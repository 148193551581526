import React, { useState, useEffect } from 'react'; // , useCallback    Component, useMemo, useRef // const { useState, useEffect, useRef } = React; 
// import PropTypes from 'prop-types';
import SplitPane from 'react-split-pane'; //, { Pane }
import { Auth } from 'aws-amplify'; //Amplify, { Auth, Hub }
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import CreatableSelect from 'react-select/creatable'; // Creatable, { makeCreatableSelect } 
import Select from 'react-select';
import toast from 'react-hot-toast';
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx';
import IgvVaNex from '../components/IgvVaNex';

// import DropdownButton from 'react-bootstrap/DropdownButton';
// import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
// import Modal from 'react-bootstrap/Modal';

import { Modal } from 'bootstrap'; // Collapse, Popover, Toast, Tooltip, Alert, , Dropdown
import { Formik, Form, Field, FieldArray } from 'formik'; //, ErrorMessage
import { Prompt } from 'react-router'; // withRouter

// import DyLoP from '../DyLoP/DyLoP';
import OmimInfo from "./OmimInfo";
import VariantTable from "./VariantTable";
import VariantInfoBox from "./VariantInfoBox";
import {GetSignedURL, GetHeatedQualityScore, GetSampleRank, EditableTextArea, VanexNoteCell, VanexTagCell, VanexReviewCell, VanexGenotypeCell, ConstructFilterFunctions, CalcDamagingScores, sortColumnByArrayLength} from '../HelperFunctions' // VanexMrPhenePhenoTermsCell, 
import {parse_JSONL, downloadIndividualNote } from './ProjectSpecificApp'
import IndeterminateCheckbox, {CHECKED, UNCHECKED, INDETERMINATE} from "./IndeterminateCheckbox";

import variantFormatDefinition from '../resources/OutputDefinitionRareDiseaseGRCh38.json';
import filterDefinition from '../resources/filters.json';
import pathways from '../resources/pathways.json';
// import { transferKeyToUpperCase } from '@aws-amplify/core';
import pako from 'pako';
import ReviewOptions from '../resources/reviewOptions.json';

import Firefly from '@nch-igm/firefly';
import Accordion from 'react-bootstrap/Accordion';
// import AccordionButton, { useAccordionButton } from 'react-bootstrap/AccordionButton';
// import Card from 'react-bootstrap/Card';
// import Button from 'react-bootstrap';

//! TODO: warn user of unsaved changes before leaving; add options to have different export settings
// ! use dropdown, Toast and etc. from bootstrap

// TODO: fix sorting Notes, (and tags?) and filtering tags?
// DONE!  fix exports and copy to clipboard, 
// TODO: fix URl for analysis and others
// TODO: fix search and router

// TODO: alert user if leaving when data is loaded
// TODO: /N at the end of JSONL???
// TODO: show percentage of truncation!


// TODO: show memory usage to the user!


// TODO: esize + edit   add event onChange on Resize in variantTable
// TODO: show patient phenotype, when analysis, moved to data do not hid other tabs, show patient number when analyzing
// TODO: add cnv, sv, save filter notes
// TODO: search for a gene name
// TODO: fix column spec json, keep sorted order, keep filter notes
// TODO: show how much truncating a protein is, 
// TODO: when is not logged in and search for a gene it asked to log in but does not get back to where the user wants to be!


// // Define a default UI for filtering
// function simpleFilter({
//   column: { filterValue, preFilteredRows, setFilter },
// }) {
//   const count = preFilteredRows.length
//   return (
//     <input
//       value={filterValue || ''}
//       onChange={e => {
//         setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
//       }}
//       placeholder={`Search ${count} records...`}
//     />
//   )
// }


// // This is a custom filter UI for selecting
// // a unique option from a list
// function SelectColumnFilter({
//   column: { filterValue, setFilter, preFilteredRows, id }
// }) {
//   // Calculate the options for filtering
//   // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set();
//     preFilteredRows.forEach((row) => {
//       options.add(row.values[id]);
//     });
//     return [...options.values()];
//   }, [id, preFilteredRows]);

//   // Render a multi-select box
//   return (
//     <select
//       value={filterValue}
//       onChange={(e) => {
//         setFilter( (aa,bb,cc) => {
//           // console.log(aa, bb, cc)
//           // return(e.target.value || undefined);
//           return('16');
//         });
//       }}
//     >
//       <option value="">All</option>
//       {options.map((option, i) => (
//         <option key={i} value={option}>
//           {option}
//         </option>
//       ))}
//     </select>
//   );
// }

// function MyCell({ value }) {
//   return <a href="#" onClick={()=>console.log("yay")} style={{backgroundColor:'red',width:'100%'}}>{value}</a>

//   // // Loop through the array and create a badge-like component instead of a comma-separated string
//   // if(values){
//   //   return (
//   //     <>
//   //       {values.map((gene, idx) => {
//   //         return (
//   //           <span key={idx} className="badge">
//   //             {gene}
//   //           </span>
//   //         );
//   //       })}
//   //     </>);
//   // }
//   // else{
//   //   return(
//   //     <>
//   //     <span className="badge bg-light text-dark" >{values}</span>
//   //     </>);
//   // } 
// }


// /**
// * @param {string} sampleID
// * @return {string}
// */
// const getSampleType = (sampleID, short=false, alreadySetArray=null) => {
//   // console.log(sampleID);
//   let label='';
//   switch(true){
//     case (sampleID.includes("_P_")):
//       label= (short ? 'P' : 'Proband');
//       break;
//     case (sampleID.includes("_M_")):
//       label= (short ? 'M' : 'Mother');
//       break;
//     case (sampleID.includes("_F_")):
//       label= (short ? 'F' : 'Father');
//       break;
//     case (sampleID.includes("_S_")):
//       label= (short ? 'S' : 'Sibling');
//       break;
//     case (sampleID.includes("_B_")):
//       label= (short ? 'B' : 'Brother');
//       break;
//     default:
//       label= ' ? ';
//   }
//   // * check if this label has already been set to another sample before, so add a number to it
//   if(alreadySetArray != null){
//     if (alreadySetArray.has(label)){
//       const findNext = (inLabel=label, increment=2) =>{
//         if(alreadySetArray.has(inLabel+'_'+increment)){
//           // console.log(inLabel, '====', increment);
//           return findNext(inLabel,++increment);
//         }
//         return (inLabel+'_'+increment);
//       }
//       label =findNext(label);
//       // console.log(sampleID, '-', label);
//     }
//   }

//   return label;
// }

/** //! duplicated code! is in RdApp too
* @param {Object} object
* @param {string} key
* @return {any} value
*/
const getParameterCaseInsensitive = (object, key) => {
  return object[Object.keys(object)
    .find(k => k.toLowerCase() === key.toLowerCase())
  ];
}

// var request;
const ENDPOINT_BASE = process.env.REACT_APP_API_URI;

const VariantViewer = (props) => {

  //* this controls whether we want the user to change sample types (PMF) during the analysis by showing the button before the column names in the column list
  const showPMFPrefix = (!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE && (props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV')) ? false : true ;
  // const [showPMFPrefix, setShowPMFPrefix] = useState( (typeof props.setPMFPrefix == "boolean") ? props.setPMFPrefix : (!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE && (props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV')) ? false : true );
  // const [showPMFPrefix, setShowPMFPrefix] = useState( (typeof props.setPMFPrefix == "boolean") ? props.setPMFPrefix : true );

  // const columnData = React.useMemo(() => {
  const [initialColumnData, setInitialColumnData] = useState( () => {
    if(!!props.dataColumns)
      return(props.dataColumns);

    // if(!!props.caseData && props.caseData.length > 0 && !!props.caseData[0]){
    //   let columnKeys = new Set(Object.keys(props.caseData[0]));
    //   props.caseData.forEach(rowData =>{
    //     columnKeys = new Set([...columnKeys,...Object.keys(rowData)]);
    //   });
    //   return( Array.from(columnKeys).map(key => {
    if(!!props.caseData[0]){
      return(Object.keys(props.caseData[0]).map( (key) => {
        if(key==="VANEX_TAGS"){
          return {
            Header: "Tag",
            id: key,
            accessor: (dt) => dt[key],
            width: 10,
            disableResizing: true,
            Cell: VanexTagCell,
            renderDefinedCell: true,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="VANEX_NOTES"){
          return {
            Header: "Note",
            id: key,
            accessor: (dt) => dt[key],
            width: 200,
            Cell: VanexNoteCell,
            renderDefinedCell: true,
            sortType: (rowA, rowB, columnId, desc) => {
              // TODO: sortType needs to return only 1 or -1. Make sure this is the case
              // if(rowA.values[columnId].CURRENT_USER_NOTE!=="" && !!rowB.values[columnId].CURRENT_USER_NOTE!=="")
                return(rowA.values[columnId].CURRENT_USER_NOTE.localeCompare(rowB.values[columnId].CURRENT_USER_NOTE, undefined, { numeric: true }))
              // if(rowA.values[columnId].CURRENT_USER_NOTE==="" && rowB.values[columnId].CURRENT_USER_NOTE==="") return 100;
              // if(rowA.values[columnId].CURRENT_USER_NOTE==="") return 100;
              // return -100;
            },
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="VANEX_REVIEWS"){
          return {
            Header: "Review",
            id: key,
            accessor: (dt) => dt[key],
            width: 150,
            Cell: VanexReviewCell,
            renderDefinedCell: true,
            disableResizing: true,
            columnStyle: {textAlign: 'center'},
            style: {overflow :'visible'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="MR_PHENE_PHENOTYPE_TERMS"){
          return {
            Header: "MrPhene Pheno. Terms",
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            // Cell: VanexMrPhenePhenoTermsCell,
            // renderDefinedCell: true,
            sortType: sortColumnByArrayLength,
            columnStyle: {textAlign: 'center'},
            // style: {overflow :'visible'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }

        if(!!props.keepColumnAsIs){
          return {
            Header: getColumnNameFromAccessor(key),
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="SAMPLES"){
          return {
            Header: key,
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="CASE_ID"){
          return {
            Header: "Case ID",
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="PHENOTYPE_RANKING"){
          return {
            Header: "Phenotype Ranking",
            id: key,
            accessor: (dt) => dt[key],
            width: 20,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="PHENOTYPE_TERMS"){
          return {
            Header: "Phenotype Terms",
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="CHROMOSOME") {
          return {
            Header: "Chr",
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            sortType: (rowA, rowB, columnId, desc) => {
              // TODO: sortType needs to return only 1 or -1. Make sure this is the case
              let valA = (rowA.values[columnId]==="X" ? 23 : (rowA.values[columnId]==="Y" ? 24 : parseInt(rowA.values[columnId])));
              let valB = (rowB.values[columnId]==="X" ? 23 : (rowB.values[columnId]==="Y" ? 24 : parseInt(rowB.values[columnId])));
              return (valA < valB ? -1 : 1);
              // return (valA < valB ? -1 : (valA > valB ? 1 : 0) );
            },
            style: {fontWeight: 'bolder',textAlign: 'center'},
            // columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="GENE") {
          return {
            Header: "Gene",
            id: key,
            accessor: (dt) => dt[key],
            width: 90,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="LOC_IN_GENE") {
          return {
            Header: "Loc In Gene",
            id: key,
            accessor: (dt) => dt[key],
            width: 120,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="DIST_TO_CODING_REGION") {
          return {
            Header: "Coding Distance",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="DIST_TO_SPLICE_SITE") {
          return {
            Header: "Splice Distance",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="EFFECT") {
          return {
            Header: "Effect",
            id: key,
            accessor: (dt) => dt[key],
            width: 150,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="QUAL"){
          return {
            Header: "Qual",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            useHeated: true,
            // addFilter: true,
            Cell: (props) => (  Math.round((Number(props.value) + Number.EPSILON) * 100) / 100 ), // Math.trunc(Number(props.value));
            heatedThresholdValue: 120, // ! maybe change later?! or this is good?
            style: { textAlign: 'center'},
            columnStyle: {textAlign: 'center'},
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="DNP"){
          return {
            Header: "DNP",
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="SPLICEAI_DS_MAX_V4"){
          return {
            Header: "SpliceAI",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        // if(key==="GENE"){
        //   return {
        //     Header: "Gene",
        //     accessor: key,
        //     id: key,
        //     accessor: (dt) => dt[key],
        //     width: 130,
        //     // addFilter: true,
        //     defaultCanFilter: true,
        //     // canFilter: true,
        //     // Filter: SelectColumnFilter,
        //     // filter: "equals",
        //     Filter: simpleFilter,
        //     filter: (rows, id, filterValue) => {
        //       return rows.filter(row => {
        //         const rowValue = row.values[id]
        //         return rowValue !== undefined
        //           ? String(rowValue)
        //               .toLowerCase()
        //               .startsWith(String('a').toLowerCase())
        //           : true
        //       })
        //     },
        //     setColWidth: function(inWidth){ this.width = inWidth; }
        //   }
        // }
        if(key.endsWith("_GT")){
          return {
            // Header: (showPMFPrefix ? `${getSampleLabel(key.slice(0,-3))} [${key}]` : key) ,
            // Header: `${getSampleLabel(key.slice(0,-3))}: ${key}`,
            Header: `${getSampleLabel(getSampleIdFromColumnAccessor(key))}: ${key}`,
            id: key,
            accessor: (dt) => dt[key],
            useGenotypeColor:true,
            Cell: VanexGenotypeCell,
            width: 46,
            style: { textAlign:'start'}, //fontWeight: 'bolder', 
            columnStyle: {textAlign: 'center'},
            sampleLabel: getSampleLabel(key.slice(0,-3)),
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if( key.endsWith('_Alt_Percent')){
          // let tmpHeader = ( showPMFPrefix ? key : `${getSampleLabel(key.slice(0,-12))} [${key}]`);
          // let tmpHeader = `${getSampleLabel(key.slice(0,-12))}: ${key}`;
          let tmpHeader = `${getSampleLabel(getSampleIdFromColumnAccessor(key))}: ${key}`;
          let threshold = 100;
          // tmpHeader = `x_${tmpHeader}`
          return {
            Header: tmpHeader,
            id: key,
            accessor: (dt) => dt[key],
            width: 50,
            Cell: VanexGenotypeCell,
            setColWidth: function(inWidth){ this.width = inWidth; },
            // useHeated: true,
            sampleLabel: getSampleLabel(key.slice(0,-12)),
            useReverseHeated: true,
            heatedThresholdValue: threshold
          }
        }
        if( key.endsWith('_GQ')){
          let tmpHeader = `${getSampleLabel(getSampleIdFromColumnAccessor(key))}: ${key}`;
          // let threshold = 80;
          // if( key.endsWith('_Alt_Percent') ) {
          //   threshold = 0.8;
          //   tmpHeader= key.substring( key.lastIndexOf("_Alt_Percent") + 1) + '_' + key.substring(0, key.lastIndexOf("_Alt_Percent") );
          // }
          // else{
          //   tmpHeader= key.substring( key.lastIndexOf("_GQ") + 1) + '_' + key.substring(0, key.lastIndexOf("_GQ") );
          // }
          return {
            Header: tmpHeader,
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            setColWidth: function(inWidth){ this.width = inWidth; },
            useHeated: true,
            // heatedThresholdValue: threshold
          }
        }
        if(!!getSampleIdFromColumnAccessor(key)){
          return {
            // Header: key, //key.substring( key.lastIndexOf("_AD_") + 1) + '_' + key.substring(0, key.lastIndexOf("_AD_") ),
            // Header: `${getSampleLabel(key.slice(0,-3))}: ${key}`,
            Header: `${getSampleLabel(getSampleIdFromColumnAccessor(key))}: ${key}`,
            id: key,
            accessor: (dt) => dt[key],
            width: 40,
            setColWidth: function(inWidth){ this.width = inWidth; },
            // style: { textAlign: 'center'}, //fontWeight: 'bolder',
            columnStyle: {textAlign: 'center'}
          }
        }
        if(key==="HKIDS_N_SAMPLES"){
          return {
            Header: "Unaffected Sibs Total Counts",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="HKIDS_HOM"){
          return {
            Header: "Unaffected Sibs Hom Counts",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="HKIDS_MALE"){
          return {
            Header: "Unaffected Sibs Male Counts",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="HKIDS_FEMALE"){
          return {
            Header: "Unaffected Sibs Female Counts",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="IGM_CFK_PANEL_MEMBERSHIP"){
          return {
            Header: "IGM CFK Panel Membership",
            id: key,
            accessor: (dt) => dt[key],
            width: 100,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="HEART_ENHANCER_AVERAGE_SCORE_STRING"){
          return {
            Header: "Heart Enhancer Avg. Score",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="HEART_ENHANCER_SCORE_CATEGORY"){
          return {
            Header: "Heart Enhancer Score Category",
            id: key,
            accessor: (dt) => dt[key],
            width: 70,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        if(key==="CLINGEN_DISEASE_HIGHEST_CLASSIFICATION"){
          return {
            Header: "ClinGen Disease Highest Classification",
            id: key,
            accessor: (dt) => dt[key],
            width: 120,
            addFilter: true,
            setColWidth: function(inWidth){ this.width = inWidth; }
          }
        }
        let colHeader = key;
        let colWidth = 100;

        // ! fix this, this is using RD column-definitions, maybe make it project specific?!
        let colDefinition = getParameterCaseInsensitive(variantFormatDefinition['column-definitions'],key);
        if (!!colDefinition){
          colHeader = colDefinition['header'] || colHeader;
          colWidth = colDefinition['column_width'] || colWidth;
        }

        // TODO: fix the default withd
        if (colWidth < 30) colWidth = 50;

        return {
          Header: colHeader,
          id: key,
          accessor: (dt) => dt[key],
          width: colWidth,
          // columnStyle: {'display': 'flex', 'flexDirection': 'column', 'justifyContent': 'center'} , //instead of this, I added it to the CSS file
          setColWidth: function(inWidth){ this.width = inWidth; }
        }
      }));
    }
    return [];
  });
  // }, [props.dataColumns]);
  const columnData = React.useMemo(() => initialColumnData,[initialColumnData]);

  const [hiddenColumnsIds, setHiddenColumnsIds] = useState(props.hiddenColumnsIds || []);
  const [columnOrder, setColumnOrder] = useState(Object.values(columnData).map(i => i.id).filter(t => !hiddenColumnsIds.includes(t)));
  const [sampleColumns, setSampleColumns] = useState({});
  const [PMFSampleColNames, setPMFSampleColNames] = useState(props.PMFSampleColNames || []);
    // it is better to keep it empty at first // const [PMFSampleColNames, setPMFSampleColNames] = useState(props.PMFSampleColNames || columnData.filter(t => t.id.endsWith("_GT")).map(i => i.id).sort( (elA,elB) => (GetSampleRank(elB, true) - GetSampleRank(elA, true)) ) );
  const [sampleTypeModal, setSampleTypeModal] = useState(null);
  const refForChangeSampleTypeModal = React.useRef();
  const [refresh, setRefresh] = useState(false);
  // const [analysisStartedAt, setAnalysisStartedAt] = useState((!!props.analysisStartedAt ? new Date(props.analysisStartedAt).toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: false}) : 
  //   new Date().toLocaleString([], {hour: '2-digit', minute:'2-digit', hour12: false})));
  // const [analysisStartedAt, setAnalysisStartedAt] = useState(props.analysisStartedAt || new Date().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true}));
  

  function getSampleLabel (sampleID) {
    let label = ''
    if (!!sampleID && !!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
      // console.log(Object.values(props.currentAnalysisInfo.SAMPLES).find(t => t.SAMPLE_ID === sampleID));
      let sampleInfo = Object.values(props.currentAnalysisInfo.SAMPLES).find(t => t.SAMPLE_ID === sampleID);
      if(!!sampleInfo && !!sampleInfo['SAMPLE_TYPE']) label=sampleInfo['SAMPLE_TYPE'];
    }
    return(label);
  }

  // function getInitialSampleType (columnInfo, short=false, addUnderline=false) {
  //   let label='';
  //   if(!showPMFPrefix){
  //     if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES)
  //       label= columnInfo.sampleLabel;
  //   } else {
  //     // let smpType = Object.keys(sampleColumns).find(el => sampleColumns[el]===columnInfo.id);
  //     let smpType = sampleColumns[columnInfo.id];
  //     if(!!smpType)
  //       label =  (short ? smpType.slice(0,1) : smpType);
  //   }

  //   return( (addUnderline && label!=='') ? `${label}_` : label);
  // }

  function getTypeOfColumnsInPMFList(columnInfo, short=true, addUnderline=false, inData = PMFSampleColNames) {
    let label='';
    if(!showPMFPrefix){
      if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES)
        label= columnInfo.sampleLabel;
    } else {
      switch(inData.indexOf(columnInfo.id)){
        case 0:
          label= ((short) ? 'P' : 'Proband');
          break;
        case 1:
          label= ((short) ? 'M' : 'Mother');
          break;
        case 2:
          label= ((short) ? 'F' : 'Father');
          break;
        default:
          label= '';
      }
    }
    return( (addUnderline && label!=='') ? `${label}_` : label);
  }

  function getSampleColumnId(sampleType, sampleColNames) {
    let smpType = Object.keys(sampleColNames).find(el => sampleColNames[el]===sampleType);
    if(!!smpType)
      return(smpType);
    else
      return 'None';
      // return null;
  }

  function getSampleIdFromColumnAccessor(clmnAccessor) {
    let testingSamples='';
    if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
      props.currentAnalysisInfo.SAMPLES.forEach(element => {
        if(clmnAccessor.includes(element.SAMPLE_ID))
          testingSamples = element.SAMPLE_ID;
        // testingSamples.push(clmnAccessor.includes(element.SAMPLE_ID)); //SAMPLE_TYPE
      });
    }
    // return(testingSamples.some(i => !!i));
    return(testingSamples);
  }

  function getSampleColumnsProbandAtFirst() {
    let columnNamesWOproband = Object.keys(sampleColumns).filter(el => el!==PMFSampleColNames[0]).map(i => i);
    if(!! PMFSampleColNames[0] && PMFSampleColNames[0]!=='None')
      return([PMFSampleColNames[0], ...columnNamesWOproband]);
    else
      return(columnNamesWOproband);
  }

  const [selectedVariantTab, setSelectedVariantTab] = useState(props.selectedVariantTab || ( (props.onlyShowOneTab)? 'TAB_ALL' : 'TAB_SUMMARY') );
  const [tabs, setTabs] = useState( () => {

    let tabsInfo={};
    // * set Analysis_Type specific settings
    let tabsToShow = -1; // * show all tabs
    if(!!props.onlyShowOneTab){
      tabsToShow = 1;
    } else if(!!props.currentAnalysisInfo && (props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='GenomeWideDeNovo')){
      tabsToShow = 2;
    }

    // * set tabs
    if(tabsToShow === 1){
      tabsInfo=
        {
          'TAB_ALL': {
            label: props.oneTabTitle || 'Results',
            filters: {}
          }
        }
    }
    else if(tabsToShow === 2){
      tabsInfo=
        {
          'TAB_SUMMARY':
          {
            label: 'Summary',
            filters: {}
          },
          'TAB_ALL': {
            label: 'All SNVs',
            filters: {}
          }
        }
    }
    else {
      tabsInfo={
        'TAB_SUMMARY':
        {
          label: 'Summary',
          filters: {}
        }
      }

      if(props.project==="paradigm"){
        tabsInfo['TAB_PARADIGM']={
          label: 'Top Candidates',
          filters: {}
        }
      }
    tabsInfo=
      {
        ...tabsInfo,
        'TAB_SUMMARY':
        {
          label: 'Summary',
          filters: {}
        },
        'TAB_CLINVAR':
        {
          label: 'ClinVar',
          filters: {}
        },
        'TAB_DENOVO': {
          label: 'De Novo',
          filters: {}
        },
        'TAB_HOMREC': {
          label: 'Hom.Rec.',
          filters: {}
        },
        'TAB_COMPHET': {
          label: 'Comp.Het.',
          filters: {}
        },
        'TAB_X': {
          label: 'X-linked',
          filters: {}
        },
        'TAB_LOF': {
          label: ' LOF ',
          filters: {}
        },
        'TAB_DAMAGING': {
          label: 'Pred.Disruptive',
          filters: {}
        },
        // 'TAB_MT': {
        //   label: 'Mitochondrial',
        //   filters: {}
        // },
        // 'TAB_REST': {
        //   label: 'Other SNVs',
        //   filters: {},
        //   visibleFilterSetIndex: 2
        // },
        'TAB_ALL': {
          label: 'All SNVs',
          filters: {}
        }
      }
      // // //!!!!! if(!!props.paradigmData){
      // if(props.project==="paradigm"){
      //   tabsInfo['TAB_PARADIGM']={
      //     label: 'Top Candidates',
      //     filters: {}
      //   }
      // }
    }
    return(tabsInfo);
    
  });

  // !! IGV stuff, took out 

  const [selectedPathwayGeneEntrezIds, setSelectedPathwayGeneEntrezIds] = useState([]);
  const [selectedPathwayIds, setSelectedPathwayIds] = useState([]);
  const [pathwaySearchValue, setPathwaySearchValue] = useState('');
  const [pathwayCategory, setPathwayCategory] = useState("KEGG");

  useEffect(() => {
    let geneEntrezIds = [];
    selectedPathwayIds.forEach( l => geneEntrezIds = [...new Set([...geneEntrezIds, ...pathways[l]])]);
    // console.log(geneEntrezIds); // console.log(selectedPathwayIds);
    setSelectedPathwayGeneEntrezIds(geneEntrezIds);
    if(selectedPathwayIds.length > 0){
      setGlobalTabFilters(
        prevGlobalTabFilters => ({
          ...prevGlobalTabFilters,
          "PATHWAYS_GLOBAL_FILTER": {
            columnId: 'PATHWAYS_GLOBAL_FILTER',
            label: 'PATHWAYS_GLOBAL_FILTER',
            value: 'PATHWAYS_GLOBAL_FILTER',
            // minValue: null,
            // maxValue: null,
            operation: '=',
            visible: true,
            editable: true,
            getFilterDescr: function(){ return("Only Selected Pathways")},
            // getFilterDescr: function(){ return(this.label + this.value)},
            filterFunction: function(){ return(t => (geneEntrezIds.includes(t['ENTREZ_ID']))) },
            isApplied: true
          }
        })
      );
    } else {
      let tmpGlobalTabFilters = {...globalTabFilters}
      delete tmpGlobalTabFilters['PATHWAYS_GLOBAL_FILTER']        //! is there a way to delete a property without copying it first? NO!
      setGlobalTabFilters(tmpGlobalTabFilters);
    }
  }, [selectedPathwayIds])

  const [numberOfReviewSaved, setNumberOfReviewSaved] = useState(null);
  const [numberOfNotesSaved, setNumberOfNotesSaved] = useState(null);
  const canSaveNotes = props.canSaveNotes || (!(!!props.onlyShowOneTab || !props.currentCaseInformation));
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [isAutoSavingNotes, setIsAutoSavingNotes] = useState(process.env.NODE_ENV!=='development' || false);
  const [autoSaveNotesInterval, setAutoSaveNotesInterval] = useState(3);
  const [autoSaveNotesCounter, setAutoSaveNotesCounter] = useState(0);
  const [savingNotesProgressbarValue, setSavingNotesProgressbarValue] = useState(0);
  const [isExporting, setIsExporting] = useState(false);
  const [exportProgressbarValue, setExportProgressbarValue] = useState(0);
  const [triggerExportPreparation, setTriggerExportPreparation] = useState(false);
  const [preparedDataFromVariantTable, setPreparedDataFromVariantTable] = useState(null);
  const [VaNexAutoNotes, setVaNexAutoNotes] = useState([]);
  const [showVaNexAutoNotes, setShowVaNexAutoNotes] = useState(true);
  const [showOtherUsersNotes, setShowOtherUsersNotes] = useState(true);
  const [shownAggNotesPanelId, setShownAggNotesPanelId] = useState(1);
  const [orderOfAggNotes, setOrderOfAggNotes] = useState("POS");
  const [minAggNotesChar, setMinAggNotesChar] = useState(50);
  const [aggNotesGeneLevel, setAggNotesGeneLevel] = useState({});
  const [aggNotesPositionLevel, setAggNotesPositionLevel] = useState({});
  const [isLoadingAggNotes, setIsLoadingAggNotes] = useState(true);
  const AggNotesUsers= ["Rolf_Stottmann","Daniel_Koboldt","Mohammad_Marhabaie","Swetha_Ramadesikan","Iftekhar_Showpnil","Umamaheswaran_Gurusamy","Ivana_Stojkic",
      "Shayne_Plourde","Ashton_Holub","Caitlyn_Colwell","Ava_Willoughby","Sari_Goldson",
      "Elizabeth_Garfinkle","Olivia_Roof2","Olivia_Grischow","Maeson_Latsko"];
  const [selectedAggNotesUsers, setSelectedAggNotesUsers] = useState(AggNotesUsers);


  const [damagingScores, setDamagingScores] = useState([0,0,0,0,0,0,0,0,0]); // * nToleratedMeta,nUnknownMeta,nToleratedMeta,nTotalMeta,nTolerated,nUnknown,nTolerated,nTotal,pNoCats
  const calCumulativeDamagingScore = (t) => {
    // let metaLR =0;
    // let metaSVM =0;
    // let revel =0;
    // let numberOfToolsReportingDamaging = 0;
    // if (t['DBNSFP_METALR_PRED'] === 'D') {
    //   metaLR=t['DBNSFP_METALR_SCORE']*100;
    //   numberOfToolsReportingDamaging +=1;
    // }
    // if (t['DBNSFP_METASVM_PRED'] === 'D'){
    //   metaSVM=t['DBNSFP_METASVM_SCORE']*100;
    //   numberOfToolsReportingDamaging +=1;
    // }
    // if (t['DBNSFP_REVEL_SCORE'] > 0.5){
    //   revel=t['DBNSFP_REVEL_SCORE']*100;
    //   numberOfToolsReportingDamaging +=1;
    // }
    // if(numberOfToolsReportingDamaging===0){
    //   return((metaLR+metaSVM+revel));
    // } else if(numberOfToolsReportingDamaging===1){
    //   // [nDamagingMeta,nUnknownMeta,nToleratedMeta,nTotalMeta,nDamaging,nUnknown,nTolerated,nTotal,nNoCats] 
    //   let scores = CalcDamagingScores(t);
    //   let pDamaging = scores[4]/scores[7]; // * nDamaging / nTotal
    //   return((metaLR+metaSVM+revel)*pDamaging);
    // } else {
    //   return((metaLR+metaSVM+revel)*numberOfToolsReportingDamaging);
    // }
    let metaLR =0;
    let metaSVM =0;
    let metaRNN =0;
    let revel =0;
    // [nDamagingMeta,nUnknownMeta,nToleratedMeta,nTotalMeta,nDamaging,nUnknown,nTolerated,nTotal,nNoCats] 
    let scores = CalcDamagingScores(t);
    let pDamaging = scores[4]/scores[7]; // * nDamaging / nTotal
    let indCorrFact = ( (pDamaging > 0.5 && scores[7] > 7) ? pDamaging : 0); // can use e^x

    // let numberOfToolsReportingDamaging = 0;
    if (t['DBNSFP_METALR_PRED'] === 'D') {
      metaLR=t['DBNSFP_METALR_SCORE'];
      // numberOfToolsReportingDamaging +=1;
    }
    if (t['DBNSFP_METASVM_PRED'] === 'D'){
      metaSVM=t['DBNSFP_METASVM_SCORE'];
      // numberOfToolsReportingDamaging +=1;
    }
    if (t['DBNSFP_METARNN_PRED'] === 'D') {
      metaRNN=t['DBNSFP_METARNN_SCORE'];
      // numberOfToolsReportingDamaging +=1;
    }
    if (t['DBNSFP_REVEL_SCORE'] > 0.5){
      revel=t['DBNSFP_REVEL_SCORE'];
      // numberOfToolsReportingDamaging +=1;
    }

    let pathScore = Number(scores[0]/3 + metaLR+metaSVM+metaRNN+revel + pDamaging + indCorrFact)
    if (isNaN(pathScore)) pathScore=-1;

    return(pathScore);
    // if(numberOfToolsReportingDamaging===0){
    //   return((metaLR+metaSVM+revel));
    // // } else if(numberOfToolsReportingDamaging===1){
    // } else {
      
      
    //   return((metaLR+metaSVM+revel)*pDamaging);
    // // } else {
    // //   return((metaLR+metaSVM+revel)*numberOfToolsReportingDamaging);
    // }

    // let metaLR =0;
    // let metaSVM =0;
    // let revel =0;
    // let numberOfToolsReportingDamaging = 0;
    // if (t['DBNSFP_METALR_PRED'] === 'D') {
    //   metaLR=t['DBNSFP_METALR_SCORE'];//*10;
    //   numberOfToolsReportingDamaging +=1;
    // }
    // if (t['DBNSFP_METASVM_PRED'] === 'D'){
    //   metaSVM=t['DBNSFP_METASVM_SCORE'];//*10;
    //   numberOfToolsReportingDamaging +=1;
    // }
    // if (t['DBNSFP_REVEL_SCORE'] > 0.5){
    //   revel=t['DBNSFP_REVEL_SCORE'];//*10;
    //   numberOfToolsReportingDamaging +=1;
    // }
    // if(numberOfToolsReportingDamaging===0){
    //   return((metaLR+metaSVM+revel));
    // // } else if(numberOfToolsReportingDamaging===1){
    // } else {
    //   // [nDamagingMeta,nUnknownMeta,nToleratedMeta,nTotalMeta,nDamaging,nUnknown,nTolerated,nTotal,nNoCats] 
    //   let scores = CalcDamagingScores(t);
    //   let pDamaging = scores[4]/scores[7]; // * nDamaging / nTotal
    //   // return((metaLR+metaSVM+revel)*pDamaging);
    //   return(metaLR+metaSVM+revel+pDamaging);
    // // } else {
    // //   return((metaLR+metaSVM+revel)*numberOfToolsReportingDamaging);
    // }
  };

  const [latestTablePageIndex, setLatestTablePageIndex] = useState(0);
  // const [notesForCurrentVariant, setNotesForCurrentVariant] = useState([]);
  const [currentVariantId, setCurrentVariantId] = useState(null);
  // const [otherVariationsInThisGene, setOtherVariationsInThisGene] = useState([]);

  const otherVariationsInThisGene = React.useMemo(() => {
    if(currentVariantId!==undefined && currentVariantId!==null){
      const currentVariant = props.caseData[currentVariantId];
      return(
        Object.values(props.caseData).filter(t => 
          t.GENE && currentVariant.GENE && 
          t.GENE?.split(";").some(item => currentVariant.GENE?.split(";").includes(item))
        )        
      );
    }
    return([]);
  }, [currentVariantId, props.caseData]);

  const otherVariationsNearby = React.useMemo(() => {
    if(currentVariantId!==undefined && currentVariantId!==null){
      const currentVariant = props.caseData[currentVariantId];
      // console.log('currentVariant',currentVariant)
      // console.log(Object.values(props.caseData).filter((t => (t!==currentVariant)&&(t.CHROMOSOME === currentVariant.CHROMOSOME) && (t.POSITION >= currentVariant.POSITION-10) && (t.POSITION <= currentVariant.POSITION+10))))
      return(
        Object.values(props.caseData).filter((t => (t!==currentVariant)&&(t.CHROMOSOME === currentVariant.CHROMOSOME) && (t.POSITION >= currentVariant.POSITION-10) && (t.POSITION <= currentVariant.POSITION+10)))
      );
    }
    return([]);
  }, [currentVariantId, props.caseData]);
    
  useEffect(() => {
    setLoadingClinVarPlot(false);
    setClinVarPlotSrc('');
    setErrorMessage('');
    // setdbSNPSrc('');

    let autoNotes = [];
    let gnomADCounts = '';
    let ntDamaging = '';

    let nDamagingMeta =0;
    let nUnknownMeta =0;
    let nToleratedMeta =0;
    let nTotalMeta =0;

    let nDamaging =0;
    let nUnknown =0;
    let nTolerated =0;
    let nTotal =0;
    let nNoCats =0;

    if(currentVariantId!==undefined && currentVariantId!==null){
      const currentVariant = props.caseData[currentVariantId];
      // console.log(currentVariant.VANEX_NOTES);
      // setOtherVariationsInThisGene(
      //   Object.values(props.caseData).filter(t => t.GENE === currentVariant.GENE)
      // );

      const currentAnalysisId = props.currentAnalysisInfo.ANALYSIS_ID

      // console.log("currentVariant", currentVariant);
      // console.log("ENTREZ_ID", currentVariant.ENTREZ_ID);
      const entrezid = currentVariant?.ENTREZ_ID;
      const varChromosome = currentVariant?.CHROMOSOME;
      const varPosition = currentVariant?.POSITION;
      const varChrPosId = `${varChromosome}-${varPosition}`;
      // console.log('ID',entrezid)
      // console.log(aggNotesGeneLevel)
      ///! find gene and pos level aggNotes
      if ( (shownAggNotesPanelId === 1) && !(entrezid in aggNotesGeneLevel)) {
      //   console.log("yaya", aggNotesGeneLevel[entrezid])
      // } else {
        const basePathForGeneNotes = `case_notes_by_gene_v2/ENTREZ_ID=${entrezid}/&folder=true`;
        console.log(basePathForGeneNotes)
        GetSignedURL('nch-igm-vanex', basePathForGeneNotes, true) 
        .then(response => downloadAggregatedNotes(response, entrezid, setAggNotesGeneLevel, currentAnalysisId))
        .catch( e => {setIsLoadingAggNotes(false); console.error(`Error in retrieving notes: ${e.toString()}`); })
        // .catch( e => {setIsLoadingAggNotes(false); toast.error(`Error in retrieving notes: ${e.toString()}`, {duration:8000}); })
      }
      // console.log("aggNotesGeneLevel", aggNotesGeneLevel)


      //! fix this to 2
      if ( (shownAggNotesPanelId === 1) && !(varChrPosId in aggNotesPositionLevel)) {
      //   console.log("yaya", aggNotesGeneLevel[entrezid])
      // } else {
        const basePathForPositionNotes = `case_notes_by_pos_v2/CHROMOSOME=${varChromosome}/POSITION=${varPosition}/&folder=true`;
        console.log(basePathForPositionNotes)
        GetSignedURL('nch-igm-vanex', basePathForPositionNotes, true) 
        .then(response => downloadAggregatedNotes(response, varChrPosId, setAggNotesPositionLevel, currentAnalysisId))
        .catch( e => {setIsLoadingAggNotes(false); console.error(`Error in retrieving notes: ${e.toString()}`); })
        // .catch( e => {setIsLoadingAggNotes(false); toast.error(`Error in retrieving notes: ${e.toString()}`, {duration:8000}); })
      }


      //!!!!! function processMetaPrediction(toolName){
      //   if (currentVariant[toolName]!==undefined && currentVariant[toolName]!==null){
      //     nTotalMeta += 1;
      //     if (currentVariant[toolName].toLowerCase() === 't') nToleratedMeta += 1;
      //     if (currentVariant[toolName].toLowerCase() === 'u') nUnknownMeta += 1;
      //     if (currentVariant[toolName].toLowerCase() === 'd') nDamagingMeta += 1;
      //   }
      // }
  
      // ['DBNSFP_METALR_PRED','DBNSFP_METASVM_PRED'].forEach(k => processMetaPrediction(k))
      // if (currentVariant.DBNSFP_REVEL_SCORE!==undefined && currentVariant.DBNSFP_REVEL_SCORE!==null){
      //   nTotalMeta += 1;
      //   if(currentVariant.DBNSFP_REVEL_SCORE > 0.5)
      //     nDamagingMeta += 1
      //   else
      //     nToleratedMeta += 1
      // }

      // function processPrediction(toolName){
      //   if (currentVariant[toolName]!==undefined && currentVariant[toolName]!==null){
      //     nTotal += 1;
      //     if (currentVariant[toolName].toLowerCase() === 't' || currentVariant[toolName].toLowerCase() === 'b' ||
      //       currentVariant[toolName].toLowerCase() === 'n' || currentVariant[toolName].toLowerCase() === 'l') nTolerated += 1;
      //     else if (currentVariant[toolName].toLowerCase() === 'u') nUnknown += 1;
      //     else if (currentVariant[toolName].toLowerCase() === 'd' || currentVariant[toolName].toLowerCase() === 'p' || 
      //     currentVariant[toolName].toLowerCase() === 'm' || currentVariant[toolName].toLowerCase() === 'h') nDamaging += 1;
      //     else nNoCats +=1;
      //   }
      // }

      // [
      //   // 'DBNSFP_ALOFT_PRED',
      //   'DBNSFP_BAYESDEL_ADDAF_PRED',
      //   'DBNSFP_BAYESDEL_NOAF_PRED',
      //   'DBNSFP_CLINPRED_PRED',
      //   'DBNSFP_DEOGEN2_PRED',
      //   // 'DBNSFP_EIGEN_PRED',
      //   // 'DBNSFP_EIGENPC_PRED',
      //   'DBNSFP_FATHMM_PRED',
      //   'DBNSFP_FATHMM-MKL_CODING_PRED',
      //   'DBNSFP_FATHMM-XF_CODING_PRED',
      //   'DBNSFP_LIST-S2_PRED',
      //   'DBNSFP_LRT_PRED',
      //   'DBNSFP_M-CAP_PRED',
      //   // 'DBNSFP_MVP_PRED',
      //   // 'DBNSFP_MUTPRED_PRED',
      //   'DBNSFP_MAX_MUTATIONASSESSOR_PRED', //* 'DBNSFP_MAX_MUTATIONTASTER_PRED', this one is done differently 
      //   'DBNSFP_PROVEAN_PRED',
      //   'DBNSFP_MAX_POLYPHEN2_HDIV_PRED',
      //   'DBNSFP_MAX_POLYPHEN2_HVAR_PRED',
      //   'DBNSFP_PRIMATEAI_PRED',
      //   'DBNSFP_SIFT_PRED',
      //   'DBNSFP_SIFT4G_PRED'
      // ].forEach(k => processPrediction(k))
      // //* processPrediction('DBNSFP_MAX_MUTATIONASSESSOR_PRED'); // N, L, | M, H == Neutral, Low, | Medium, High
      
      
      // //* processPrediction('DBNSFP_MAX_MUTATIONTASTER_PRED');   // N, P, | D, A == Polymorphism automatic, Polymorphism, | Disease causing, Disease causing automatic
      // if (currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED!==undefined && currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED!==null){
      //   nTotal += 1;
      //   if (currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'p' ||
      //     currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'n') nTolerated += 1;
      //   else if (currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'd' ||
      //   currentVariant.DBNSFP_MAX_MUTATIONTASTER_PRED.toLowerCase() === 'a') nDamaging += 1;
      //   else nNoCats +=1;
      // }
      // if (currentVariant.DBNSFP_CADD_PHRED!==undefined && currentVariant.DBNSFP_CADD_PHRED!==null){
      //   nTotal += 1;
      //   if(currentVariant.DBNSFP_CADD_PHRED > 19)
      //     nDamaging += 1
      //   else
      //     nTolerated += 1
      // }
      // if (currentVariant.DBNSFP_DANN_SCORE!==undefined && currentVariant.DBNSFP_DANN_SCORE!==null){
      //   nTotal += 1;
      //   if(currentVariant.DBNSFP_DANN_SCORE > 0.5)
      //     nDamaging += 1
      //   else
      //     nTolerated += 1
      // }
      // // if (variant.DBNSFP_REVEL_SCORE!==undefined && variant.DBNSFP_REVEL_SCORE!==null){
      // //   nTotal += 1;
      // //   if (variant.DBNSFP_METALR_PRED.toLowerCase() === 't') nTolerated += 1;
      // //   if (variant.DBNSFP_METALR_PRED.toLowerCase() === 'u') nUnknown += 1;
      // //   if (variant.DBNSFP_METALR_PRED.toLowerCase() === 'd') nDamaging += 1;
      // // }

      // gnomADCounts='not in gnomAD';
      // if(!! currentVariant.GNOMAD_WG_AC) gnomADCounts=`${currentVariant.GNOMAD_WG_AC} in gnomAD`;

      // ntDamaging=`${nDamaging}/${nTotal} (${nDamagingMeta}/${nTotalMeta} Meta) damaging`;
      //!!!! autoNotes=`${gnomADCounts},\n${ntDamaging}`

      // gnomADCounts='not in gnomAD';
      // if(!! currentVariant.GNOMAD_WG_AC) gnomADCounts=`${currentVariant.GNOMAD_WG_AC} in gnomAD`;
      gnomADCounts= GetGnomADCounts(currentVariant)[0];

      [nDamagingMeta,nUnknownMeta,nToleratedMeta,nTotalMeta,nDamaging,nUnknown,nTolerated,nTotal,nNoCats] = CalcDamagingScores(currentVariant);
    
      ntDamaging=`${nDamaging}/${nTotal} (${nDamagingMeta}/${nTotalMeta} Meta) damaging`;
      if(nTotalMeta<1) ntDamaging=`${nDamaging}/${nTotal} (no Meta) damaging`;
      if(nTotal<1) ntDamaging=`no damaging score`;
      
    }
    // autoNotes=`${gnomADCounts},\n${ntDamaging}`
    autoNotes=[gnomADCounts, ntDamaging]
    // autoNotes['GNOMAD']=gnomADCounts;
    // autoNotes['DAMAGING']=ntDamaging;
    // console.log(currentVariantId);

    if(isAutoSavingNotes && (autoSaveNotesCounter >= autoSaveNotesInterval) ) saveNotes();
    // if(columnId==='VANEX_NOTES' && isAutoSavingNotes){
    // if(isAutoSavingNotes){
    //   if(autoSaveNotesCounter >= autoSaveNotesInterval){
    //     saveNotes();
    //   } else {
    //     setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
    //   // console.log(columnId);
    //   }
    // }
    
    setDamagingScores([nDamagingMeta,nUnknownMeta,nToleratedMeta,nTotalMeta,nDamaging,nUnknown,nTolerated,nTotal,nNoCats])

    setVaNexAutoNotes(autoNotes);
  }, [currentVariantId])


  function downloadAggregatedNotes(allSignedUrls, entrezid, setAggregatedNotes, currentAnalysisId){
    const promises = [];
    let allExistingNotesAggregated = [];
    setIsLoadingAggNotes(true);
    // console.log('allSignedUrls',allSignedUrls)
    // console.log('entrezid',entrezid)
    allSignedUrls.forEach( signedUrl => {
      // console.log('allSignedUrls',signedUrl)
      if (signedUrl.includes(entrezid.split(";")[0]+'%') ||signedUrl.includes(entrezid.split(";")[0]+'/') || signedUrl.includes(entrezid.split("-")[1]+'/')) // 
        // console.log('signedUrl',signedUrl)
        promises.push(downloadIndividualNote(signedUrl));
    });
    //   // console.log(signedUrl);
    //   let fileUserName = signedUrl.match(/(\w*).json/)[1].replaceAll('_',' ') || "Unknown";
    //   // if(isNoteScopeAllAnalysis){
    //   //   let thisNoteAnalysisId = signedUrl.match(/\/ANALYSIS_ID%3D(.*)\/USERNAME/)[1] // * `=` is replaced by `%3D`
    //   //   if (!!activeCaseInfo && !!activeCaseInfo.ANALYSES){
    //   //     let thisNoteAnalysisInfo = activeCaseInfo.ANALYSES.find(t => t.ANALYSIS_ID === thisNoteAnalysisId);
    //   //     if(!!thisNoteAnalysisInfo){
    //   //       if(analysisObj.ANALYSIS_ID !== thisNoteAnalysisId)
    //   //         fileUserName += ` ::[ ${thisNoteAnalysisInfo.ANALYSIS_NAME} (${thisNoteAnalysisInfo.ANALYSIS_TYPE}) ]`
    //   //     }
    //   //   }
    //   //   // fileUserName += signedUrl.match(/\/ANALYSIS_ID%3D(.*)\/USERNAME/)[1]
    //   //   // console.log(analysisObj.ANALYSIS_ID, '--', thisNoteAnalysisId);
    //   //   // if(!caseAnalysisId.includes(thisNoteAnalysisId))
    //   //   // if(analysisObj.ANALYSIS_ID !== thisNoteAnalysisId)
    //   //   // fileUserName += ` ::[ ${analysisObj.ANALYSIS_NAME} (${analysisObj.ANALYSIS_TYPE}) ]`
    //   // }

    //   // * no need to do First letter uppercase as we did not use  .toUpperCase() to save so no need to process it here. 
    //   // fileUserName = fileUserName.toLowerCase().split(' ').map((s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' ');
    //   // if(fileUserName!==props.userName){
    //     // userNames.push(fileUserName);
    //     if(!signedUrl.includes("USERNAME=REVIEWS")) //* excluding the review.json file from this part.
    //       promises.push(downloadIndividualNote(signedUrl));
    //     // promises.push(downloadIndividualNote(signedUrl, fileUserName));
    //   // }
    // });

    Promise.all(promises)
    .then((results) => {
      results.forEach( (setOfNote, index) => {
        let notes = parse_JSONL(setOfNote);
        // console.log("notes",notes)
        notes.forEach( myNote => {
          const thisNoteTag = myNote['VANEX_TAGS']===true ? 1 : (myNote['VANEX_TAGS']===false ? 0 : myNote['VANEX_TAGS']);
          // console.log("myNote", myNote, " t = ", thisNoteTag)
          if (myNote.VANEX_NOTES && (myNote.ANALYSIS_ID !== currentAnalysisId) && (myNote.VANEX_NOTES.length > 24) ){  //! set the length here
            allExistingNotesAggregated.push(
              {
                ...myNote,
                VANEX_TAGS: thisNoteTag
              }
            );
          }
        });
      });
      
      allExistingNotesAggregated.sort( (elA,elB) => (elA.UPDATED_AT > elB.UPDATED_AT) ? -1 : ((elB.UPDATED_AT > elA.UPDATED_AT) ? 1 : 0)); // * reverse sorting (descending)
      // console.log("allExistingNotesAggregated", allExistingNotesAggregated);
      // console.log(activeCaseInfo)
      // allExistingNotesAggregated.sort( (elA,elB) =>  elA.UPDATED_AT.localeCompare(elB.UPDATED_AT)); // * ascending
      // setOpenCases(prevOpenCases => prevOpenCases.map(indvCase => (indvCase[0] === caseAnalysisId ? [indvCase[0], [...indvCase[1]], allExistingNotesAggregated] : indvCase)));
      // console.log("allExistingNotesAggregated", allExistingNotesAggregated.map( vr => ({...vr, VANEX_TAGS: 3 }) ))
      // console.log('allExistingNotesAggregated', allExistingNotesAggregated);
      setAggregatedNotes(prevAgg => ({...prevAgg, [entrezid]:allExistingNotesAggregated}) )

      // setOpenCases(prevOpenCases => prevOpenCases.map(indvCase => (indvCase[0] === caseAnalysisId ? 
      //   indvCase.map( (pieceOfCaseInfo, infoIndex) => (infoIndex === 1 ? 
      //     pieceOfCaseInfo.map( variantData => {
      //       let foundNotes = Object.values(allExistingNotesAggregated)
      //       // .map( varData => ({...varData, VANEX_TAGS: (varData['VANEX_TAGS']===true ? 1 : (varData['VANEX_TAGS']===false ? 0 : varData['VANEX_TAGS']) ) }) )
      //       .filter( t => t['CHROMOSOME']===variantData['CHROMOSOME']
      //                 &&  t['POSITION']===variantData['POSITION']
      //                 &&  t['REF']===variantData['REF']
      //                 &&  t['ALT']===variantData['ALT'] )
      //       .map( ({USERNAME, VANEX_TAGS, VANEX_NOTES, UPDATED_AT, IS_SET_BY_OTHERS}) => ({USERNAME, VANEX_TAGS, VANEX_NOTES, UPDATED_AT, IS_SET_BY_OTHERS}));
      //       return( (Object.keys(foundNotes).length > 0) ?
      //         {...variantData, VANEX_NOTES:  {...variantData['VANEX_NOTES'] , ALL_USERS_NOTES:foundNotes} }
      //       :
      //         variantData
      //       );
      //     })
      //     : pieceOfCaseInfo))
      // : indvCase)))

      // console.log(" notes loaded successfully!");
      setIsLoadingAggNotes(false);
    })
    .catch((e) => {
      toast.error("Error: " + e);
      setIsLoadingAggNotes(false);
    });
  }


  useEffect(() => {
    let timer;
    if(numberOfNotesSaved!==null && numberOfNotesSaved!==undefined){
      timer = setTimeout(() => {setNumberOfReviewSaved(null); setNumberOfNotesSaved(null)}, 5000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [numberOfNotesSaved])

  useEffect(() => {
    if(!!preparedDataFromVariantTable){
      navigator.clipboard.writeText(preparedDataFromVariantTable);
      console.log("Copied to Clipboard!");
      // console.log(preparedDataFromVariantTable);
      setPreparedDataFromVariantTable(null);
    }
  }, [preparedDataFromVariantTable])

  //? --------------------------------------------- Filters ----------------------------------

  // const [filterSelectionIndeterminateCheckboxStatus, setFilterSelectionIndeterminateCheckboxStatus] = useState(UNCHECKED);
  const [filterSelectionFormik, setFilterSelectionFormik] = useState(null);
  const [filterSelectionTitle, setFilterSelectionTitle] = useState('Filtering');
  const [filterSelectionModal, setFilterSelectionModal] = useState(null);
  const refForFilterSelectionModal = React.useRef();
  const refForCreatableSelect = React.useRef();

  const [visibleFilterSets, setVisibleFilterSets] = useState(() => {
    let tmpVisibleSetsObj = [];
    Object.values(filterDefinition.SNV_FILTER_SET).filter(t => t.visible === true).forEach( (myFilter, filterInd) => {
    // Object.keys(Object.values(filterDefinition.SNV_FILTER_SET).filter(t => t.visible==true)).FILTER_DEFINITIONS).forEach( tabFilterId => {
    // Object.keys(filterDefinition.SNV_FILTER_SET[0].FILTER_DEFINITIONS).forEach( filterId => {
      let tmpFilterSet = {};
      let tmpDefinitionObj = {};

      Object.keys(myFilter.FILTER_DEFINITIONS).filter( t => ( myFilter.FILTER_DEFINITIONS[t].visible === true)
      ).forEach( filterId => {
        // * createFilterObjectFromJSON
        // console.log(filterId);
        let filter = myFilter.FILTER_DEFINITIONS[filterId];
        let tmpObj = {
          columnId: filter.column_id,
          label: filter.label,
          value: ( (!!filter.value) ? filter.value : null ),
          visible: (filter.visible===true || filter.visible===false) ? filter.visible : true, 
          editable: (filter.editable===true || filter.editable===false) ? filter.editable : true,
          getFilterDescr: ( (!!filter.value) ? function(){ return(this.label + this.value);} : function(){ return(this.label);} ),
          filterFunction: ConstructFilterFunctions(filter),
          isApplied: (filter.isApplied===true || filter.isApplied===false) ? filter.isApplied : false,
          isSpecial: filter.isSpecial===true
        }
        // console.log("tmpObj",tmpObj.filterFunction)
        tmpDefinitionObj[filterId]=tmpObj;
      });
      tmpFilterSet['value']=filterInd;
      tmpFilterSet['label']=myFilter.label;
      tmpFilterSet['user_defined']=false;
      tmpFilterSet['visible']=myFilter.visible;
      tmpFilterSet['FILTER_DEFINITIONS']=tmpDefinitionObj;
      tmpVisibleSetsObj.push(tmpFilterSet);
    });

    //TODO: fetch user filters and add to this with tmpFilterSet['user_defined']=true; (async? in another method?)

    // console.log('tmpVisibleSetsObj');
    // console.log(tmpVisibleSetsObj);
    return(tmpVisibleSetsObj);
  });

  const [isLoadingVisibleFilterSets, setIsLoadingVisibleFilterSets] = useState(false);
  // const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState(props.defaultFilterSet || null);
  const [selectedFilterSetIndex, setSelectedFilterSetIndex] = useState(null);
  const [isFilterSetModified, setIsFilterSetModified] = useState(false);
  const [filterDescriptions, setFilterDescriptions] = useState([]);
  const [globalTabFilters, setGlobalTabFilters] = useState({});




  //? --------------------------------------------- Apply Filters ----------------------------------

  const groupBy = (data, key) => {
    return data.reduce(function(rval, item) {
      (rval[item[key]] = rval[item[key]] || []).push(item);
      return rval;
    }, {});
  };

  // function notIn(inFunction){ return function(){return !inFunction.apply(this, arguments);};}
  
  // ! ------ Filter ------
  const applyFilter = (tmpVariantData, tabId) => {
    // console.log("applyFilter");
    let filterSpec = [];

    if (tabId === 'TAB_PARADIGM'){// //!!!!! && !!props.paradigmData && props.paradigmData.length>0
    //  //!!!!!  tmpVariantData = Object.values(tmpVariantData).filter(vr => props.paradigmData.map(el => el["entrezId"].toString()).includes(vr["ENTREZ_ID"]));
      tmpVariantData = Object.values(tmpVariantData).filter(vr => !!vr["PARADIGM"]);
    }

    // const IGNORE_FILTERS_4_PLP_CALLS=(!!tabs && tabs["TAB_CLINVAR"] ? tabId==="TAB_CLINVAR" : tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS && tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS.isApplied);
    const IGNORE_FILTERS_4_PLP_CALLS=tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS && tabs[tabId].filters.IGNORE_FILTERS_4_PLP_CALLS.isApplied;

    // ! add this:  check if clinvar then add all variants in those genes to make sure there is no comp.het. !
    Object.values(globalTabFilters).filter(k => !!k['isApplied']).forEach((filterTerm)=> {
      // if (filterTerm.isApplied!== undefined && filterTerm.isApplied!== null && filterTerm.isApplied===true){
        tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS)); 
      // }
    });

    // ! To Be Deleted (this is added to the global filters)
    // if(selectedPathwayGeneEntrezIds.length>0){
    //   tmpVariantData = Object.values(tmpVariantData).filter(t => ( selectedPathwayGeneEntrezIds.includes(t['ENTREZ_ID'])) );
    // }

    if(tabId==='TAB_SUMMARY' && !!tabs[tabId] && !!tabs[tabId].filters['MAIN_B'] && !!tabs[tabId].filters['MAIN_B'].isApplied){
      // console.log(tabs[tabId].filters.MAIN_A.filterFunction);
      // tmpVariantData = Object.values(tmpVariantData).filter( tabs[tabId].filters['MAIN_B'].filterFunction()); 
      Object.keys(tabs[tabId].filters).filter(k => !!tabs[tabId].filters[k]['isApplied']).forEach( filterKey => {
        let filterTerm = tabs[tabId].filters[filterKey];
        if( filterKey !=='MAIN_A'){
          tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS)); 
        }
      });
    } else {
      Object.values(tabs[tabId].filters).filter(k => !!k['isApplied']).forEach((filterTerm)=> {
        tmpVariantData = Object.values(tmpVariantData).filter( filterTerm.filterFunction(IGNORE_FILTERS_4_PLP_CALLS, PMFSampleColNames));
      });
      // * making sure that TAB_Filter is applied after other filters
      if( !!tabs[tabId] && !!tabs[tabId].filters['MAIN'] ){
        tmpVariantData = Object.values(tmpVariantData).filter( tabs[tabId].filters['MAIN'].filterFunction());
      }
    }

    if (tabId === 'TAB_DENOVO'){
      // tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] !== 'ref' && 
      //                                                       t[PMFSampleColNames[1]] !== 'het' && t[PMFSampleColNames[1]] !== 'hom' &&
      //                                                       t[PMFSampleColNames[2]] !== 'het' && t[PMFSampleColNames[2]] !== 'hom'));
      if (PMFSampleColNames.includes('None')){
        //* filter everything to show "No data" since this needs full trio 
        tmpVariantData = Object.values(tmpVariantData).filter(t => false);
      } else {
        tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] !== 'ref' && t[PMFSampleColNames[1]] === 'ref' && t[PMFSampleColNames[2]] === 'ref'));
      }
    }
    
    if (tabId === 'TAB_HOMREC'){
      tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] === 'hom' && t[PMFSampleColNames[1]] !== 'ref' && t[PMFSampleColNames[2]] !== 'ref'));
    }
    
    if (tabId === 'TAB_COMPHET'){
      if (PMFSampleColNames.includes('None')){
        //* filter everything to show "No data" since this needs full trio 
        tmpVariantData = Object.values(tmpVariantData).filter(t => false);
      } else{
        tmpVariantData = Object.values(tmpVariantData).filter(t => (t[PMFSampleColNames[0]] === 'het' && 
        ( (t[PMFSampleColNames[1]] === 'ref' && t[PMFSampleColNames[2]] !== 'ref') ||
        (t[PMFSampleColNames[1]] !== 'ref' && t[PMFSampleColNames[2]] === 'ref'))
        ));
        // ! fix this, need to have G and M GT!
        // ? Comp. Het. needs special analysis. This code selects TRUE Comp.Hets
        // tmpVariantData = Object.values(tmpVariantData).filter(t => t['Inherit Model'] === 'ch');

        //? remove the genes with only 1 variant
        let groupedByGene=groupBy(tmpVariantData, 'GENE');
        groupedByGene = Object.keys(groupedByGene).filter(t => groupedByGene[t].length > 1);
        tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['GENE']));

        //? keep the true comp. het. variants
        if (PMFSampleColNames.length > 0 && !!tmpVariantData[0] && tmpVariantData[0][PMFSampleColNames[1]] && tmpVariantData[0][PMFSampleColNames[2]]){
          groupedByGene=groupBy(tmpVariantData, 'GENE');
          groupedByGene = Object.keys(groupedByGene).filter(gene => {
            // console.log(`group ${gene} has ${groupedByGene[gene].length} members : `);
            let momGT=[];
            let dadGT=[];
            groupedByGene[gene].forEach ((t,i) => {
              momGT.push(t[PMFSampleColNames[1]]!=='ref');
              dadGT.push(t[PMFSampleColNames[2]]!=='ref');
            })
            return (momGT.includes(true) && dadGT.includes(true));
          }); 
          tmpVariantData = Object.values(tmpVariantData).filter(t => Object.values(groupedByGene).includes(t['GENE']));
        }
      }

      // filterSpec.push("Inheritance = Comp.Het.");
      filterSpec.push("Only show TRUE Comp.Het.");
    }

    // * sort variants based on damaging score in both LOF and DAMAGING tabs
    if (tabId === 'TAB_DAMAGING'){
      tmpVariantData = Object.values(tmpVariantData).filter(t => (calCumulativeDamagingScore(t)>0.3) ).sort( (elA,elB) => (calCumulativeDamagingScore(elB) - calCumulativeDamagingScore(elA)));
    }
    
    if (tabId === 'TAB_LOF'){
      tmpVariantData.sort( (elA,elB) => (calCumulativeDamagingScore(elB) - calCumulativeDamagingScore(elA)));
    }

    if (tabId === 'TAB_PARADIGM'){
      tmpVariantData.sort( (elA,elB) => ( !!elA.PARADIGM && !!elA.PARADIGM['FULL_RECORD'] && !!elA.PARADIGM['FULL_RECORD']['geneRank'] &&
                                          !!elB.PARADIGM && !!elB.PARADIGM['FULL_RECORD'] && !!elB.PARADIGM['FULL_RECORD']['geneRank']
                                          ? elA.PARADIGM['FULL_RECORD']['geneRank'] - elB.PARADIGM['FULL_RECORD']['geneRank'] : 0 ));
    }

    // if(props.project==='rd') filterSpec.push("gnomAD frq. < 0.001");

    return([tmpVariantData, filterSpec]);
  }


  const updateFilterSet = (filterSetIndex, selectedTabId, keepItOff=false) => {
    let tmpTabFilterObj = {};

    // * add Tab-Filters defined in the JSON for this tab
    let tabFilterDefinition = filterDefinition.RD_TAB_FILTER_SET.FILTER_DEFINITIONS[selectedTabId];
    if(tabFilterDefinition !== undefined && tabFilterDefinition !==null){
      Object.keys(tabFilterDefinition).forEach( tabFilterId => {
        // * createFilterObjectFromJSON
        let filter = tabFilterDefinition[tabFilterId];
        let tmpObj = {
          columnId: filter.column_id,
          label: filter.label,
          value: null,
          visible: (filter.visible===true || filter.visible===false) ? filter.visible : false, 
          editable: (filter.editable===true || filter.editable===false) ? filter.editable : false,
          getFilterDescr: function(){ return(this.label)},
          filterFunction: ConstructFilterFunctions(filter),
          isApplied: (filter.isApplied===true || filter.isApplied===false) ? filter.isApplied : true,
          isSpecial: filter.isSpecial===true
        }
        tmpTabFilterObj[tabFilterId]=tmpObj;
      });
    }

    // * add filters from visibleFilterSets
    if (filterSetIndex !==undefined && filterSetIndex !== null && !!visibleFilterSets &&
      !!visibleFilterSets[filterSetIndex] && !!visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS){
      Object.keys(visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS).forEach( filterId => {

        // * if we have a CLINVAR tab, only use IGNORE_FILTERS_4_PLP_CALLS in the CLINVAR tab
        // * and do not apply this in any other tabs, otherwise apply it (use whatever is in JSON).
        if(keepItOff || (!!tabs && tabs["TAB_CLINVAR"] && selectedTabId!=="TAB_CLINVAR" && filterId==="IGNORE_FILTERS_4_PLP_CALLS"))
          tmpTabFilterObj[filterId]={...visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS[filterId], isApplied:false};
        else
          tmpTabFilterObj[filterId]={...visibleFilterSets[filterSetIndex].FILTER_DEFINITIONS[filterId]};
      });
    }

    setTabs( prevTabs => ({
      ...prevTabs,
      [selectedTabId]: {
        ...prevTabs[selectedTabId],
        filters: tmpTabFilterObj
      }
    }));

  }



  //? --------------------------------------------- main useEffects ---------------------------------- 
  const [mainUseEffectsIsDone, setMainUseEffectsIsDone] = useState(false);
  
  useEffect(() => {
    // set Modal for selecting column filters
    setFilterSelectionModal( new Modal(refForFilterSelectionModal.current));

    // set Modal for changing sample type
    setSampleTypeModal( new Modal(refForChangeSampleTypeModal.current));

    // ! fix   'MR_PHENE_PHENOTYPE_TERMS' in all three 
    let defaultVisibleColumns = ['VANEX_TAGS','VANEX_NOTES','VANEX_REVIEWS','CHROMOSOME','POSITION','REF','ALT','QUAL','P_GT','GENE','LOC_IN_GENE','EFFECT','CLINVAR_CLNSIG','OMIM_PHENOTYPES','GNOMAD_WG_AC','GNOMAD_EX_AC','IGM_CFK_PANEL_MEMBERSHIP','HEART_ENHANCER_AVERAGE_SCORE_STRING','HEART_ENHANCER_SCORE_CATEGORY','MR_PHENE_PHENOTYPE_TERMS','CLINGEN_DISEASE_HIGHEST_CLASSIFICATION','DNP','SPLICEAI_DS_MAX_V4']; // ,'GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX'
    let include_GT = true;
    let include_Alt_Percent = false;
    let columnToUseForPMF = '_GT';
    let defaultFilterSet = null;

    if (props.project==="paradigm") defaultVisibleColumns = ['VANEX_TAGS','VANEX_NOTES','CHROMOSOME','POSITION','REF','ALT','QUAL','P_GT','GENE','LOC_IN_GENE','EFFECT','PHENOTYPE_RANKING','PHENOTYPE_TERMS','CLINVAR_CLNSIG','OMIM_PHENOTYPES','GNOMAD_WG_AC','GNOMAD_EX_AC','MR_PHENE_PHENOTYPE_TERMS']; // ,'GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX'

    if(!!props.onlyShowOneTab) defaultVisibleColumns.push('CASE_ID')
    
    // * set Analysis_Type specific settings
    if(!!props.currentAnalysisInfo && ( props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV' || props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV') ){
      // defaultVisibleColumns = ['VANEX_TAGS','VANEX_NOTES','CHROMOSOME','POSITION','REF','ALT','QUAL','P_GT','GENE','LOC_IN_GENE','EFFECT','CLINVAR_CLNSIG','DIST_TO_CODING_REGION','MAX_AD','EPILEPSY_GENE_LIST','OMIM_PHENOTYPES','IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_FRACTION','IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_FRACTION','IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_FRACTION','GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX','STJCAN_CATEGORY','FILTER'];
      defaultVisibleColumns = ['VANEX_TAGS','VANEX_NOTES','VANEX_REVIEWS','CHROMOSOME','POSITION','REF','ALT','QUAL','P_GT','GENE','LOC_IN_GENE','EFFECT','CLINVAR_CLNSIG','MAX_AD','EPILEPSY_GENE_LIST','OMIM_PHENOTYPES','GNOMAD_WG_AC','GNOMAD_EX_AC','MR_PHENE_PHENOTYPE_TERMS'];// ,'GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX'
      include_GT = false;
      include_Alt_Percent = true;
      columnToUseForPMF = '_Alt_Percent';
      if(props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV') defaultFilterSet=3;
      if(props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV') defaultFilterSet=4;
      if(props.currentAnalysisInfo.ANALYSIS_TYPE==='GermlineSNV' && props.project==='ep') defaultFilterSet=7;
      if(props.currentAnalysisInfo.ANALYSIS_TYPE==='SomaticSNV' && props.project==='ep') defaultFilterSet=8;

    }

    if(!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE==='SNV_Pheno') {
      defaultVisibleColumns = ['VANEX_TAGS','VANEX_NOTES','VANEX_REVIEWS','CHROMOSOME','POSITION','REF','ALT','QUAL','P_GT','GENE','LOC_IN_GENE','EFFECT','PHENOTYPE_RANKING','PHENOTYPE_TERMS','CLINVAR_CLNSIG','OMIM_PHENOTYPES','GNOMAD_WG_AC','GNOMAD_EX_AC','MR_PHENE_PHENOTYPE_TERMS']; // ,'GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX'
      defaultFilterSet=6;
    }

    if(!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE==='SNV' && props.project==='bh')
      defaultFilterSet=5;
    else if(!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE==='SNV')
      defaultFilterSet=2;

    if(!!props.currentAnalysisInfo && props.currentAnalysisInfo.ANALYSIS_TYPE==='GenomeWideDeNovo' ){
      defaultVisibleColumns = ['VANEX_TAGS','VANEX_NOTES','VANEX_REVIEWS','CHROMOSOME','POSITION','REF','ALT','QUAL','P_GT','FUNC_ELEMENT','PRDCTD_REVIEW','Y_SCORE','GENE','LOC_IN_GENE','EFFECT','CLINVAR_CLNSIG','OMIM_PHENOTYPES','GNOMAD_WG_AC','GNOMAD_EX_AC','MR_PHENE_PHENOTYPE_TERMS']; // ,'GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX'
      include_GT = true;
      include_Alt_Percent = false;
      columnToUseForPMF = '_GT';
      defaultFilterSet = 0;
    }

    let hiddenCols = [];
    if(!props.hiddenColumnsIds){
      // * set hidden columns
      // hiddenCols = Object.keys(inputData[0]).filter(t => !defaultVisibleColumns.includes(t) ).map( k => k);
      //! fix this, move columnData to here?! and then use the top line instead of the below

      Object.values(columnData).forEach(column => {
        if(!defaultVisibleColumns.includes(column.id) &&
            !(include_GT && column.id.endsWith('_GT')) &&
            !(include_Alt_Percent && column.id.endsWith('_Alt_Percent'))
          ){
          hiddenCols.push(column.id);
        }
      });
      setHiddenColumnsIds(hiddenCols);
    } else { hiddenCols = hiddenColumnsIds}


    Object.keys(tabs).forEach( tabId => {
      let filterSetIndex = undefined;
      if(tabId!=='TAB_SUMMARY') filterSetIndex = defaultFilterSet || 0;
      updateFilterSet(filterSetIndex, tabId, tabId==='TAB_PARADIGM');
    } );

    // setSelectedFilterSetIndex(defaultFilterSet);

    if(selectedVariantTab === 'TAB_SUMMARY') 
      setSelectedFilterSetIndex(null);

    let sampleColNames = {};
    if (!!props.currentAnalysisInfo && !!props.currentAnalysisInfo.SAMPLES){
      props.currentAnalysisInfo.SAMPLES.forEach(element => {
        sampleColNames[`${element.SAMPLE_ID}${columnToUseForPMF}`]=element.SAMPLE_TYPE;
        // sampleColNames[element.SAMPLE_TYPE]=`${element.SAMPLE_ID}${columnToUseForPMF}`;
      });
      if (!props.PMFSampleColNames)
        setPMFSampleColNames( [getSampleColumnId('Proband', sampleColNames), getSampleColumnId('Mother', sampleColNames), getSampleColumnId('Father', sampleColNames) ]);
      
    } else {
      columnData.filter(t => t.id.endsWith(columnToUseForPMF))
        .map(i => i.id)
        .sort( (elA,elB) => (GetSampleRank(elB, true) - GetSampleRank(elA, true)) )
        .forEach(element => {
          sampleColNames[element]="None";
        });
    }
    setSampleColumns(sampleColNames);

    // * set column order
    // if (!!props.PMFSampleColNames) sampleColNames= PMFSampleColNames;
    // if(sampleColNames.length > 0){
    //   let colOrder = Object.values(columnData).map(i => i.id).filter(t => (!hiddenCols.includes(t) && !PMFSampleColNames.includes(t) ) );
    //   console.log(colOrder);
    //   console.log(PMFSampleColNames);
    //   colOrder =[...colOrder, ...PMFSampleColNames]
    //   console.log(colOrder);
    //   setColumnOrder( colOrder );
    // }
    setColumnOrder( Object.values(columnData).map(i => i.id).filter(t => !hiddenCols.includes(t)) );
    // setColumnOrder( Object.values(columnData).map(i => i.id).filter(t => !hiddenCols.includes(t)).sort( (elA,elB) => (defaultVisibleColumns.indexOf(elA) - defaultVisibleColumns.indexOf(elB))  ) );

    // ! does this work? fix it if not!
    if(!props.userName && canSaveNotes){ 
      toast.error(`Cannot save notes since you are either not logged in or your session has expired! (${props.userName})`, {duration:8000});
    }
    setMainUseEffectsIsDone(true);

  }, []);

  const variantData = React.useMemo(() => {
    let [filteredVariantData, filterSpec] = applyFilter(props.caseData, selectedVariantTab);  // ! [...props.caseData] or  props.caseData ?
    setFilterDescriptions(filterSpec);
    return(filteredVariantData);
  }, [tabs, globalTabFilters, selectedVariantTab, props.caseData, refresh, selectedPathwayGeneEntrezIds]); //! deconstruct props and use individual ones?? 


  useEffect(() => {
    setLatestTablePageIndex(0);
  }, [tabs, globalTabFilters, selectedVariantTab]);


  // const [dbSNPSrc, setdbSNPSrc] = useState(''); //! not used, so delete this?

  const [loadingClinVarPlot, setLoadingClinVarPlot] = useState(false);
  const [autoLoadFirefly, setAutoLoadFirefly] = useState(false);
  const [clinVarPlotSrc, setClinVarPlotSrc] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const loadClinVarPlot = (currentVariant) => {
    setLoadingClinVarPlot(true);

    let geneName = currentVariant.GENE;
    GetSignedURL('nch-igm-vanex-client',`ClinVarPlots/GRCH38-Varhouse/${geneName.toUpperCase()}%2F${geneName.toUpperCase()}.ALL.png`) 
    .then(result_json => {
      setClinVarPlotSrc(result_json);
      setErrorMessage('');
      setLoadingClinVarPlot(false);
    }).catch(e => {
      setClinVarPlotSrc('');
      setLoadingClinVarPlot(false);
      setErrorMessage(`Failed to load the image. (${e})`);
    });
  }

  const getClinvarSignificanceStyle = (clinvarSig) => {
    let style = 'rounded-pill px-2 py-1 ';
    if( (clinvarSig !== null && clinvarSig !==undefined) && (clinvarSig.toLowerCase()==='pathogenic' || clinvarSig.toLowerCase()==='likely_pathogenic' || clinvarSig.toLowerCase()=== 'pathogenic/likely_pathogenic')){
      style += ' bg-danger text-light ';
    }
    else if( (clinvarSig !== null && clinvarSig !==undefined) && (clinvarSig.toLowerCase()==='benign' || clinvarSig.toLowerCase()==='likely_benign' || clinvarSig.toLowerCase()=== 'benign/likely_benign')){
      style += ' bg-success text-light ';
    }
    else{
      style += ' bg-warning text-dark ';
    }
    return style;
  }


  const getMetaSummary = () => {
    let nDamaging = damagingScores[0];
    let nUnknown =damagingScores[1];
    let nTolerated =damagingScores[2];
    let nTotal =damagingScores[3];

    let pTolerated =100*nTolerated/nTotal;
    let pUnknown =100*nUnknown/nTotal;
    let pDamaging =100*nDamaging/nTotal;

    return(
      <div className="d-flex flex-row flex-nowrap" style={{width:'10rem'}}> {/* style={{width:'15em'}} */}
        {(pDamaging > 0) ? <div className="bg-danger text-light d-flex flex-row flex-nowrap align-items-center justify-content-center fw-bold" style={{width:`${pDamaging}%`}}>{nDamaging}</div> : null}
        {(pUnknown > 0) ? <div className="bg-warning text-dark d-flex flex-row flex-nowrap align-items-center justify-content-center fw-bold" style={{width:`${pUnknown}%`}}>{nUnknown}</div> : null}
        {(pTolerated > 0) ? <div className="bg-success text-light d-flex flex-row flex-nowrap align-items-center justify-content-center fw-bold" style={{width:`${pTolerated}%`}}>{nTolerated}</div> : null}
        {(nTotal === 0) ? <div className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center" >N/A</div> : null}
      </div>
    );
  }

  const getIndividualSummary = () => {
    let nDamaging = damagingScores[4];
    let nUnknown =damagingScores[5];
    let nTolerated =damagingScores[6];
    let nTotal =damagingScores[7];
    let nNoCats =damagingScores[8];

    let pTolerated =100*nTolerated/nTotal;
    let pUnknown =100*nUnknown/nTotal;
    let pDamaging =100*nDamaging/nTotal;
    let pNoCats =100*nNoCats/nTotal;

    return(
      <div className="d-flex flex-row flex-nowrap" style={{width:'25rem'}}> {/* style={{width:'30em'}} */}
        {(pDamaging > 0) ? <div className="bg-danger text-light d-flex flex-row flex-nowrap align-items-center justify-content-center fw-bold" style={{width:`${pDamaging}%`}}>{nDamaging}</div> : null}
        {(pUnknown > 0) ? <div className="bg-warning text-dark d-flex flex-row flex-nowrap align-items-center justify-content-center fw-bold" style={{width:`${pUnknown}%`}}>{nUnknown}</div> : null}
        {(pTolerated > 0) ? <div className="bg-success text-light d-flex flex-row flex-nowrap align-items-center justify-content-center fw-bold" style={{width:`${pTolerated}%`}}>{nTolerated}</div> : null}
        {(pNoCats > 0) ? <div className="bg-info text-dark d-flex flex-row flex-nowrap align-items-center justify-content-center fw-bold" style={{width:`${pNoCats}%`}}>{nNoCats}</div> : null}
        {(nTotal === 0) ? <div className="w-100 d-flex flex-row flex-nowrap align-items-center justify-content-center" >N/A</div> : null}
      </div>
    );
  }


  const getPredictionStyle = (prediction, mutTaster=false) => {
    let predictionStyle = 'rounded-pill px-2 pt-1 ';
    let predictionText = '';
    if(prediction !== null && prediction !==undefined && prediction !=='.' ){
      if(prediction.toLowerCase()==='d'){
        predictionStyle += ' text-danger ';
        predictionText = 'Damaging';
      }
      else if(prediction.toLowerCase()==='p'){
        predictionStyle += ' text-danger ';
        predictionText = 'Pathogenic';
      }
      else if(prediction.toLowerCase()==='m'){
        predictionStyle += ' text-danger ';
        predictionText = 'Medium';
      }
      else if(prediction.toLowerCase()==='h'){
        predictionStyle += ' text-danger ';
        predictionText = 'High';
      }
      else if(prediction.toLowerCase()==='t'){
        predictionStyle += ' text-success ';
        predictionText = 'Tolerated';
      }
      else if(prediction.toLowerCase()==='b'){
        predictionStyle += ' text-success ';
        predictionText = 'Benign';
      }
      else if(prediction.toLowerCase()==='n'){
        predictionStyle += ' text-success ';
        predictionText = 'Neutral';
      }
      else if(prediction.toLowerCase()==='l'){
        predictionStyle += ' text-success ';
        predictionText = 'Low';
      }
      else if(prediction.toLowerCase()==='u'){
        predictionStyle += ' text-warning fw-bold ';
        predictionText = 'Unknown';
      }
      else {
        predictionText = prediction;
      }
      if(mutTaster){
        if(prediction.toLowerCase()==='d'){
          predictionStyle += ' text-danger ';
          predictionText = 'Disease causing';
        }
        else if(prediction.toLowerCase()==='a'){
          predictionStyle += ' text-danger text-small ';
          predictionText = 'Disease causing auto.';
        }
        else if(prediction.toLowerCase()==='n'){
          predictionStyle += ' text-success';
          predictionText = 'Polymorphism';
        }
        else if(prediction.toLowerCase()==='p'){
          predictionStyle += ' text-success text-small ';
          predictionText = 'Polymorphism automatic';
        }
      }
      return(<div className={predictionStyle}>{predictionText}</div>)
    }
    else{
      return(<div>{prediction}</div>)
      // return(<div></div>)
    }
  }

  const getBgSensitiveNumber = (inputVal, threshold=0.5, fixed=2) => {
    let tagValue = inputVal;
    let className = " ";
    if(inputVal!==null && inputVal!==undefined){
      tagValue = Number(inputVal);
      if(tagValue > threshold) className += ' bg-pink '
    }
    return(<span className={className} style={{padding:'.1em .3em',borderRadius:'.4em'}}>{Number(tagValue).toFixed(fixed)}</span>)
  }


  //? --------------------------------------------- saveNotes ----------------------------------

  const saveNotes = () => {
    // ! find a better way to update progress bar?
    const updateSavingNotesProgress = (value, final=false) => {
      setSavingNotesProgressbarValue(value);
      // const interval = setInterval(() => {  // ! fix this!
      //   setSavingNotesProgressbarValue(value);
      //   if(final || value > 90) {
      //     setIsSavingNotes(false);
      //     clearInterval(interval);
      //   }
      // }, 400);
    }

    // ! does this work? fix it if not!
    if(!(!!props.userName && !!props.currentCaseInformation.CASE_ID)){
      toast.error(`Cannot save notes since you are either not logged in or your session has expired 🙁 (${props.userName} - ${props.currentCaseInformation.CASE_ID})`);
      return 1;
    }

    setIsSavingNotes(true);

    updateSavingNotesProgress(10);

    const recordsWithReview = Object.values(props.caseData).filter(t => ( t.VANEX_REVIEWS['REVIEW_VALUE']>0) )
    .map( variant => (({ VANEX_REVIEWS, CHROMOSOME, POSITION, REF, ALT }) => {
      let tmpDate = (!!VANEX_REVIEWS['UPDATED_AT'] ? VANEX_REVIEWS['UPDATED_AT'] : new Date() );
      return({ REVIEW_VALUE:VANEX_REVIEWS['REVIEW_VALUE'] || -1, CHROMOSOME, POSITION, REF, ALT, UPDATED_AT:tmpDate })
    })(variant));
    
    if(!!recordsWithReview && recordsWithReview.length > 0) {
      // console.log(recordsWithReview);
      let caseId = props.currentCaseInformation.CASE_ID; // *no need for .toUpperCase();
      let userName= "REVIEWS";
      let fileName= `${caseId}_${props.currentAnalysisInfo.ANALYSIS_ID}_${userName}.json.gz`;
      
      // const resultAsBinString = pako.gzip(Object.values(recordsWithReview).map(x=>JSON.stringify(x)).join(''));
      const resultAsBinString = pako.gzip(JSON.stringify(recordsWithReview));
      
      // const stringUtf16ThatWeNeedInUtf8 = JSON.stringify(recordsWithReview);
      // const stringUtf8 = unescape(encodeURIComponent(stringUtf16ThatWeNeedInUtf8));
      // const resultAsBinString = pako.gzip(stringUtf8);
      // const gzippedBlob = new Blob([resultAsBinString]);

      GetSignedURL('nch-igm-vanex',`case_notes/CASE_ID=${caseId}/ANALYSIS_ID=${props.currentAnalysisInfo.ANALYSIS_ID}/USERNAME=${userName}/${fileName}&upload=true`)
      .then(result_json => { 
        updateSavingNotesProgress(25);
        return(
          fetch(result_json, {
            method: 'PUT',
            // headers: {
            //   'Content-Type': 'application/json'
            //   'Content-Encoding': 'gzip',
            // },
            body: resultAsBinString //Object.values(recordsWithReview).map(x=>JSON.stringify(x)).join('\n')
          })
        );
      }).then( response => {
        if(response.status === 200){
          setNumberOfReviewSaved(Object.keys(recordsWithReview).length);
          // toast.success(`${Object.keys(recordsWithReview).length} Reviews Saved!`, {duration:2000});
          // setShouldBlockNavigation(false);
          // setIsSavingNotes(false);
          // setAutoSaveNotesCounter(0);
        }
        else { throw Error(`${response.status} - ${response.statusText}`); }
      }).catch(e => {
        toast.error(`Failed to save review data. (${e})`);
        // setShouldBlockNavigation(false);
        // setIsSavingNotes(false);
      });
    }

    const userNotes = Object.values(props.caseData).filter(t => (!!t.VANEX_TAGS || !!t.VANEX_NOTES['CURRENT_USER_NOTE']) )
    .map( variant => (({ VANEX_TAGS, VANEX_NOTES, CHROMOSOME, POSITION, REF, ALT }) => {
      let tmpDate = (!!VANEX_NOTES['UPDATED_AT'] ? VANEX_NOTES['UPDATED_AT'] : new Date() );
      return({ VANEX_TAGS, VANEX_NOTES:VANEX_NOTES['CURRENT_USER_NOTE'] || '', CHROMOSOME, POSITION, REF, ALT, UPDATED_AT:tmpDate })
    })(variant));
    // .map( variant => ( ({ VANEX_TAGS, VANEX_NOTES, CHROMOSOME, POSITION, REF, ALT }) => ({ VANEX_TAGS, VANEX_NOTES:VANEX_NOTES['CURRENT_USER_NOTE'], CHROMOSOME, POSITION, REF, ALT, UPDATED_AT:VANEX_NOTES['UPDATED_AT'] }))(variant));

    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ FILTER, CHROMOSOME, POSITION, REF, ALT }) => ({ FILTER, CHROMOSOME, POSITION, REF, ALT }))(variant));
    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ FILTER, CHROMOSOME, POSITION, REF, ALT }) => ({ FILTER, CHROMOSOME, POSITION, REF, ALT, VARIANTID: `${CHROMOSOME}_${POSITION}_${REF}_${ALT}` }))(variant));
    // const userNotes = Object.values(props.caseData).filter(t => !!t.FILTER ).map( variant => (({ CHROMOSOME, POSITION, REF, ALT, FILTER, index }) => ({ CHROMOSOME, POSITION, REF, ALT, FILTER, index }))(variant));
    
    // * no need to check for this
    // if(!(!!userNotes && userNotes.length > 0)) {
    //   toast.error('No Notes to Save!');
    //   setShouldBlockNavigation(false);
    //   setIsSavingNotes(false);
    //   return;
    // }

    let caseId = props.currentCaseInformation.CASE_ID; // *no need for .toUpperCase();
    let userName= props.userName.replaceAll(' ','_');                     // ! do we need to make it .toUpperCase()?
    let fileName= `${userName}.json`;
    //!! add this back let fileName= `${caseId}_${props.currentAnalysisInfo.ANALYSIS_ID}_${userName}.json`;
    // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?upload=true&bucket=nch-igm-vanex&key=case_notes/CASE_ID=${caseId}/${userName}.json`;
    // const url_to_fetch = ENDPOINT_BASE+`get_signed_url?upload=true&bucket=nch-igm-vanex&key=case_notes/CASE_ID=${caseId}/USERNAME=${userName}/${userName}.json`;
    GetSignedURL('nch-igm-vanex',`case_notes/CASE_ID=${caseId}/ANALYSIS_ID=${props.currentAnalysisInfo.ANALYSIS_ID}/USERNAME=${userName}/${fileName}&upload=true`)
    .then(result_json => {
      updateSavingNotesProgress(50);
      return(
        fetch(result_json, {
          method: 'PUT',
          headers: {
            // 'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: Object.values(userNotes).map(x=>JSON.stringify(x)).join('\n')
          // body: JSON.stringify(userNotes)
          // body: JSON.stringify({a: 1, b: 'Textual content'})
        })
      );
    }).then( response => {
      if(response.status === 200){
        setNumberOfNotesSaved(Object.keys(userNotes).length);
        // setTimeout(() => {setSaveNotesMessage(`${Object.keys(userNotes).length} Notes Saved!`)}, 1000); //! fix this hack!
        // toast.success(`${Object.keys(userNotes).length} Notes Saved!`, {duration:2000});
        setShouldBlockNavigation(false);
        setIsSavingNotes(false);
        setAutoSaveNotesCounter(0);
      }
      else { throw Error(`${response.status} - ${response.statusText}`); }
    }).catch(e => {
      toast.error(`Failed to save notes. (${e})`);
      setShouldBlockNavigation(false);
      setIsSavingNotes(false);
    });

  }

  //? --------------------------------------------- Exports ----------------------------------

  const shouldExportThisTab = (scope, tabId) => {
    switch(scope){
      case 'summary':
        return(tabId==='TAB_SUMMARY');
      case 'noOriginalData':
        return(tabId!=='TAB_ALL');
      default:
        return true;
    }
  }

  const notesOptions = (scope) => {
    if(scope==='autoSavingNotes')
      setIsAutoSavingNotes(prevState => !prevState);
    if(scope==='hideOtherUsersNotes')
      setShowOtherUsersNotes(prevState => !prevState);
  }

  const exportSnvAsXlsx = (scope) => {

    if (scope==="clipboard"){
      setTriggerExportPreparation(true);
      // navigator.clipboard.writeText(preparedDataFromVariantTable);

      // var header = visibleColumns.filter(col => col.id!=="VANEX_TAGS").map(k => k.Header).join('\t')+"\n";
      const visColumns = columnData.filter(col => (col.id!=="VANEX_TAGS" && !hiddenColumnsIds.includes(col.id)) )
      const header =  visColumns.map(k => (k.id==="VANEX_NOTES" ? k.Header +"\t." : k.Header)).join('\t')+"\n";

      var rowData = variantData.map(variantCallInfo => 
        visColumns.map(column => column.id)
          .map(colId => {
            let cellValue = variantCallInfo[colId];
            if (cellValue){
              if(colId==="VANEX_NOTES"){
                // console.log(variantCallInfo[colId]);
                return(variantCallInfo[colId].CURRENT_USER_NOTE.replace('\n','') + "\t [" + 
                  variantCallInfo[colId].ALL_USERS_NOTES.filter(nt => nt.IS_SET_BY_OTHERS===true).map(x => " «" + x.USERNAME + "»: " + x.VANEX_NOTES.replace('\n','') ).join("],  [") +
                "]")
              }
              else if(colId==="VANEX_REVIEWS"){
                return(variantCallInfo[colId].REVIEW_VALUE)
              }
              else if (typeof(cellValue)==='string' && cellValue.trim()!== ''){
                // if(colId=="LINK_JIRA"){
                //   return("=HYPERLINK('"+cellValue+"';'JIRA')")
                // }
                // if(colId=="LINK_CONFLUENCE"){ 
                //   return("=HYPERLINK('"+cellValue+"';'JIRA')")
                // }
                cellValue = cellValue.replaceAll('\t', ' ').replaceAll('\n', ' ').trim()
              }
            }
            return(cellValue)
          }).join('\t')
      ).join("\n")
      // console.log(header + rowData)
      navigator.clipboard.writeText(header + rowData);

      return;
    }

    // ! find a better way to update progress bar?
    const updateExportProgress = (value, final=false) => {
      setExportProgressbarValue(value);
      // setTimeout(() => {setExportProgressbarValue(value)}, 100); //! fix this hack!
      // const interval = setInterval(() => { // ! fix this!
      //   setExportProgressbarValue(value);
      //   if(final || value > 90) {
      //     setIsExporting(false);
      //     clearInterval(interval);
      //   }
      // }, 400);
    }

    setIsExporting(true);
    
    let outputFileName = "VaNex-output";
    let xlsxSheetData= [];

    updateExportProgress(1);
    // let deepCopiedCaseData = JSON.parse(JSON.stringify(props.caseData));
    let deepCopiedCaseData = structuredClone(props.caseData);

    updateExportProgress(10);

    let totalTabs = Object.keys(tabs).length;
    Object.keys(tabs).forEach( (tabId, index) => {
      if(shouldExportThisTab(scope, tabId)){
        // * apply filteres for each tab!
        let [tmpVariantData, filterSpec]= applyFilter(deepCopiedCaseData, tabId); //! [...props.caseData], tabId);  // ! [...props.caseData] or  props.caseData ?
        Object.keys(tmpVariantData).forEach( rId => {tmpVariantData[rId].VANEX_NOTES = tmpVariantData[rId].VANEX_NOTES['CURRENT_USER_NOTE']});
        
        // * add a sheet for each tab!
        xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData, {header:['VANEX_TAGS', 'VANEX_NOTES', 'CHROMOSOME' ]})]);
        // xlsxSheetData.push([tabs[tabId].label , XLSX.utils.json_to_sheet(tmpVariantData, {header:['CHROMOSOME' POSITION	STOP_POSITION	REF	ALT	SAMPLES	INHERIT_MODEL	QUAL	FILTER	GENE	ENTREZ_ID	LOC_IN_GENE	DIST_TO_SPLICE_SITE	DIST_TO_CODING_REGION	EFFECT	IMPACT	MANE_HGVS	HGVS	HGVS_ALL_PRIORITIZED	INFORMATION, 	ROW_INDEX	VANEX_TAGS	VANEX_NOTES']})]);
      }
      updateExportProgress(10+((index+1)*(60/totalTabs)));
    });

    updateExportProgress(70);

    //! add this? xlsxSheetData.push(['Filtered (raw)' , XLSX.utils.json_to_sheet(props.caseData)]);
    //! add this? xlsxSheetData.push(['Filtered (Global)' , XLSX.utils.json_to_sheet(globalFilteredVariantData)]);
    //! add this? xlsxSheetData.push(['Selected' , XLSX.utils.json_to_sheet(variantData)]);

		const wb = XLSX.utils.book_new();
    xlsxSheetData.forEach( element => {
      // console.log('appending: ', element);
      XLSX.utils.book_append_sheet(wb, element[1], element[0]);  
    });

    updateExportProgress(80);

    if(!!props.currentCaseInformation){
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([props.currentCaseInformation]), "Case Info");
      if(!!props.currentCaseInformation.CASE_ID){
        outputFileName = props.currentCaseInformation.CASE_ID;
      }
    }
    if(!!props.currentAnalysisInfo){
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet([props.currentAnalysisInfo]), "Analysis Info");
    }

    updateExportProgress(90);
    /* generate XLSX file and send to client */
		XLSX.writeFile(wb, outputFileName + ".xlsx");
    toast.success('Export Completed!');
    setIsExporting(false); // updateExportProgress(99, true);
  }

  //? --------------------------------------------- process values ----------------------------------

  const showWithPrecision = (inGnomadFreq, decNum=2) => {
    let gnomadFreq = inGnomadFreq;
    if (inGnomadFreq !== undefined && inGnomadFreq !== null){
      gnomadFreq = Number(gnomadFreq);
      return(gnomadFreq.toPrecision(decNum));
      // if(gnomadFreq!==0) return gnomadFreq.toFixed(decNum);
    }
    return gnomadFreq
  }

  const processHGVS = (hgvsTermsString) => {
    if(hgvsTermsString===null || hgvsTermsString===undefined) return('-'); //! use this? if(hgvsTermsString===null) return('-'); //  
    if(hgvsTermsString.trim()==='') return('-');
    return(hgvsTermsString.split(';').map((hgvsTerm, hgvsIndex) => {
      return(
        <div className="rounded-pill border-light-blue m-0 mb-2 px-5" key={hgvsIndex}>
          {hgvsTerm.split(':').map((subTerm, inerIndex) => {  //.split(/(?=:)/g);
            return(<div key={inerIndex} className="">{subTerm}{(inerIndex!==hgvsTerm.split(':').length-1) ? ':' : ''}</div>);
          })}
      </div>
      );
    }));
  }

  const processOMIMTerms = (omimTermsString) => {
    let omimTerms = omimTermsString.split(';');
    omimTerms = omimTerms.map((term, index) => {
      return(
        <div  key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue d-flex flex-row flex-nowrap align-items-center justify-content-center" 
          dangerouslySetInnerHTML={{ __html: term.replaceAll(/,? ?autosomal dominant/ig, '<span class="ms-1 badge bg-orange">AD</span>')
            .replaceAll(/,? ?autosomal recessive/ig, '<span class="ms-1 badge bg-green">AR</span>')
            .replaceAll(/,? ?X-linked dominant/ig, '<span class="ms-1 badge bg-orange">XLD</span>')
            .replaceAll(/,? ?X-linked recessive/ig, '<span class="ms-1 badge bg-green">XLR</span>') }} />
      );
    });
    return omimTerms;
  }

  const GetGnomADCounts = (currentVariant) => {
    let gnomADCounts='not in gnomAD';
    // if(!! currentVariant.GNOMAD_WG_AC) ntFreq=`${currentVariant.GNOMAD_WG_AC} in gnomAD`;
  
    let GNOMAD_WG_AC = (!!currentVariant.GNOMAD_WG_AC) ? Number(currentVariant.GNOMAD_WG_AC) : 0;
    let GNOMAD_EX_AC = (!!currentVariant.GNOMAD_EX_AC) ? Number(currentVariant.GNOMAD_EX_AC) : 0;
    let maxCount = Math.max(GNOMAD_WG_AC,GNOMAD_EX_AC);
    if(!! maxCount) gnomADCounts=`${maxCount} in gnomAD`;
    
    return([gnomADCounts, (maxCount>0) ? maxCount : '-']);
  }

  const GetGnomADv4Counts = (currentVariant) => {
    let gnomADCounts='not in gnomAD';
    // if(!! currentVariant.GNOMAD_WG_AC) ntFreq=`${currentVariant.GNOMAD_WG_AC} in gnomAD`;
  
    let GNOMAD_WG_AC = (!!currentVariant.GNOMAD_WG_AC_V4) ? Number(currentVariant.GNOMAD_WG_AC_V4) : 0;
    let GNOMAD_EX_AC = (!!currentVariant.GNOMAD_EX_AC_V4) ? Number(currentVariant.GNOMAD_EX_AC_V4) : 0;
    let maxCount = Math.max(GNOMAD_WG_AC,GNOMAD_EX_AC);
    if(!! maxCount) gnomADCounts=`${maxCount} in gnomAD`;
    
    return([gnomADCounts, (maxCount>0) ? maxCount : '-']);
  }

  const getIGMAlleleCountsSummary = (currentVariant) => {
    let CLINICAL_EXOME = (!!currentVariant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT) : 0;
    let CANCER_SOMATIC_FFPE_WES = (!!currentVariant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_COUNT) : 0;
    let CANCER_SOMATIC_FROZEN_WES = (!!currentVariant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_COUNT) : 0;
    let CANCER_SOMATIC_FROZEN_WGS = (!!currentVariant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_COUNT) : 0;
    let CANCER_GERMLINE_WES_ALT = (!!currentVariant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_COUNT) : 0;
    let CANCER_GERMLINE_WES_BLOOD_ALT = (!!currentVariant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_COUNT) : 0;
    let CANCER_GERMLINE_WES_BUCCAL_ALT = (!!currentVariant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_COUNT) : 0;
    let CANCER_GERMLINE_WGS_ALT = (!!currentVariant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_COUNT) ? Number(currentVariant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_COUNT) : 0;

    let maxCount = Math.max(CLINICAL_EXOME, CANCER_SOMATIC_FFPE_WES, CANCER_SOMATIC_FROZEN_WES, CANCER_SOMATIC_FROZEN_WGS,
      CANCER_GERMLINE_WES_ALT, CANCER_GERMLINE_WES_BLOOD_ALT, CANCER_GERMLINE_WES_BUCCAL_ALT, CANCER_GERMLINE_WGS_ALT);
    // if(!! maxCount) gnomADCounts=`${maxCount} in gnomAD`;
    
    return( (maxCount>0) ? maxCount : '-');
  }

  const processMrPheneBoxTitle = (mrPheneTerms) => {
    let outputStr = '(-)';
    let number = mrPheneTerms.match(/^\[(\d*)\]/);
    if(!!number && !!number[1]) outputStr=`(${number[1]})`;

    // outputStr += `: ${mrPheneTerms}`; 

    return <span>{outputStr}</span>;
  }

  const processHpoTerms = (hpoTermsString) => {
    let hpoTerms = hpoTermsString.toString().split(',');
    hpoTerms = hpoTerms.map( (term, index) => {
      return(
        <div key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue">{term}</div>
      );
    });
    return hpoTerms;
  }
  const processPhenoTerms = (phenoTermsString) => {
    let phenoTerms = phenoTermsString.toString().split(';');
    phenoTerms = phenoTerms.map( (term, index) => {
      return(
        <div key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue">{term}</div>
      );
    });
    return phenoTerms;
  }

  const processOrphanetTerms = (orphanetTermsString) => {
    let orphanetTerms = orphanetTermsString.split(';');
    orphanetTerms = orphanetTerms.map( (term, index) => {
      return(
        <div key={index} className="rounded-pill px-2 mx-2 my-1 border-light-blue">{term}</div>
      );
    });
    return orphanetTerms;
  }

  const [selectedPathwayInVariantDesc, setSelectedPathwayInVariantDesc] = useState('');

  const allPathwayTermsForTheCurrentVariant = React.useMemo(() => {
    let pathwaysTerms = [];
    if (!!currentVariantId && props.caseData[currentVariantId] && props.caseData[currentVariantId].ENTREZ_ID!==undefined && props.caseData[currentVariantId].ENTREZ_ID!==null){
      // console.log(props.caseData[currentVariantId])
      if(pathways!==null && Object.keys(pathways).length >0){
        pathwaysTerms = Object.keys(pathways).filter(t => pathways[t].includes(props.caseData[currentVariantId].ENTREZ_ID));
      }
    }
    if (pathwaysTerms.length > 0)
      setSelectedPathwayInVariantDesc(pathwaysTerms[0]);
    else
      setSelectedPathwayInVariantDesc('');

    return pathwaysTerms;
  }, [currentVariantId]); //! deconstruct props and use individual ones?? 

  const processedPathwayTerms = React.useMemo(() => {
    let pathwaysTerms = allPathwayTermsForTheCurrentVariant.map( (term, index) => {
      let numberOfCallsInThisPathway = Object.values(props.caseData).filter(t => pathways[term].includes(t.ENTREZ_ID)).length;
      return(
        // <div key={index} className={`rounded-pill px-2 mx-2 my-1 cursor-pointer ${ (term===selectedPathwayInVariantDesc ) ? 'border-red ' :'border-light-blue ' }`}
        <div key={index} className={`rounded-pill px-2 mx-2 my-1 cursor-pointer ${ (numberOfCallsInThisPathway > 1) ? 'bg-light-pink' :'' } ${ (term===selectedPathwayInVariantDesc ) ? 'border-red ' :'border-light-blue ' }`}
          onClick={() => setSelectedPathwayInVariantDesc(term)}
        >{term} ({numberOfCallsInThisPathway})</div>
      );
    });
    return pathwaysTerms;
  }, [currentVariantId, selectedPathwayInVariantDesc]); //! deconstruct props and use individual ones?? 


  const otherVariationsInThisPathway = React.useMemo(() => {
    if(selectedPathwayInVariantDesc!==undefined && selectedPathwayInVariantDesc!==null && Object.keys(pathways).length > 0 && !!pathways[selectedPathwayInVariantDesc] ){
      return(
        Object.values(props.caseData).filter(t => pathways[selectedPathwayInVariantDesc].includes(t.ENTREZ_ID))
      );
    }
    return([]);
  }, [selectedPathwayInVariantDesc]);

  const processFunctionalElements = (fnctionalElementsString) => {
    try{
      if(fnctionalElementsString.length < 5) return("-");
      let funcElements = JSON.parse(fnctionalElementsString);
      let fullAnnotation = Object.keys(funcElements).map( (element, i) => 
        <div className="mx-2 my-1" key={i}> 
          {Object.keys(funcElements[element]).map( (innerElement, innerI) => <span className="no-white-space-wrap" key={innerI}><b>{element}</b>: {String(funcElements[element][innerElement]) } <br/></span>) }
        </div>)

      let wholeBlock = 
        <>
          {Object.keys(funcElements.CHROM).map( (innerElementId, innerInd) => 
            <div className="variant-item text-center m-1 d-flex flex-row flex-wrap" key={innerInd}>
              <span className="functional-element-annotation-text"><b>ID</b>: {innerElementId}</span>
              <span className="functional-element-annotation-text"><b>CHROM</b>: {funcElements.CHROM[innerElementId]}</span>
              <span className="functional-element-annotation-text"><b>Start</b>: {funcElements.Start[innerElementId]}</span>
              <span className="functional-element-annotation-text"><b>End</b>: {funcElements.End[innerElementId]}</span>
              <span className="functional-element-annotation-text"><b>db_xref</b>: {funcElements.db_xref[innerElementId]}</span>
              <span className="functional-element-annotation-text"><b>regulatory_class</b>: {funcElements.regulatory_class[innerElementId]}</span>
              <span className="functional-element-annotation-text"><b>function</b>: {funcElements.function[innerElementId]}</span>
              <span className="functional-element-annotation-text"><b>note</b>: {funcElements.note[innerElementId]}</span>
              <span className="functional-element-annotation-text"><b>experiment</b>: {funcElements.experiment[innerElementId]}</span>
              {/* <span className="functional-element-annotation-text"><b>experiment</b>: {funcElements.experiment[innerElementId].replaceAll(/\[PMID:([\d])*]/g,'xxx')}</span> */}
              {/* <span className="functional-element-annotation-text"><b>experiment</b>: {funcElements.experiment[innerElementId].replaceAll(/\[PMID:/g,'[PMID:<a>https://pubmed.ncbi.nlm.nih.gov/30612741/')}</span> */}
              <span className="functional-element-annotation-text"><b>genes</b>: {funcElements.genes[innerElementId].replaceAll(';',', ')}</span>
            </div>)}
          {(props.devOptions ?
          <><hr/>
          <div className="variant-item text-center m-1 d-flex flex-row flex-wrap">{fullAnnotation}</div>
          </> : "")}
        </>
      return(wholeBlock)
    }
    catch (e) {
      return "error in parsing functional elements info"
    }

    // Object.keys(funcElements).map( (element, i) =>   String(funcElements[element]) )
    // funcElements.map(s -> )
    // debugger;
    // return fnctionalElementsString
    // if(fnctionalElementsString===null || fnctionalElementsString===undefined) return('-'); //! use this? if(fnctionalElementsString===null) return('-'); //  
    // if(fnctionalElementsString.trim()==='') return('-');
    // return(fnctionalElementsString.split(';').map((hgvsTerm, hgvsIndex) => {
    //   return(
    //     <div className="rounded-pill border-light-blue m-0 mb-2 px-5" key={hgvsIndex}>
    //       {hgvsTerm.split(':').map((subTerm, inerIndex) => {  //.split(/(?=:)/g);
    //         return(<div key={inerIndex} className="">{subTerm}{(inerIndex!==hgvsTerm.split(':').length-1) ? ':' : ''}</div>);
    //       })}
    //   </div>
    //   );
    // }));
  }

  const getPrtPos = (hgvs) => {
    // console.log('hgvs', hgvs);
    if (!hgvs) return 0;
    let prtPos = 0;
    let prtPosSearch = hgvs.match(/p\.[A-Za-z]+(\d+)/);
    if(!!prtPosSearch && prtPosSearch.length > 0 && parseInt(prtPosSearch[1]) > 0)
      prtPos = parseInt(prtPosSearch[1]);
    // if(prtPos == 0 ){
    //   let prtPosSearch = hgvs.match(/c\.[A-Za-z]+(\d+)/);
    // }
    // console.log('prtPos',prtPos);
    return(prtPos);
  }

  const processPanelMembership = (panelMembershipInfo) => {
    if(panelMembershipInfo===null || panelMembershipInfo===undefined) return('-'); //! use this? if(hgvsTermsString===null) return('-'); //  
    if(panelMembershipInfo.trim()==='') return('-');
    return(panelMembershipInfo.split(';').map((term, index) => {
      return( <div className="rounded-pill border-light-blue m-1 px-3" key={index}>{term}</div> );
    }));
  }

  // //? --------------------------------------------- IGV ----------------------------------
  // !! IGV stuff, took out 

  //? --------------------------------------------- renders ----------------------------------

  const renderVariantInfo = () => {
    if(currentVariantId===null || !props.caseData[currentVariantId]){
      return(
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
            <div className="card m-2 shadow-sm flex-grow-1">
              <h5 className="card-header bg-light-blue">Variant</h5>
              <div className="card-body d-flex flex-row flex-nowrap align-items-center justify-content-center">
                <h3>No variant selected</h3>
              </div>
            </div>
          </div>
        </div>
      );
    }
    else{
      const variant = props.caseData[currentVariantId];
      // console.log('vr',variant,variant.ENTREZ_ID,variant.GENE)
      const entrez_id_split = variant.ENTREZ_ID?.split(";")[0]
      const gene_split = variant.GENE?.split(";")[0]

      // console.log({name: variant.GENE, entrez_id:variant.ENTREZ_ID});
      // console.log([{POSITION:parseInt(variant.POSITION), PRT_POS:getPrtPos(variant.MANE_HGVS)}]);
      //console.log('variant',variant)
      return(
        <div className="bottom-var-info-panel d-flex flex-column">
          <div className="d-flex overflow-auto flex-column flex-nowrap">
          
            {/* //!!!!! {( selectedVariantTab === 'TAB_PARADIGM' && !!props.paradigmData && props.paradigmData.length>0 ?  */}
            {( selectedVariantTab === 'TAB_PARADIGM' && !!variant.PARADIGM && !!variant.PARADIGM['FULL_RECORD'] ? 
            <div className="card m-1 shadow-sm flex-grow-1">
              <h5 className="variant-card-header bg-light-blue d-flex flex-row">
                PARADIGM Data
                {/* <div className="mx-2 user-select-all variant-full-name-display">{variant.CHROMOSOME}-{variant.POSITION}-{variant.REF}-{variant.ALT}</div>  */}
                {/* <span className="ms-5">Gene: <span className="mx-1 user-select-all">{variant.GENE}</span> </span> */}
                {/* <span className="ms-5">Position: <span className="mx-1 user-select-all">{variant.CHROMOSOME}:{variant.POSITION}</span> </span> */}
              </h5>
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
              {/* //!!!!! {props.paradigmData.map( (vr, ind) =>
                <div key={ind} className='mx-3'>
                  {Object.keys(vr).map( (k,innerInd) => <div key={innerInd} ><b>{k}:</b> {vr[k]}</div>)}
                </div>
              )} */}
                {Object.keys(variant.PARADIGM['FULL_RECORD']).map( (k,innerInd) => <div key={innerInd} className="mx-4"><b>{k}:</b> {(typeof(variant.PARADIGM['FULL_RECORD'][k] )==='number' ? Math.round(variant.PARADIGM['FULL_RECORD'][k]*100)/100 : variant.PARADIGM['FULL_RECORD'][k] )  }</div>)}
              </div>
            </div>
            : null)}

            {(!!variant.DENOVO_REANALYSIS ? 
            <div className="card m-1 shadow-sm flex-grow-1">
              <h5 className="variant-card-header bg-light-blue d-flex ">
                De Novo Data: {variant.DENOVO_REANALYSIS.CASE_ID}
                {/* <div className="mx-2 user-select-all variant-full-name-display">{variant.CHROMOSOME}-{variant.POSITION}-{variant.REF}-{variant.ALT}</div>  */}
                {/* <span className="ms-5">Gene: <span className="mx-1 user-select-all">{variant.GENE}</span> </span> */}
                {/* <span className="ms-5">Position: <span className="mx-1 user-select-all">{variant.CHROMOSOME}:{variant.POSITION}</span> </span> */}
              </h5>
              <div className="variant-card-body d-flex flex-row align-items-center justify-content-center">
    
                <div className="card-group flex-grow-1 d-flex flex-column">
                  <div className="mx-2 mb-1 d-flex flex-row">
                    <h5 className="card-title">Phenotype</h5>
                    <div className="limited-text-height d-flex flex-row flex-wrap ">
                      {(variant.DENOVO_REANALYSIS.PHENOTYPE===undefined) ? "Error!" : ( (variant.DENOVO_REANALYSIS.PHENOTYPE===null || variant.DENOVO_REANALYSIS.PHENOTYPE==="") ? <span className="mx-4">-</span> : processPhenoTerms(variant.DENOVO_REANALYSIS.PHENOTYPE) )}
                    </div>
                  </div>
                  <div className="mx-2 d-flex flex-row">
                    <h5 className="card-title">Case Notes</h5>
                    <div className="mx-2 my-1">{variant.DENOVO_REANALYSIS.CASE_NOTE}</div>
                  </div>
                </div>
              </div>
            </div>
            : null)}

            <div className="card m-1 shadow-sm flex-grow-1">
              <h5 className="variant-card-header bg-light-blue d-flex flex-row">
                Variant: <div className="mx-2 user-select-all variant-full-name-display">{variant.CHROMOSOME}-{variant.POSITION}-{variant.REF}-{variant.ALT}</div> 
                <span className="ms-5">Gene: <span className="mx-1 user-select-all">{variant.GENE}</span> </span>
                <span className="ms-5">Position: <span className="mx-1 user-select-all">{variant.CHROMOSOME}:{variant.POSITION}</span> </span>
                {Object.keys(otherVariationsNearby).length>0 ? <span className="ms-5">{Object.keys(otherVariationsNearby).length} Variant(s) nearby</span>:null}
              </h5>
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-0" style={{minWidth:'45rem'}}>
                  <div className="variant-item text-start">
                    <a href={'https://varsome.com/variant/hg38/'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT+'?annotation-mode=germline'}  target="_blank" rel="noreferrer"><i className="fas fa-check"></i> Varsome</a>
                    <div className="mt-2">
                      {(variant.DBSNP_ID===null || variant.DBSNP_ID===undefined) ?
                        <span><i className="fas fa-external-link-alt"></i> dbSNP</span> :
                        <a href={'https://www.ncbi.nlm.nih.gov/snp/'+variant.DBSNP_ID} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> dbSNP</a>
                      }
                    </div>
                  </div>
                  <div className="variant-item text-start">
                    {/* <a href={'http://localhost:60151/goto?locus=chr'+variant.CHROMOSOME+':'+variant.POSITION+'-'+variant.POSITION} target="_blank" rel="noreferrer"><i className="fas fa-align-center"></i> IGV</a> */}
                    <a href={'https://www.genecards.org/cgi-bin/carddisp.pl?gene='+gene_split} target="_blank" rel="noreferrer"><i className="fas fa-dna"></i> GeneCards</a>
                    <div className="mt-2"><a href={'http://denovo-db.gs.washington.edu/denovo-db/QueryVariantServlet?searchBy=Gene&target='+gene_split} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> denovo-db</a></div>
                  </div>
                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                    <a href={'https://gene.sfari.org/database/human-gene/'+gene_split} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> SFARI</a>                    
                    <div className="mt-2"><a href={'http://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&position=chr'+variant.CHROMOSOME+':'+variant.POSITION+'-'+variant.STOP_POSITION} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> UCSC</a></div>
                  </div>

                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                    <a href={'https://stuart.radboudumc.nl/metadome/dashboard'} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> MetaDom</a>                    
                    <div className="mt-2">
                      <a href={'https://spliceailookup.broadinstitute.org/#variant=chr'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT+'&hg=38&distance=50&mask=1&ra=0'} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> SpliceAI</a>  
                      {/* <a href={'http://genome.ucsc.edu/cgi-bin/hgTracks?db=hg38&position=chr'+variant.CHROMOSOME+':'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> newTool</a> */}
                    </div>
                  </div>

                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                    {/* <a href={'https://spliceailookup.broadinstitute.org/#variant=chr'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT+'&hg=38&distance=50&mask=1&ra=0'} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> SpliceAI</a>                     */}
                    <a href={'https://rgc-research.regeneron.com/me/variant/'+variant.CHROMOSOME+':'+variant.POSITION+':'+variant.REF+':'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> RGC</a>
                    <div className="mt-2"><a href={'https://www.informatics.jax.org/marker/MGI:'+variant.MGI_ID} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> MGI</a></div>
                  </div>

                  <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                    {/* <a href={'https://spliceailookup.broadinstitute.org/#variant=chr'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT+'&hg=38&distance=50&mask=1&ra=0'} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> SpliceAI</a>                     */}
                    <a href={'https://databrowser.researchallofus.org/snvsindels/'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> AllOfUs</a>
                    <div className="mt-2"><a href={'http://gnomad.broadinstitute.org/variant/'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT+'?dataset=gnomad_r4'} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> gnomAD</a></div>
                  </div>

                  {/* <div className="variant-item text-start" style={{minWidth:'fit-content'}}>
                    <a href={'https://mutscore-wgt7hvakhq-ew.a.run.app/'} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> MutScore</a>                    
                    <div className="mt-2"><a href={'https://alphamissense.hegelab.org/search'} target="_blank" rel="noreferrer"><i className="fas fa-external-link-alt"></i> AlphaMissense</a></div>
                  </div> */}
                </div>
                {/* <div className="variant-item text-center"><span className="text-muted">Gene</span><br/><a href={'https://www.genecards.org/cgi-bin/carddisp.pl?gene='+variant.GENE} target="_blank" rel="noreferrer"><i className="fas fa-dna"></i> {variant.GENE}</a></div> */}
                {/* <div className="variant-item text-center flex-grow-1 show-overflow">{variant.CHROMOSOME}-{variant.POSITION}-{variant.REF}-{variant.ALT}</div> */}
                {/* <div className="variant-item text-center flex-grow-0"><span className="text-muted">Quality</span><div className="m-1"><span className="px-2 py-1 rounded-pill fw-bold" style={{backgroundColor: GetHeatedQualityScore(variant.QUAL)}}>{variant.QUAL}</span></div></div> */}
                <div className="variant-item text-center variant-item-HGVS flex-grow-1" style={{minWidth:'min-content'}}>
                  {/* //! fix this: do we need trim? <span className="text-muted">{(variant.MANE_HGVS.trim()!=="") ? 'MANE HGVS' : 'HGVS'}</span> */}
                  <span className="text-muted">{(variant.MANE_HGVS) ? 'MANE HGVS' : 'HGVS'}</span>
                  <div className="mt-1 limited-text-height d-flex flex-column align-items-center"  style={{width:'100%',height:'4.4rem'}}>
                    {(variant.MANE_HGVS) ? processHGVS(variant.MANE_HGVS) : ( (variant.HGVS) ? processHGVS(variant.HGVS) : '-')}
                    {/* //! here as well {(variant.MANE_HGVS.trim()!=="") ? processHGVS(variant.MANE_HGVS) : ( (variant.HGVS) ? processHGVS(variant.HGVS) : '-')} */}
                  </div>
                </div>
                {/* <div className="variant-item text-center flex-grow-0 1flex-shrink-1" style={{minWidth:'15rem'}}><span className="text-muted">Loc In Gene</span><div className="">{variant.LOC_IN_GENE}</div></div> */}
                <div className="variant-item text-center flex-grow-0"><span className="text-muted">Splice AI</span><div className="">{variant?.SPLICEAI_DS_MAX_V4 > 0 ? variant.SPLICEAI_DS_MAX_V4.toFixed(2) : variant?.SPLICEAI_DS_MAX_V4 }</div></div>
                <div className="variant-item text-center flex-grow-0"><span className="text-muted">Splice Dist</span><div className="">{variant.DIST_TO_SPLICE_SITE}</div></div>
              </div>
              {/* <footer className="card-footer text-muted">2 days ago <cite title="Source Title">Source Title</cite></footer> */}
            </div>

            {/* {renderOMIM()} */}
            <VariantInfoBox title="OMIM" min_width="10rem">
            {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">OMIM</h5> */}
              {/* <div className="variant-card-body d-flex flex-column"> */}
                <div className="d-flex flex-row flex-nowrap ">
                  <div className="variant-item text-center m-1 d-flex flex-row">
                    {(variant.MIM_NUMBER===null) ? "" :
                      <a href={'https://www.omim.org/entry/'+variant.MIM_NUMBER} target="_blank" rel="noreferrer" className="me-2"><i className="fas fa-external-link-alt"></i> OMIM<br/> Phenotypes</a>
                    }
                    <div className="flex-grow-1 d-flex flex-row flex-wrap">
                      {(variant.OMIM_PHENOTYPES===undefined) ? "Error!" : ( (variant.OMIM_PHENOTYPES===null) ? "-" : processOMIMTerms(variant.OMIM_PHENOTYPES) )}
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row w-100"><OmimInfo omimPhenotypes={variant.OMIM_PHENOTYPES} omimNumber={variant.MIM_NUMBER} /></div>
              {/* </div> */}
            </VariantInfoBox>{/* </div> */}

            {( (variant.MR_PHENE_PHENOTYPE_TERMS!==undefined && variant.MR_PHENE_PHENOTYPE_TERMS!==null)
            ?
              <VariantInfoBox title={<span>Mr. Phene <span className="fs-6 ms-2">{processMrPheneBoxTitle(variant.MR_PHENE_PHENOTYPE_TERMS)}</span></span>}
                min_width="10em" collapsed>
                {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">Orphanet/HPO</h5> */}
                <div className="variant-card-body d-flex flex-row align-items-center justify-content-center">
                  <div className="card-group flex-grow-1 d-flex flex-column">
                      <div className="limited-text-height d-flex flex-row flex-wrap ">
                        {processHpoTerms(variant.MR_PHENE_PHENOTYPE_TERMS.replace(/^\[\d*\]/g, ''))}
                      </div>
                  </div>
                </div>
              </VariantInfoBox>
            : <span/> )}

            {/* {renderClinVar()} */}
            <VariantInfoBox title={<span>ClinVar <span className="fs-6 ms-3">{(variant.CLINVAR_CLNSIG===undefined) ? "Error!" : ( (variant.CLINVAR_CLNSIG===null) ? "-" : <span className={getClinvarSignificanceStyle(variant.CLINVAR_CLNSIG)} style={{fontSize:'.8em'}}>{String(variant.CLINVAR_CLNSIG ).replaceAll(/_/g," ")  }</span>  ) } </span></span>}
              min_width="10rem" >
            {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">ClinVar</h5> */}
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-1">
                  <div className="variant-item text-center m-0 p-0 px-1 flex-grow-0" style={{minWidth:'15rem'}}>
                    <div className="mb-1">
                    {(variant.CLINVAR_ID===null) ?
                      // <span className="text-muted ">ClinVar Significance</span>
                      <a href={`https://www.ncbi.nlm.nih.gov/clinvar/?term=${gene_split}%5Bgene%5D`} target="_blank" rel="noreferrer" className=""><i className="fas fa-external-link-alt"></i> Related Variants in ClinVar</a>
                      :
                      <a href={`https://www.ncbi.nlm.nih.gov/clinvar/variation/${variant.CLINVAR_ID}/`} target="_blank" rel="noreferrer" className=""><i className="fas fa-external-link-alt"></i> ClinVar Significance</a>
                    }
                    </div>
                    {/* <div>
                      {(variant.CLINVAR_CLNSIG===undefined) ? "Error!" : ( (variant.CLINVAR_CLNSIG===null) ? "-" : 
                      <div className={getClinvarSignificanceStyle(variant.CLINVAR_CLNSIG)}>{String(variant.CLINVAR_CLNSIG ).replaceAll(/_/g," ")  }</div>)}
                    </div> */}
                  </div>
                  <div className="variant-item text-center m-0 p-0 px-1 flex-grow-1 flex-wrap"><span className="text-muted">ClinVar Disease</span><br/>{(variant.CLINVAR_CLNDN===undefined) ? "Error!" : ( (variant.CLINVAR_CLNDN===null) ? "-" : variant.CLINVAR_CLNDN )}</div>
                </div>
                {/* <div className="text-center m-1 d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-1 ">
                  <div className="variant-item text-center m-1 flex-grow-1"><span className="text-muted">CADD Phred</span><br/>{(variant.DBNSFP_CADD_PHRED===undefined) ? "Error!" : ( (variant.DBNSFP_CADD_PHRED===null) ? "-" : showWithPrecision(variant.DBNSFP_CADD_PHRED, 3) )}</div>
                  <div className="variant-item text-center m-1 flex-grow-1"><span className="text-muted">CADD Raw</span><br/>{(variant.DBNSFP_CADD_RAW===undefined) ? "Error!" : ( (variant.DBNSFP_CADD_RAW===null) ? "-" : showWithPrecision(variant.DBNSFP_CADD_RAW, 3) )}</div>
                </div> */}
              </div>
              <div  className="variant-card-body d-flex flex-column align-items-center justify-content-center">
                {(autoLoadFirefly)
                  ?
                  <>
                    <div className="d-flex flex-row flex-nowrap cursor-pointer" onClick={ () => setAutoLoadFirefly(false)}><i className="far fa-times-circle px-1 fa-lg red-mid"></i>Stop Autoloading Dynamic Plots</div>
                    <Firefly 
                      className="mb-3"
                      // geneNameEntrezId={{name: variant.GENE, entrez_id:variant.ENTREZ_ID}}
                      entrezId={entrez_id_split}
                      highlight={ [{POSITION:parseInt(variant.POSITION), PRT_POS:getPrtPos(variant.MANE_HGVS)}] }
                      useStagingEnv = {process.env.REACT_APP_ENV!=='prod'}
                      // highlight={ {PRT_POS:getPrtPos(variant.MANE_HGVS)} }
                      // highlight={variant}
                      // highlight={{POSITION:2, REF:'A', ALT:'C', HGVSc:'HGVSc', HGVSp:'HGVSp'}}
                    />
                  </>
                  :
                    <h5 className="d-flex flex-row flex-nowrap align-items-center justify-content-center cursor-pointer" onClick={ () => setAutoLoadFirefly(true)}>Autoload dynamic plots<i className="far fa-image px-3 fa-2x"></i></h5>
                  }
              </div>
              {/* {renderClinVarPlots()} */}
              {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">ClinVar Plot</h5> */}
                {/* <div className="variant-card-body d-flex flex-row flex-nowrap align-items-center justify-content-center"> */}
                  <div  className="variant-card-body text-center m-0 p-0 d-flex flex-column align-items-center justify-content-center">
                    {/* <br/> */}
                    {/* {(variant.ClinVarPlot) ? */}
                    {(!!clinVarPlotSrc)
                    ?
                      <div id="img-holder">
                        {/* <span id="clinvarMask" tabIndex="1"></span> */}
                        {/* <img id="clinvarPic" tabIndex="2" className="clinvarplot" src={variant.ClinVarPlot} alt="ClinVar Plot"/> */}
                        {/* <img tabIndex="2" className="clinvarplot" src={variant.ClinVarPlot} alt="ClinVar Plot"/> */}
                        {/* <img className="clinvarplot" src={clinVarPlotSrc} alt="ClinVar Plot"/> */}
                        <a href={clinVarPlotSrc} target="_blank" rel="noreferrer"> <img className="clinvarplot mb-2" src={clinVarPlotSrc} alt="ClinVar Plot"/></a>
                      </div>
                    :
                      (loadingClinVarPlot)
                      ?
                      <span><span className="mx-2 spinner-border spinner-border" role="status" aria-hidden="true"> </span><i className="far fa-image px-3 fs-1"></i></span>
                      :
                        (errorMessage.length > 0)
                        ?
                        <h5>{errorMessage}</h5>
                        :
                        <h5 className="d-flex flex-row flex-nowrap align-items-center justify-content-center cursor-pointer" onClick={ () => loadClinVarPlot(variant)}>Load static plot</h5>
                    }
                    </div>
                {/* </div> */}
              {/* </div> */}
            </VariantInfoBox>{/* </div> */}

            {(props.currentAnalysisInfo.ANALYSIS_TYPE==='GenomeWideDeNovo') ?
              <VariantInfoBox title="Functional Elements" min_width="10rem">
              {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">OMIM</h5> */}
                {/* <div className="variant-card-body d-flex flex-column"> */}
                  <div className="d-flex flex-row flex-nowrap ">
                    <div className="variant-item m-1 d-flex flex-column">
                      {(variant.FUNC_ELEMENT===null || variant.FUNC_ELEMENT===undefined || variant.FUNC_ELEMENT===".") ? "-" : processFunctionalElements(variant.FUNC_ELEMENT)}
                    </div>
                    {/* <div className="flex-grow-1 d-flex flex-row flex-wrap">
                      {(variant.OMIM_PHENOTYPES===undefined) ? "Error!" : ( (variant.OMIM_PHENOTYPES===null) ? "-" : processOMIMTerms(variant.OMIM_PHENOTYPES) )}
                    </div> */}
                  </div>
                {/* </div> */}
              </VariantInfoBox>
            : null }

            {/* {renderdOtherVriationInthisGene()} */}
            <VariantInfoBox title={<span>All SNVs in {variant.GENE}<span className="fs-6 ms-3">({Object.keys(otherVariationsInThisGene).length})</span></span>}
              min_width="10em" collapsed>
              {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">All SNVs in {variant.GENE} {' ('}{Object.keys(otherVariationsInThisGene).length}{')'}</h5> */}
              <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                <VariantTable
                  initialSortBy={props.initialSortBy}
//! fix this part 
                  updateVariantTableData={(variantIndex, columnId, value) => { 
                    if(!!props.updateVariantTableData){ 
                      setShouldBlockNavigation(true);
                      props.updateVariantTableData(variantIndex, columnId, value);
                    } else {toast.error('Cannot update this value!')}
                    if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                      setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                    }
                  }}
                  // displayVariantInfo={(variantId) => setCurrentVariantId(variantId)}
                  getTabName={() => selectedVariantTab}
                  hiddenColumnsIds={hiddenColumnsIds}
                  // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                  PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                  columns={columnData} data={otherVariationsInThisGene}
                />
              </div>
            </VariantInfoBox>{/* </div> */}


            {/* {renderdOtherVriationInthisGene()} */}
            {Object.keys(otherVariationsNearby).length>0 ?<VariantInfoBox title={<span>Variants Near This Position (+-10bp)<span className="fs-6 ms-3">({Object.keys(otherVariationsNearby).length})</span></span>}
                min_width="10em" collapsed>
                <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white" style={{ minHeight:'12rem', maxHeight:'20rem'}}>
                  <VariantTable
                    initialSortBy={props.initialSortBy}
                    updateVariantTableData={(variantIndex, columnId, value) => { 
                      if(!!props.updateVariantTableData){ 
                        setShouldBlockNavigation(true);
                        props.updateVariantTableData(variantIndex, columnId, value);
                      } else {toast.error('Cannot update this value!')}
                      if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                        setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                      }
                    }}
                    // displayVariantInfo={(variantId) => setCurrentVariantId(variantId)}
                    getTabName={() => selectedVariantTab}
                    hiddenColumnsIds={hiddenColumnsIds}
                    // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                    PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                    columns={columnData} data={otherVariationsNearby}
                  />
                </div>
              </VariantInfoBox> : null}


            {(props.project==="cardiac" ? 
            <VariantInfoBox title="COURAGE" min_width="10em" >
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                <div className="variant-item text-center flex-grow-0 1flex-shrink-1" style={{minWidth:'15rem'}}>
                  <span className="text-muted">Heart Enhancer Avg. Score</span>
                  <div className="">{(variant.HEART_ENHANCER_AVERAGE_SCORE_STRING!==null && variant.HEART_ENHANCER_AVERAGE_SCORE_STRING!==undefined ? variant.HEART_ENHANCER_AVERAGE_SCORE_STRING : '-')}</div>
                </div>
                
                <div className="variant-item text-center flex-grow-0" style={{minWidth:'15rem'}}>
                  <span className="text-muted">Heart Enhancer Score Category</span>
                  <div className="">{(!!variant.HEART_ENHANCER_SCORE_CATEGORY ? variant.HEART_ENHANCER_SCORE_CATEGORY : '-')}</div>
                </div>

                <div className="ps-4 variant-item text-start flex-grow-1" >
                  <span className="text-muted">IGM CFK Panel Membership</span>
                  <div className="mt-1 limited-text-height d-flex flex-row  align-items-center">
                  {processPanelMembership(variant.IGM_CFK_PANEL_MEMBERSHIP)}
                  </div>
                </div>
              </div>
            </VariantInfoBox>
            : null )}

            {/* {renderPathogenicity()} */}
            {/* <VariantInfoBox title={<span>Pathogenicity <span className="fs-6 ms-3">{`(${VaNexAutoNotes[1].replace(' damaging','')} | ${Number(calCumulativeDamagingScore(variant)).toFixed(2)})`}</span></span>}  */}
            <VariantInfoBox title={
              <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
                Pathogenicity 
                <span className="fs-6 ms-3">{`(${VaNexAutoNotes[1].replace(' damaging','')} | ${Number(calCumulativeDamagingScore(variant)).toFixed(2)})`}</span>
                {/* {(variant.DBNSFP_PROVEAN_PRED!==undefined) ? <> */}
                  <div className="d-flex flex-row flex-grow-1 flex-wrap align-items-center justify-content-center">
                    <div className="mx-2 d-flex flex-row flex-nowrap align-items-center justify-content-center my-1 fs-6" >
                      <div className="me-2 text-end fw-bold fs-6" style={{minWidth:'3em'}}>Meta:</div>
                      {getMetaSummary()}
                    </div>
                    <div className="mx-1 ms-5 d-flex align-items-center justify-content-center my-1 fs-6">
                      <div className="me-2 text-end fw-bold fs-6" style={{minWidth:'3em'}}>Individual:</div>
                      {getIndividualSummary()}
                    </div>
                  </div>
                  {/* </>: null } */}
              </div>} 
              min_width="10rem" collapsed>
              {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">Pathogenicity{` (${Number(calCumulativeDamagingScore(variant)).toFixed(2)})`}</h5> */}
              {(damagingScores[3]===0 && damagingScores[7]===0) ? 
                <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                  No data available for this variant.
                  {/* No data available for this variant in DANN, CADD, dbNSFP and dbscSNV. */}
                </div>
              : 
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                {/* {(variant.DBNSFP_PROVEAN_PRED!==undefined) ? <>
                  <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                    <div className="mx-5 d-flex flex-row flex-nowrap align-items-center justify-content-center mb-2" >
                      <div className="me-2 text-end fw-bold fs-5" style={{minWidth:'6em'}}>Meta:</div>
                      {getMetaSummary()}
                    </div>
                    <div className="mx-5 d-flex align-items-center justify-content-center mb-2">
                      <div className="me-2 text-end fw-bold fs-5" style={{minWidth:'6em'}}>Individual:</div>
                      {getIndividualSummary()}
                    </div>
                  </div>
                <div className="w-100 d-flex justify-content-center"><div style={{width:'80%'}}><hr /></div></div>
                </>: null } */}

                <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                  {(variant.DBNSFP_METALR_SCORE!==undefined && variant.DBNSFP_METALR_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1 " style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MetaLR</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_METALR_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METALR_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{(variant.DBNSFP_MetaLR_rankscore===undefined) ? "No data" : ( (variant.DBNSFP_MetaLR_rankscore===null) ? "-" : showWithPrecision(variant.DBNSFP_MetaLR_rankscore, 2) )}<br/><span className="text-muted">Fraction</span></div> */}
                    </div>
                  : '' }

                  {(variant.DBNSFP_METASVM_SCORE!==undefined && variant.DBNSFP_METASVM_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MetaSVM</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_METASVM_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }
                  
                  {(variant.DBNSFP_METARNN_SCORE!==undefined && variant.DBNSFP_METARNN_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MetaRNN</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( variant.DBNSFP_METARNN_PRED )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METARNN_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {(variant.DBNSFP_METASVM_SCORE!==undefined && variant.DBNSFP_METASVM_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">REVEL</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( (variant.DBNSFP_REVEL_SCORE > 0.5 ? 'd' : 't') )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_REVEL_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {/* {(variant.DBNSFP_METARNN_SCORE!==undefined && variant.DBNSFP_METARNN_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MetaRNN</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( (variant.DBNSFP_METARNN_SCORE > 0.5 ? 'd' : 't') )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METARNN_SCORE, 3)}</div>
                    </div>
                  : '' } */}

                  {/* </div> */}
                  
                  {/* <hr /> */}
                  {/* <div className="w-100 d-flex justify-content-center"><div style={{width:'80%'}}><hr /></div></div> */}

{/* ALoFT
dbNSFP version 4.2	

prediction
, Tolerant

confidence
, High

Fraction transcripts affected
, 2/2

probability tolerant
0.5709, 0.5736

probability recessive
0.3679, 0.3723

probability dominant
0.05685, 0.05855 */}
                  {/* //! need more modifications {(variant.DBNSFP_ALOFT_PROB_TOLERANT!==undefined && variant.DBNSFP_ALOFT_PROB_TOLERANT!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">Aloft</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_ALOFT_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_ALOFT_PROB_TOLERANT, 3)}</div>
                    </div>
                  : '' } */}

                  {(variant.DBNSFP_BAYESDEL_ADDAF_SCORE!==undefined && variant.DBNSFP_BAYESDEL_ADDAF_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">BayesDel addAF</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_BAYESDEL_ADDAF_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_BAYESDEL_ADDAF_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {(variant.DBNSFP_BAYESDEL_NOAF_SCORE!==undefined && variant.DBNSFP_BAYESDEL_NOAF_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">BayesDel noAF</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_BAYESDEL_NOAF_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_BAYESDEL_NOAF_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {/* <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center"> */}
                  {/* <div className="text-center m-1 d-flex flex-row flex-nowrap align-items-center justify-content-center flex-grow-1 "> */}
                  {(variant.DBNSFP_CADD_PHRED!==undefined && variant.DBNSFP_CADD_PHRED!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">CADD</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( (variant.DBNSFP_CADD_PHRED > 19 ? 'd' : 't') )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_CADD_PHRED, 3)}</div>
                      {/* <div className="variant-item text-center m-1 flex-grow-1"><span className="text-muted">Phred: </span>{(variant.DBNSFP_CADD_PHRED===undefined) ? "Error!" : ( (variant.DBNSFP_CADD_PHRED===null) ? "-" : showWithPrecision(variant.DBNSFP_CADD_PHRED, 3) )}</div> */}
                      {/* <div className="variant-item text-center m-1 flex-grow-1"><span className="text-muted">Raw: </span>{(variant.DBNSFP_CADD_RAW===undefined) ? "Error!" : ( (variant.DBNSFP_CADD_RAW===null) ? "-" : showWithPrecision(variant.DBNSFP_CADD_RAW, 3) )}</div> */}
                    </div>
                  : '' }

                  {(variant.DBNSFP_CLINPRED_SCORE!==undefined && variant.DBNSFP_CLINPRED_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">ClinPred</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_CLINPRED_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_CLINPRED_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {(variant.DBNSFP_DANN_SCORE!==undefined && variant.DBNSFP_DANN_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">DANN</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( (variant.DBNSFP_DANN_SCORE > 0.5 ? 'd' : 't') )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_DANN_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {(variant.DBNSFP_DEOGEN2_SCORE!==undefined && variant.DBNSFP_DEOGEN2_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">DEOGEN2</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_DEOGEN2_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_DEOGEN2_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {/* DBNSFP_EIGEN-PHRED_CODING */}
                  {/* {(variant['DBNSFP_EIGEN-RAW_CODING']!==undefined && variant['DBNSFP_EIGEN-RAW_CODING']!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">EIGEN PC</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_EIGEN_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant['DBNSFP_EIGEN-RAW_CODING'], 3)}</div>
                    </div>
                  : '' } */}

                  {/* DBNSFP_EIGEN-PC-PHRED_CODING */}
                  {/* {(variant['DBNSFP_EIGEN-PC-RAW_CODING']!==undefined && variant['DBNSFP_EIGEN-PC-RAW_CODING']!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">EIGEN PC</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_EIGENPC_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant['DBNSFP_EIGEN-PC-RAW_CODING'], 3)}</div>
                    </div>
                  : '' } */}

                  {(variant.DBNSFP_FATHMM_SCORE!==undefined && variant.DBNSFP_FATHMM_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">FATHMM</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_FATHMM_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_FATHMM_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {(variant['DBNSFP_FATHMM-MKL_CODING_SCORE']!==undefined && variant['DBNSFP_FATHMM-MKL_CODING_SCORE']!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">Fathmm MKL</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant['DBNSFP_FATHMM-MKL_CODING_PRED'])}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant['DBNSFP_FATHMM-MKL_CODING_SCORE'], 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {(variant['DBNSFP_FATHMM-XF_CODING_SCORE']!==undefined && variant['DBNSFP_FATHMM-XF_CODING_SCORE']!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">Fathmm XF</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant['DBNSFP_FATHMM-XF_CODING_PRED'])}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant['DBNSFP_FATHMM-XF_CODING_SCORE'], 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  
                  {(variant['DBNSFP_LIST-S2_SCORE']!==undefined && variant['DBNSFP_LIST-S2_SCORE']!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">LIST S2</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant['DBNSFP_LIST-S2_PRED'])}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant['DBNSFP_LIST-S2_SCORE'], 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {(variant.DBNSFP_LRT_SCORE!==undefined && variant.DBNSFP_LRT_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">LRT</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_LRT_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_LRT_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {(variant['DBNSFP_M-CAP_SCORE']!==undefined && variant['DBNSFP_M-CAP_SCORE']!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">M CAP</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant['DBNSFP_M-CAP_PRED'])}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant['DBNSFP_M-CAP_SCORE'], 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {/* {(variant.DBNSFP_MVP_SCORE!==undefined && variant.DBNSFP_MVP_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MVP</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( (variant.DBNSFP_MVP_SCORE > 0.5 ? 'd' : 't') )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_MVP_SCORE, 3)}</div>
                    </div>
                  : ''  }

                  {(variant.DBNSFP_MPC_SCORE!==undefined && variant.DBNSFP_MPC_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MPC</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( (variant.DBNSFP_MPC_SCORE > 0.5 ? 'd' : 't') )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_MPC_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {(variant.DBNSFP_MUTPRED_SCORE!==undefined && variant.DBNSFP_MUTPRED_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MutPred</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_MUTPRED_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_MUTPRED_SCORE, 3)}</div>
                    </div>
                  : '' } */}

                  {/* //! DBNSFP_MAX_MUTATIONASSESSOR_PRED # N, L, | M, H  =  Neutral, Low, | Medium, High */}
                  {(variant.DBNSFP_MAX_MUTATIONASSESSOR_SCORE!==undefined && variant.DBNSFP_MAX_MUTATIONASSESSOR_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">Mutation Assessor</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_MAX_MUTATIONASSESSOR_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_MAX_MUTATIONASSESSOR_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {/* //! DBNSFP_MAX_MUTATIONTASTER_PRED # N, P, D, A  ==  Polymorphism automatic, Polymorphism, Disease causing, Disease causing automatic */} 
                  {(variant.DBNSFP_MAX_MUTATIONTASTER_SCORE!==undefined && variant.DBNSFP_MAX_MUTATIONTASTER_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">MutationTaster</span></div>
                      <div className="text-center flex-fill">{getPredictionStyle(variant.DBNSFP_MAX_MUTATIONTASTER_PRED, true)}</div>
                      <div className="px-2 text-center">{showWithPrecision(variant.DBNSFP_MAX_MUTATIONTASTER_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {(variant.DBNSFP_PROVEAN_SCORE!==undefined && variant.DBNSFP_PROVEAN_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">PROVEAN</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_PROVEAN_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_PROVEAN_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {/* {(variant.DBNSFP_POLYPHEN2_HDIV_SCORE!==undefined && variant.DBNSFP_POLYPHEN2_HDIV_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'30rem'}}>
                      <div className="variant-item text-center"><span className="fw-bold">Polyphen2 HDIV</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_POLYPHEN2_HDIV_PRED)}</div>
                    </div>
                  : '' } */}

                  {(variant.DBNSFP_MAX_POLYPHEN2_HDIV_PRED!==undefined && variant.DBNSFP_MAX_POLYPHEN2_HDIV_PRED!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">Polyphen2 HDIV</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_MAX_POLYPHEN2_HDIV_PRED)}</div>
                      <div className="variant-item text-center">{(variant.DBNSFP_MAX_POLYPHEN2_HDIV_SCORE===null) ? '-' : showWithPrecision(variant.DBNSFP_MAX_POLYPHEN2_HDIV_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {(variant.DBNSFP_MAX_POLYPHEN2_HVAR_PRED!==undefined && variant.DBNSFP_MAX_POLYPHEN2_HVAR_PRED!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">Polyphen2 HVAR</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_MAX_POLYPHEN2_HVAR_PRED)}</div>
                      <div className="variant-item text-center">{(variant.DBNSFP_MAX_POLYPHEN2_HVAR_SCORE===null) ? '-' :showWithPrecision(variant.DBNSFP_MAX_POLYPHEN2_HVAR_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {(variant.DBNSFP_PRIMATEAI_SCORE!==undefined && variant.DBNSFP_PRIMATEAI_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">PrimateAI</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_PRIMATEAI_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_PRIMATEAI_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {(variant.DBNSFP_SIFT_SCORE!==undefined && variant.DBNSFP_SIFT_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">SIFT</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_SIFT_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_SIFT_SCORE, 3)}</div>
                    </div>
                  : '' }

                  {(variant.DBNSFP_SIFT4G_SCORE!==undefined && variant.DBNSFP_SIFT4G_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">SIFT4G</span></div>
                      <div className="variant-item text-center">{getPredictionStyle(variant.DBNSFP_SIFT4G_PRED)}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_SIFT4G_SCORE, 3)}</div>
                      {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                    </div>
                  : '' }

                  {/* {(variant.DBNSFP_MAX_VEST4_SCORE!==undefined && variant.DBNSFP_MAX_VEST4_SCORE!==null) ?
                    <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                      <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">VEST4</span></div>
                      <div className="variant-item text-center">{getPredictionStyle( (variant.DBNSFP_MAX_VEST4_SCORE > 0.5 ? 'd' : 't') )}</div>
                      <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_MAX_VEST4_SCORE, 3)}</div>
                    </div>
                  : '' } */}

                </div>
              </div>}
            </VariantInfoBox>{/* </div> */}

            <div className="card-group flex-grow-1">
              {/* {renderPopulationCounts()} */}
              <VariantInfoBox title={<span>gnomAD-v4 Counts <span className="fs-6 ms-3">({GetGnomADv4Counts(variant)[1]})</span></span>} 
                min_width="25em" collapsed>
                {/* <div className="card m-1 shadow-sm flex-fill" style={{minWidth: '40em'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">gnomAD Counts</h5> */}
                <div className="variant-card-body d-flex flex-column flex-nowrap align-items-center justify-content-center mx-2">
                  {/* <div className="text-center">
                    <a href={'https://rgc-research.regeneron.com/me/variant/'+variant.CHROMOSOME+':'+variant.POSITION+':'+variant.REF+':'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> <strong>RGC</strong> (MILLION EXOME VARIANT)</a>
                  </div> */}
                  <div className="d-flex flex-row flex-nowrap align-items-center text-muted">
                    <div className="variant-item text-center">
                      {/* <a href={'https://rgc-research.regeneron.com/me/variant/'+variant.CHROMOSOME+':'+variant.POSITION+':'+variant.REF+':'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> <strong>RGC</strong></a>
                      <a href={'https://rgc-research.regeneron.com/me/variant/'+variant.CHROMOSOME+':'+variant.POSITION+':'+variant.REF+':'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> <strong>AoU</strong></a> */}
                    </div>
                    <div className="variant-item text-center">Alt</div>
                    <div className="variant-item text-center">Fraction</div>
                    <div className="variant-item text-center">Homozygous</div>
                  </div>
                  <div className="d-flex flex-row flex-nowrap align-items-center my-2">
                    <div className="text-center">
                      <a href={'http://gnomad.broadinstitute.org/variant/'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT+'?dataset=gnomad_r4'} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> gnomAD</a>
                    </div>
                    {/* <div className="variant-item text-end"><span className="fw-bold">WG</span></div> */}
                    <div className="variant-item text-center">{(variant.GNOMAD_WG_AC_V4===undefined) ? "Error!" : ( (variant.GNOMAD_WG_AC_V4===null) ? "-" : variant.GNOMAD_WG_AC_V4 )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_WG_FAF95_POPMAX_V4===undefined) ? "Error!" : ( (variant.GNOMAD_WG_FAF95_POPMAX_V4===null) ? "-" : showWithPrecision(variant.GNOMAD_WG_FAF95_POPMAX_V4, 2) )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_WG_NHOMALT_V4===undefined) ? "Error!" : ( (variant.GNOMAD_WG_NHOMALT_V4===null) ? "-" : variant.GNOMAD_WG_NHOMALT_V4 )}</div>
                  </div>
                  <div className="d-flex flex-row flex-nowrap align-items-center my-2">
                    <div className="text-center">
                      {/* <a href={'http://gnomad.broadinstitute.org/variant/'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> gnomAD <strong>Ex</strong></a> */}
                      <i className="ps-4"> gnomAD <strong>Ex</strong></i>
                    </div>
                    {/* <div className="variant-item text-end"><span className="fw-bold">Ex</span></div> */}
                    <div className="variant-item text-center">{(variant.GNOMAD_EX_AC_V4===undefined) ? "Error!" : ( (variant.GNOMAD_EX_AC_V4===null) ? "-" : variant.GNOMAD_EX_AC_V4 )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_EX_FAF95_POPMAX_V4===undefined) ? "Error!" : ( (variant.GNOMAD_EX_FAF95_POPMAX_V4===null) ? "-" : showWithPrecision(variant.GNOMAD_EX_FAF95_POPMAX_V4, 2) )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_EX_NHOMALT_V4===undefined) ? "Error!" : ( (variant.GNOMAD_EX_NHOMALT_V4===null) ? "-" : variant.GNOMAD_EX_NHOMALT_V4 )}</div>
                  </div>
                </div>
              </VariantInfoBox>{/* </div> */}
              
              {/* {renderPopulationCounts()} */}
              <VariantInfoBox title={<span>gnomAD-v3 Counts <span className="fs-6 ms-3">({GetGnomADCounts(variant)[1]})</span></span>} 
                min_width="25em" collapsed>
                {/* <div className="card m-1 shadow-sm flex-fill" style={{minWidth: '40em'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">gnomAD Counts</h5> */}
                <div className="variant-card-body d-flex flex-column flex-nowrap align-items-center justify-content-center mx-2">
                  {/* <div className="text-center">
                    <a href={'https://rgc-research.regeneron.com/me/variant/'+variant.CHROMOSOME+':'+variant.POSITION+':'+variant.REF+':'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> <strong>RGC</strong> (MILLION EXOME VARIANT)</a>
                  </div> */}
                  <div className="d-flex flex-row flex-nowrap align-items-center text-muted">
                    <div className="variant-item text-center">
                      {/* <a href={'https://rgc-research.regeneron.com/me/variant/'+variant.CHROMOSOME+':'+variant.POSITION+':'+variant.REF+':'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> <strong>RGC</strong></a>
                      <a href={'https://rgc-research.regeneron.com/me/variant/'+variant.CHROMOSOME+':'+variant.POSITION+':'+variant.REF+':'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> <strong>AoU</strong></a> */}
                    </div>
                    <div className="variant-item text-center">Alt</div>
                    <div className="variant-item text-center">Fraction</div>
                    <div className="variant-item text-center">Homozygous</div>
                  </div>
                  <div className="d-flex flex-row flex-nowrap align-items-center my-2">
                    <div className="text-center">
                      <a href={'http://gnomad.broadinstitute.org/variant/'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT+'?dataset=gnomad_r3'} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> gnomAD</a>
                    </div>
                    {/* <div className="variant-item text-end"><span className="fw-bold">WG</span></div> */}
                    <div className="variant-item text-center">{(variant.GNOMAD_WG_AC===undefined) ? "Error!" : ( (variant.GNOMAD_WG_AC===null) ? "-" : variant.GNOMAD_WG_AC )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_WG_FAF95_POPMAX===undefined) ? "Error!" : ( (variant.GNOMAD_WG_FAF95_POPMAX===null) ? "-" : showWithPrecision(variant.GNOMAD_WG_FAF95_POPMAX, 2) )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_WG_NHOMALT===undefined) ? "Error!" : ( (variant.GNOMAD_WG_NHOMALT===null) ? "-" : variant.GNOMAD_WG_NHOMALT )}</div>
                  </div>
                  <div className="d-flex flex-row flex-nowrap align-items-center my-2">
                    <div className="text-center">
                      {/* <a href={'http://gnomad.broadinstitute.org/variant/'+variant.CHROMOSOME+'-'+variant.POSITION+'-'+variant.REF+'-'+variant.ALT} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> gnomAD <strong>Ex</strong></a> */}
                      <i className="ps-4"> gnomAD <strong>Ex</strong></i>
                    </div>
                    {/* <div className="variant-item text-end"><span className="fw-bold">Ex</span></div> */}
                    <div className="variant-item text-center">{(variant.GNOMAD_EX_AC===undefined) ? "Error!" : ( (variant.GNOMAD_EX_AC===null) ? "-" : variant.GNOMAD_EX_AC )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_EX_FAF95_POPMAX===undefined) ? "Error!" : ( (variant.GNOMAD_EX_FAF95_POPMAX===null) ? "-" : showWithPrecision(variant.GNOMAD_EX_FAF95_POPMAX, 2) )}</div>
                    <div className="variant-item text-center">{(variant.GNOMAD_EX_NHOMALT===undefined) ? "Error!" : ( (variant.GNOMAD_EX_NHOMALT===null) ? "-" : variant.GNOMAD_EX_NHOMALT )}</div>
                  </div>
                </div>
              </VariantInfoBox>{/* </div> */}

              {/* {renderConstraint()} */}
              <VariantInfoBox title={<span>Constraint-v3
                  <span className="fs-6 ms-3">( pLI={(variant.GNOMAD_LOF_PLI===undefined) ? " -" : ( (variant.GNOMAD_LOF_PLI===null) ? "-" : getBgSensitiveNumber(variant.GNOMAD_LOF_PLI) )}</span>
                  <span className="fs-6 ms-3">Z={(variant.GNOMAD_LOF_MIS_Z===undefined) ? " -" : ( (variant.GNOMAD_LOF_MIS_Z===null) ? "-" : getBgSensitiveNumber(variant.GNOMAD_LOF_MIS_Z, 1))})</span>
                </span>}
                min_width="25em" collapsed>
                {/* <div className="card m-1 shadow-sm flex-fill" style={{minWidth: '40em'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">Constraint</h5> */}
                {(variant.GNOMAD_LOF_CONSTRAINT_FLAG===undefined)
                ?
                  <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                    No data
                  </div>
                :
                  <div className="variant-card-body d-flex flex-column flex-nowrap align-items-center justify-content-center">
                    {/* //! maybe add more info about the flags https://gnomad.broadinstitute.org/help/why-are-constraint-metrics-missing-for-this-gene-or-annotated-with-a-note
                    <div className="w-100 text-center">
                      {(variant.GNOMAD_LOF_CONSTRAINT_FLAG!==null) ? variant.GNOMAD_LOF_CONSTRAINT_FLAG : ''}
                    </div> */}
                    <div className="d-flex flex-row flex-nowrap align-items-center w-100 text-muted">
                      <div className="variant-item text-center">Category</div>
                      <div className="variant-item text-center">Observed SNVs</div>
                      <div className="variant-item text-center">Expected SNVs</div>
                      <div className="variant-item text-center">Obs./Exp.</div>
                      <div className="variant-item text-center">Constraint Metrics</div>
                    </div>
                    {/* <div className="d-flex flex-row flex-nowrap align-items-center">
                      <div className="variant-item text-center"><span className="text-muted">Individuals</span><br/>{(variant.HKIDS_N_SAMPLES===undefined) ? "No data" : ( (variant.HKIDS_N_SAMPLES===null) ? "-" : variant.HKIDS_N_SAMPLES )}</div>
                      <div className="variant-item text-center"><span className="text-muted">Fraction</span><br/>{(variant.HKIDS_N_SAMPLES===undefined) ? "No data" : ( (variant.HKIDS_N_SAMPLES===null) ? "-" : showWithPrecision(variant.HKIDS_N_SAMPLES/8, 2) )}</div>
                      <div className="variant-item text-center"><span className="text-muted">Homozygous</span><br/>{(variant.HKIDS_HOM===undefined) ? "No data" : ( (variant.HKIDS_HOM===null) ? "-" : variant.HKIDS_HOM )}</div>
                      <div className="variant-item text-center"><span className="text-muted">Male</span><br/>{(variant.HKIDS_MALE===undefined) ? "No data" : ( (variant.HKIDS_MALE===null) ? "-" : variant.HKIDS_MALE )}</div>
                      <div className="variant-item text-center"><span className="text-muted">Female</span><br/>{(variant.HKIDS_FEMALE===undefined) ? "No data" : ( (variant.HKIDS_FEMALE===null) ? "-" : variant.HKIDS_FEMALE )}</div>
                    </div> */}
                    <div className="d-flex flex-row flex-nowrap align-items-center w-100">
                      <div className="variant-item text-center">Synonymous</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_OBS_SYN===undefined) ? "No data" : ( (variant.GNOMAD_LOF_OBS_SYN===null) ? "-" : variant.GNOMAD_LOF_OBS_SYN )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_EXP_SYN===undefined) ? "No data" : ( (variant.GNOMAD_LOF_EXP_SYN===null) ? "-" : Number(variant.GNOMAD_LOF_EXP_SYN).toFixed(1) )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_OE_SYN===undefined) ? "No data" : ( (variant.GNOMAD_LOF_OE_SYN===null) ? "-" : showWithPrecision(variant.GNOMAD_LOF_OE_SYN,3) )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_SYN_Z===undefined) ? "No data" : ( (variant.GNOMAD_LOF_SYN_Z===null) ? "-" : <>Z = {getBgSensitiveNumber(variant.GNOMAD_LOF_SYN_Z, 1)}</> )}</div>
                      {/* <div className="variant-item text-center"><span className="">{(variant.GNOMAD_LOF_SYN_Z===undefined) ? "No data" : ( (variant.GNOMAD_LOF_SYN_Z===null) ? "-" : `Z = ${showWithPrecision(variant.GNOMAD_LOF_SYN_Z,3)}` )}</span>
                        <br/>{(variant.GNOMAD_LOF_OE_SYN===undefined) ? "No data" : ( (variant.GNOMAD_LOF_OE_SYN===null) ? "-" : `o/e = ${showWithPrecision(variant.GNOMAD_LOF_OE_SYN,3)}` )}</div> */}
                    </div>
                    <div className="d-flex flex-row flex-nowrap align-items-center w-100">
                      <div className="variant-item text-center">Missense</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_OBS_MIS===undefined) ? "No data" : ( (variant.GNOMAD_LOF_OBS_MIS===null) ? "-" : variant.GNOMAD_LOF_OBS_MIS )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_EXP_MIS===undefined) ? "No data" : ( (variant.GNOMAD_LOF_EXP_MIS===null) ? "-" : Number(variant.GNOMAD_LOF_EXP_MIS).toFixed(1) )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_OE_MIS===undefined) ? "No data" : ( (variant.GNOMAD_LOF_OE_MIS===null) ? "-" : showWithPrecision(variant.GNOMAD_LOF_OE_MIS,3) )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_MIS_Z===undefined) ? "No data" : ( (variant.GNOMAD_LOF_MIS_Z===null) ? "-" : <>Z = {getBgSensitiveNumber(variant.GNOMAD_LOF_MIS_Z, 1)}</> )}</div>
                      {/* <div className="variant-item text-center"><span className="">Z =0.0</span><br/>o/e = {(variant.HKIDS_MALE===undefined) ? "No data" : ( (variant.HKIDS_MALE===null) ? "-" : variant.HKIDS_MALE )}</div> */}
                    </div>
                    <div className="d-flex flex-row flex-nowrap align-items-center w-100">
                      {/* {console.log(typeof(variant.GNOMAD_LOF_PLI))} */}
                      <div className="variant-item text-center">pLoF</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_OBS_LOF===undefined) ? "No data" : ( (variant.GNOMAD_LOF_OBS_LOF===null) ? "-" : variant.GNOMAD_LOF_OBS_LOF )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_EXP_LOF===undefined) ? "No data" : ( (variant.GNOMAD_LOF_EXP_LOF===null) ? "-" : Number(variant.GNOMAD_LOF_EXP_LOF).toFixed(1) ) }</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_OE_LOF===undefined) ? "No data" : ( (variant.GNOMAD_LOF_OE_LOF===null) ? "-" : showWithPrecision(variant.GNOMAD_LOF_OE_LOF,3) )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_PLI===undefined) ? "No data" : ( (variant.GNOMAD_LOF_PLI===null) ? "-" : <>pLI = {getBgSensitiveNumber(variant.GNOMAD_LOF_PLI)}</> )}</div>
                      {/* <div className="variant-item text-center">{(variant.GNOMAD_LOF_PLI===undefined) ? "No data" : ( (variant.GNOMAD_LOF_PLI===null) ? "-" : <span className="bg-pink">{`pLI = ${Number(variant.GNOMAD_LOF_PLI).toFixed(2)}`}</span> )}</div> */}
                      {/* <div className="variant-item text-center">{(variant.GNOMAD_LOF_PLI===undefined) ? "No data" : ( (variant.GNOMAD_LOF_PLI===null) ? "-" : `pLI = ${Number(variant.GNOMAD_LOF_PLI).toFixed(3)}` )}</div> */}
                      {/* <div className="variant-item text-center"><span className="">pLI = 0.0</span><br/>o/e = {(variant.HKIDS_MALE===undefined) ? "No data" : ( (variant.HKIDS_MALE===null) ? "-" : variant.HKIDS_MALE )}</div> */}
                    </div>
                    {/* <div className="d-flex flex-row flex-nowrap align-items-center w-100">
                      <div className="variant-item text-center">EXAC pLI</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_EXAC_OBS_LOF===undefined) ? "No data" : ( (variant.GNOMAD_LOF_EXAC_OBS_LOF===null) ? "-" : variant.GNOMAD_LOF_EXAC_OBS_LOF )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_EXAC_EXP_LOF===undefined) ? "No data" : ( (variant.GNOMAD_LOF_EXAC_EXP_LOF===null) ? "-" : variant.GNOMAD_LOF_EXAC_EXP_LOF )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_EXAC_OE_LOF===undefined) ? "No data" : ( (variant.GNOMAD_LOF_EXAC_OE_LOF===null) ? "-" : showWithPrecision(variant.GNOMAD_LOF_EXAC_OE_LOF,3) )}</div>
                      <div className="variant-item text-center">{(variant.GNOMAD_LOF_EXAC_PLI===undefined) ? "No data" : ( (variant.GNOMAD_LOF_EXAC_PLI===null) ? "-" : `pLI = ${showWithPrecision(variant.GNOMAD_LOF_EXAC_PLI,3)}` )}</div>
                    </div> */}
                  </div>
                }
              </VariantInfoBox>{/* </div> */}


              {/* {renderHealthyKidsCounts()} */}
              <VariantInfoBox title={<span>Unaffected Sibs Counts <span className="fs-6 ms-3">({(variant.HKIDS_N_SAMPLES===undefined) ? "-" : ( (variant.HKIDS_N_SAMPLES===null) ? "-" : variant.HKIDS_N_SAMPLES )})</span></span>}
                min_width="25em" collapsed>
                {/* <div className="card m-1 shadow-sm flex-fill" style={{minWidth: '40em'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">Unaffected Sibs Counts</h5> */}
                <div className="variant-card-body d-flex flex-column flex-nowrap align-items-center justify-content-center">
                  <div className="d-flex flex-row flex-nowrap align-items-center text-muted">
                    <div className="variant-item text-center">Individuals</div>
                    <div className="variant-item text-center">Fraction</div>
                    <div className="variant-item text-center">Homozygous</div>
                    <div className="variant-item text-center">Male</div>
                    <div className="variant-item text-center">Female</div>
                  </div>
                  <div className="d-flex flex-row flex-nowrap align-items-center">
                    <div className="variant-item text-center">{(variant.HKIDS_N_SAMPLES===undefined) ? "No data" : ( (variant.HKIDS_N_SAMPLES===null) ? "-" : variant.HKIDS_N_SAMPLES )}</div>
                    <div className="variant-item text-center">{(variant.HKIDS_N_SAMPLES===undefined) ? "No data" : ( (variant.HKIDS_N_SAMPLES===null) ? "-" : showWithPrecision(variant.HKIDS_N_SAMPLES/8, 2) )}</div>
                    <div className="variant-item text-center">{(variant.HKIDS_HOM===undefined) ? "No data" : ( (variant.HKIDS_HOM===null) ? "-" : variant.HKIDS_HOM )}</div>
                    <div className="variant-item text-center">{(variant.HKIDS_MALE===undefined) ? "No data" : ( (variant.HKIDS_MALE===null) ? "-" : variant.HKIDS_MALE )}</div>
                    <div className="variant-item text-center">{(variant.HKIDS_FEMALE===undefined) ? "No data" : ( (variant.HKIDS_FEMALE===null) ? "-" : variant.HKIDS_FEMALE )}</div>
                  </div>
                </div>
              </VariantInfoBox>{/* </div> */}


              {/* {renderIGMAlleleCounts()} */}
              <VariantInfoBox title={<span>IGM Allele Counts <span className="fs-6 ms-3">({getIGMAlleleCountsSummary(variant)})</span></span>}
                min_width="25em" collapsed>
                {/* <div className="card m-1 shadow-sm flex-fill" style={{minWidth: '40em'}}> */}
                {/* <h5 className="variant-card-header bg-light-blue">IGM Allele Counts</h5> */}
                {/* <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">
                  <div className="variant-item text-center">{(variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT )}<br/><span className="text-muted">Alt</span></div>
                  <div className="variant-item text-center">{(variant.IGM_CLINICAL_EXOME_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CLINICAL_EXOME_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CLINICAL_EXOME_TOTAL_SAMPLE_COUNT )}<br/><span className="text-muted">Total</span></div>
                  <div className="variant-item text-center">{(variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION, 2) )}<br/><span className="text-muted">Fraction</span></div>
                </div> */}
                <div className="variant-card-body d-flex flex-column">

                  <div className="d-flex flex-row flex-nowrap align-items-center text-muted">
                  {/* <div className="d-flex flex-row flex-wrap align-items-center justify-content-center"> */}
                    <div className="variant-item text-center">{' '}</div>
                    <div className="variant-item text-center">Alt</div>
                    <div className="variant-item text-center">Total</div>
                    <div className="variant-item text-center">Fraction</div>
                    <div className="variant-item text-center">Multi Allelic</div>
                  </div>
                  {(variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CLINICAL EXOME</div>
                      <div className="variant-item text-center">{(variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CLINICAL_EXOME_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CLINICAL_EXOME_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CLINICAL_EXOME_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CLINICAL_EXOME_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{ '' }</div>
                    </div>
                  :null}
                  {(variant.IGM_CANCER_SOMATIC_FFPE_WES_MULTIALLELIC_SAMPLE_COUNT!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CANCER SOMATIC (FFPE, WES)</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FFPE_WES_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FFPE_WES_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_SOMATIC_FFPE_WES_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CANCER_SOMATIC_FFPE_WES_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FFPE_WES_MULTIALLELIC_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FFPE_WES_MULTIALLELIC_SAMPLE_COUNT===null) ? "-" : showWithPrecision(variant.IGM_CANCER_SOMATIC_FFPE_WES_MULTIALLELIC_SAMPLE_COUNT, 2) )}</div>
                    </div>
                  :null}
                  {(variant.IGM_CANCER_SOMATIC_FROZEN_WES_MULTIALLELIC_SAMPLE_COUNT!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CANCER SOMATIC (FROZEN, WES)</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WES_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WES_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_SOMATIC_FROZEN_WES_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CANCER_SOMATIC_FROZEN_WES_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WES_MULTIALLELIC_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WES_MULTIALLELIC_SAMPLE_COUNT===null) ? "-" : showWithPrecision(variant.IGM_CANCER_SOMATIC_FROZEN_WES_MULTIALLELIC_SAMPLE_COUNT, 2) )}</div>
                    </div>
                  :null}
                  
                  {(variant.IGM_CANCER_SOMATIC_FROZEN_WGS_MULTIALLELIC_SAMPLE_COUNT!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CANCER_SOMATIC_FROZEN_WGS</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WGS_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WGS_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_SOMATIC_FROZEN_WGS_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CANCER_SOMATIC_FROZEN_WGS_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_SOMATIC_FROZEN_WGS_MULTIALLELIC_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_SOMATIC_FROZEN_WGS_MULTIALLELIC_SAMPLE_COUNT===null) ? "-" : showWithPrecision(variant.IGM_CANCER_SOMATIC_FROZEN_WGS_MULTIALLELIC_SAMPLE_COUNT, 2) )}</div>
                    </div>
                  :null}
                  {(variant.IGM_CANCER_GERMLINE_WES_MULTIALLELIC_SAMPLE_COUNT!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CANCER GERMLINE (WES)</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WES_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WES_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_MULTIALLELIC_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_MULTIALLELIC_SAMPLE_COUNT===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WES_MULTIALLELIC_SAMPLE_COUNT, 2) )}</div>
                    </div>
                  :null}
                  
                  {(variant.IGM_CANCER_GERMLINE_WES_BLOOD_MULTIALLELIC_SAMPLE_COUNT!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CANCER_GERMLINE_WES_BLOOD</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BLOOD_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BLOOD_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WES_BLOOD_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WES_BLOOD_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BLOOD_MULTIALLELIC_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BLOOD_MULTIALLELIC_SAMPLE_COUNT===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WES_BLOOD_MULTIALLELIC_SAMPLE_COUNT, 2) )}</div>
                    </div>
                  :null}
                  {(variant.IGM_CANCER_GERMLINE_WES_BUCCAL_MULTIALLELIC_SAMPLE_COUNT!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CANCER_GERMLINE_WES_BUCCAL</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BUCCAL_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BUCCAL_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WES_BUCCAL_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WES_BUCCAL_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WES_BUCCAL_MULTIALLELIC_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WES_BUCCAL_MULTIALLELIC_SAMPLE_COUNT===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WES_BUCCAL_MULTIALLELIC_SAMPLE_COUNT, 2) )}</div>
                    </div>
                  :null}
                  {(variant.IGM_CANCER_GERMLINE_WGS_MULTIALLELIC_SAMPLE_COUNT!==undefined)? 
                    <div className="d-flex flex-row flex-wrap align-items-center justify-content-center">
                      <div className="variant-item text-center">CANCER_GERMLINE_WGS</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WGS_TOTAL_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WGS_TOTAL_SAMPLE_COUNT===null) ? "-" : variant.IGM_CANCER_GERMLINE_WGS_TOTAL_SAMPLE_COUNT )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_FRACTION===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_FRACTION===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WGS_ALT_SAMPLE_FRACTION, 2) )}</div>
                      <div className="variant-item text-center">{(variant.IGM_CANCER_GERMLINE_WGS_MULTIALLELIC_SAMPLE_COUNT===undefined) ? "Error!" : ( (variant.IGM_CANCER_GERMLINE_WGS_MULTIALLELIC_SAMPLE_COUNT===null) ? "-" : showWithPrecision(variant.IGM_CANCER_GERMLINE_WGS_MULTIALLELIC_SAMPLE_COUNT, 2) )}</div>
                    </div>
                  :null}
                  
                </div>
              </VariantInfoBox>{/* </div> */}
            </div>
    
            {/* {renderdbSNPFrame()} */}
            {/* <VariantInfoBox title="dbSNP" > */}
            {/* <VariantInfoBox title={
              <span>
                {(variant.DBSNP_ID===null) ? "dbSNP" :
                  <span>
                    <a href={`https://www.ncbi.nlm.nih.gov/snp/${variant.DBSNP_ID}`} target="_blank" rel="noreferrer" >dbSNP</a>
                  </span>
                }
              </span>
            } collapsed>
              <div className="variant-card-body d-flex flex-column align-items-center justify-content-center">
                {(dbSNPSrc)
                ?
                <iframe src={dbSNPSrc} style={{width:'100%', height:'30vh'}} height="100" title="dbSNP Frame"></iframe>
                :
                <div>
                  {(variant.DBSNP_ID===null) ? "-" :
                    <span>
                      <a href={`https://www.ncbi.nlm.nih.gov/snp/${variant.DBSNP_ID}`} target="_blank" rel="noreferrer" ><i className="fas fa-external-link-alt"></i> dbSNP</a>
                      <span className="fs-5 mx-5" onClick={ () => setdbSNPSrc(`https://www.ncbi.nlm.nih.gov/snp/${variant.DBSNP_ID}`)} style={{cursor:'pointer'}}>(click to load dbSNP data in here!)</span>
                    </span>
                  }
                </div>
                }
              </div>
            </VariantInfoBox> */}

            
            {/* {renderConservation()} */}
            <VariantInfoBox title="Conservation" min_width="10em" collapsed>
            {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">Conservation</h5> */}
              <div className="variant-card-body d-flex flex-row flex-wrap align-items-center justify-content-center">

              {(variant.DBNSFP_PHYLOP30WAY_MAMMALIAN!==undefined && variant.DBNSFP_PHYLOP30WAY_MAMMALIAN!==null) ?
                <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                  <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">PhyloP30way</span></div>
                  <div className="variant-item text-center">mammalian: {showWithPrecision(variant.DBNSFP_PHYLOP30WAY_MAMMALIAN, 3)}</div>
                  {/* <div className="variant-item text-center">{showWithPrecision(variant.DBNSFP_METASVM_RANKSCORE, 3)}</div> */}
                </div>
              : '' }
              
              {(variant.DBNSFP_METASVM_SCORE!==undefined && variant.DBNSFP_METASVM_SCORE!==null) ?
                <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                  <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">PhastCons30way</span></div>
                  <div className="variant-item text-center">mammalian: {showWithPrecision(variant.DBNSFP_REVEL_SCORE, 3)}</div>
                </div>
              : '' }                
              
              {/* {(variant.DBNSFP_BSTATISTIC_SCORE!==undefined && variant.DBNSFP_BSTATISTIC_SCORE!==null) ?
                <div className="d-flex flex-row flex-nowrap align-items-end pb-0 pt-2 my-1 mx-4 border-bottom border-1" style={{minWidth:'20rem'}}>
                  <div className="variant-item text-center no-white-space-wrap"><span className="fw-bold">bstatistic</span></div>
                  <div className="variant-item text-center">mammalian: {showWithPrecision(variant.DBNSFP_BSTATISTIC_SCORE, 3)}</div>
                </div>
              : '' }                 */}
              </div>
            </VariantInfoBox>{/* </div> */}

            {/* {renderIGV()} */}
            <div className="card m-1 p-0 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}>
              {/* <h5 className="variant-card-header bg-light-blue">IGV</h5> */}
              {/* <div className="variant-card-body d-flex flex-row flex-nowrap align-items-center justify-content-center"> */}
              <div className="variant-card-body d-flex flex-column m-1 mt-0 p-0">
                <IgvVaNex chromosome={variant.CHROMOSOME} position={variant.POSITION} currentAnalysisInfo={props.currentAnalysisInfo}/>
              </div>
            </div>

            <VariantInfoBox title={<span>Pathways <span className="fs-6 ms-3">({(!!processedPathwayTerms && !!processedPathwayTerms.length) ? processedPathwayTerms.length : '-'})</span></span>}
              min_width="10em" collapsed>
              {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">Pathways</h5> */}
              <div className="variant-card-body d-flex flex-column ">
                <div className="card-group flex-grow-1 d-flex flex-column">
                  {/* <div className="mx-2 mb-4"> */}
                    {/* <h5 className="card-title">Pathways</h5> */}
                    <div className="limited-text-height d-flex flex-row flex-wrap ">
                      {(entrez_id_split===undefined) ? "Error!" : ( (entrez_id_split===null) ? <span className="mx-4">-</span> : processedPathwayTerms)} {/* processPathwayTerms(variant.ENTREZ_ID) */}
                    </div>
                  {/* </div> */}
                </div>
                <hr className='my-2' />
                <div className="d-flex flex-row bg-light-blue fs-5 fw-bold px-2 ms-4 me-2">
                  {/* <button className="btn btn-secondary border border-secondary py-0 px-2 fw-bold" style={{margin:'0px'}}
                    onClick={() => setSelectedPathwayInVariantDesc(allPathwayTermsForTheCurrentVariant[0])}
                    // disabled={!canPreviousPage}
                    >
                    &nbsp;{'<'}&nbsp;
                  </button>{' '}
                  <button className="btn btn-secondary border border-secondary py-0 px-2 fw-bold" style={{margin:'0px 5px 0px 1px'}}
                    onClick={() => setSelectedPathwayInVariantDesc(allPathwayTermsForTheCurrentVariant[1])}
                    // disabled={!canNextPage}
                    >
                    &nbsp;{'>'}&nbsp;
                  </button>{' '} */}
                  {/* <i className="fas fa-caret-right mt-1 me-1"></i>  */}
                  All SNVs in "{selectedPathwayInVariantDesc}"
                </div>
                <div className="variant-card-body p-0 m-1 d-flex flex-column show-overflow bg-white ms-4 me-2" style={{ height:'16rem', minHeight:'12rem', maxHeight:'20rem'}}>
                  <VariantTable
                    initialSortBy={props.initialSortBy}
  //! fix this part 
                    updateVariantTableData={() => console.log('ToBeFixed!')}
                    getTabName={() => selectedVariantTab}
                    hiddenColumnsIds={hiddenColumnsIds}
                    // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
                    PMFSampleColNames={getSampleColumnsProbandAtFirst()}
                    columns={columnData} data={otherVariationsInThisPathway}
                  />
                </div>

              </div>
            </VariantInfoBox>{/* </div> */}

            <VariantInfoBox title="Orphanet/HPO" min_width="10em" collapsed>
            {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">Orphanet/HPO</h5> */}
              <div className="variant-card-body d-flex flex-row align-items-center justify-content-center">
    
                <div className="card-group flex-grow-1 d-flex flex-column">
                  <div className="mx-2 mb-4">
                    <h5 className="card-title">HPO</h5>
                    <div className="limited-text-height d-flex flex-row flex-wrap ">
                      {(variant.HPO_LABEL===undefined) ? "Error!" : ( (variant.HPO_LABEL===null) ? <span className="mx-4">-</span> : processHpoTerms(variant.HPO_LABEL) )}
                    </div>
                  </div>
                  <div className="mx-2 mb-4">
                    <h5 className="card-title">Orphanet</h5>
                    <div className="limited-text-height d-flex flex-row flex-wrap ">
                      {(variant.ORPHANET_DISPLAY_SUMMARY===undefined) ? "Error!" : ( (variant.ORPHANET_DISPLAY_SUMMARY===null) ? <span className="mx-4">-</span> : processOrphanetTerms(variant.ORPHANET_DISPLAY_SUMMARY) )}
                    </div>
                  </div>
                  <div className="mx-2">
                    <h5 className="card-title">InterVar</h5>
                    <div className=" d-flex flex-row">
                      {(variant.INTERVAR===undefined) ? "Error!" : ( (variant.INTERVAR===null) ? <span className="mx-4">-</span> : variant.INTERVAR )}
                    </div>
                  </div>
                </div>
    
              </div>
            </VariantInfoBox>{/* </div> */}

            <VariantInfoBox title={<span>ClinGen Disease Association <span className="fs-6 ms-3">({variant.CLINGEN_DISEASE_COUNT >0 ? String(variant.CLINGEN_DISEASE_COUNT): '-' })</span></span>}
                min_width="40em" collapsed>
                  
                <div className="variant-card-body d-flex flex-shrink-0 flex-column">
                  <div className="d-flex flex-row flex-nowrap flex-shrink-0">
                    <div className="ClinGen-Item text-center text-muted flex-grow-1"  style={{maxWidth:"350px"}}>Classification Report</div>
                    <div className="ClinGen-Item text-center text-muted flex-grow-1">Disease Association</div>
                    <div className="ClinGen-Item text-center text-muted flex-grow-1" style={{maxWidth:"350px"}}>Classification Date : SOP</div>
                  </div>
                  {variant.CLINGEN_DISEASE_CLASSIFICATION.length>0 ?
                    (variant.CLINGEN_DISEASE_ONLINEREPORT.split(";").map((row, index) => {
                        return(<div className="d-flex flex-row flex-nowrap flex-shrink-0">
                            <div className="ClinGen-Item text-center flex-grow-1" style={{maxWidth:"350px"}}><a href={row} target="_blank" rel="noreferrer">{String(variant.CLINGEN_DISEASE_CLASSIFICATION.split(";")[index])}</a></div>
                            <div className="ClinGen-Item text-center flex-grow-1">{String(variant.CLINGEN_DISEASE_LABEL.split(";")[index])}(<a href={'https://monarchinitiative.org/'+ variant.CLINGEN_DISEASE_ID.split(";")[index]} target="_blank" rel="noreferrer">{String(variant.CLINGEN_DISEASE_ID.split(";")[index])}</a>) {renderWithBadges(variant.CLINGEN_DISEASE_MOI.split(";")[index])}</div>
                            <div className="ClinGen-Item text-center flex-grow-1" style={{maxWidth:"350px"}}>{String(variant.CLINGEN_DISEASE_CLASSIFICATION_DATE.split(";")[index])} : {String(variant.CLINGEN_DISEASE_SOP.split(";")[index])}</div>
                          </div>)
                    }))
                    :
                      <div className="d-flex flex-row flex-nowrap flex-shrink-0">
                        <div className="text-center flex-grow-1" style={{maxWidth:"350px"}}>-</div>
                        <div className="text-center flex-grow-1">-</div>
                        <div className="text-center flex-grow-1" style={{maxWidth:"350px"}}>-</div>
                      </div>
                    }
                </div>
              </VariantInfoBox>{/* </div> */}
    
            <VariantInfoBox title="Complete Record" min_width="10em" collapsed>
            {/* <div className="card m-1 shadow-sm flex-grow-1" style={{minWidth: '10rem'}}> */}
              {/* <h5 className="variant-card-header bg-light-blue">Complete Record</h5> */}
              <div className="card-body">
                {/* {Object.keys(variant).map( (element) => { return( String(variant[element])) } ) } */}
                { Object.keys(variant).map( (element, i) => <span className="" key={i}> {element}= { String(variant[element]) } <br/></span>) }
              </div>
            </VariantInfoBox>{/* </div> */}
    
          </div>
        </div>
      );
    }
  }

  const renderWithBadges = (text) => {
    const parts = text.split(";");  
    return parts.map((part, index) => 
      part === "AR" ? <span className="ms-1 badge bg-green">AR</span> : part === "AD" ? <span className="ms-1 badge bg-orange">AD</span> : <span>[ {part} ]</span>
    );
  };


  const onVariantFilterChange = (event, useGlobalTabFilters) => {
    // console.log(event.target.name , ' is ', event.target.value);
    let currentFilterName = event.target.name;

    if(event.target.type === 'checkbox'){
      if(useGlobalTabFilters){
        setGlobalTabFilters(prevGlobalTabFilters => ({
          ...prevGlobalTabFilters,
          [currentFilterName]: {
            ...prevGlobalTabFilters[currentFilterName],
            isApplied: Boolean(event.target.checked)
          }
        }));
      } else {
        // * update isApplied of this filter in the current tab 
        setTabs( prevTabs => ({
          ...prevTabs,
          [selectedVariantTab]: {
            ...prevTabs[selectedVariantTab],
            filters:{
              ...prevTabs[selectedVariantTab].filters,
              [currentFilterName]: {
                ...prevTabs[selectedVariantTab].filters[currentFilterName],
                isApplied: Boolean(event.target.checked)
              }
            }
          }
        }));
      }
    }
  }


  const renderFilterInfo = (filterList, useGlobalTabFilters) => {

    if(Object.keys(filterList).length === 0){
      return(<label className="list-group-item ps-4">Found None.</label>)
    }
    else{
      return(
        <React.Fragment>
          {Object.keys(filterList).filter( t => filterList[t].editable===true).map((filterTerm, index)=> {
            return(
              <label className="list-group-item pe-2" key={index}>
                <div className="form-check form-switch m-0">
                  <input className="form-check-input me-1" type="checkbox" name={filterTerm} 
                    onChange={(e) => onVariantFilterChange(e, useGlobalTabFilters)} 
                    checked={filterList[filterTerm]["isApplied"]}
                    />
                      <div className="d-flex flex-row">
                      <div className="filter-description flex-grow-1" >{filterList[filterTerm].getFilterDescr() }</div>
                      {(useGlobalTabFilters)
                        ? 
                        <button className="btn move-filter-button flex-center" 
                          onClick={ () => {addFilterToSet(filterList[filterTerm].columnId)}}
                          ><span className="plus-icon-next-to-arrow"><i className="fas fa-plus fa-xs"></i></span><i className="fas fa-level-up-alt"></i>
                        </button>
                      : null }
                    </div>
                </div>
              </label>
            );
          })}
        </React.Fragment>
      );
    }
  }

  // const addNewFilter = () => {
  //   toast((t) => (<span>{"Yay!, you found a hidden gem!"}<br/> {"(under construction)"}</span>),{icon: '👏', duration:5000});
  //   // showAlert("To be added!", 0, true, 'Success!')
  //   // console.log(JSON.stringify(globalTabFilters))
  //   // localStorage.setItem('globalTabFilters', JSON.stringify(globalTabFilters));
  //   // Object.assign(globalTabFilters, JSON.parse(localStorage.getItem('game_status')));

  //   // console.log(JSON.stringify(variantFilterObj))
  //   // localStorage.setItem('variantFilterObj', JSON.stringify(variantFilterObj));
  //   // Object.assign(variantFilterObj, JSON.parse(localStorage.getItem('game_status')));
    
  // }

  const createNewFilterSet = (inputValue) => {
    // * check to make sure there is no other filter-set with the same name (including the invisible ones)
    let filteKey = inputValue.toUpperCase().replace(/\W/g, '');
    console.log(filterDefinition.XLS_FILTER_SET.filter(t => t.label.toUpperCase().replace(/\W/g, '') === filteKey).length > 0);
    if(filterDefinition.XLS_FILTER_SET.filter(t => t.label.toUpperCase().replace(/\W/g, '') === filteKey).length > 0){
      toast.error('The filter-set name needs to be unique. A hidden filter-set might exist!', {duration:6000})
      return;
    }

    setIsLoadingVisibleFilterSets(true);
    const newFilterSet = {
      value: visibleFilterSets.length,
      label: inputValue,
      user_defined: true,
      visible: true,
      FILTER_DEFINITIONS: {},
    }
    setVisibleFilterSets([...visibleFilterSets, newFilterSet]);

    // * show the new FilterSet
    refForCreatableSelect.current.state.value = newFilterSet; // console.log(refForCreatableSelect.current.state.value); // console.log(refForCreatableSelect.current.props);

    updateFilterSet(visibleFilterSets.length, selectedVariantTab);
    setSelectedFilterSetIndex(visibleFilterSets.length);

    setIsLoadingVisibleFilterSets(false);
  }

  const addFilterToSet = (filterName) => {
    // if(visibleFilterSets[selectedFilterSetIndex].user_defined===true){
      setIsLoadingVisibleFilterSets(true);
      setTabs( prevTabs => ({
        ...prevTabs,
        [selectedVariantTab]: {
          ...prevTabs[selectedVariantTab],
          filters:{
            ...prevTabs[selectedVariantTab].filters,
            [filterName]: {...globalTabFilters[filterName]}
          }
        }
      }));

      let tmpGlobalTabFilters = {...globalTabFilters}
      delete tmpGlobalTabFilters[filterName]        //! is there a way to delete a property without copying it first? NO!
      setGlobalTabFilters(tmpGlobalTabFilters);

      setIsFilterSetModified(true);
      setIsLoadingVisibleFilterSets(false);
    // } else {
    //   toast.error("Can only add to filter-set created by you!");
    // }
  }

  const saveFilterSet = () => {
    if(!!refForCreatableSelect.current){
      if (!refForCreatableSelect.current.state.value){
        toast.error("Select a filter-set first!");
      } else if( selectedFilterSetIndex!==null && selectedFilterSetIndex!==undefined && visibleFilterSets[selectedFilterSetIndex].user_defined===true){
        //* update the filterSet first
        setIsLoadingVisibleFilterSets(true);
        let currentFilterSet = {};
        Object.keys(tabs[selectedVariantTab].filters).forEach( filterId => {
          currentFilterSet[filterId] = {...tabs[selectedVariantTab].filters[filterId]}; //! do we need a shallow copy here?
        });

        // console.log(currentFilterSet);
        setVisibleFilterSets(
          prevVisibleFilterSets => prevVisibleFilterSets.map( 
            el => (el.value === selectedFilterSetIndex ? { ...el, FILTER_DEFINITIONS: currentFilterSet } : el ) 
          )
        );
        setIsFilterSetModified(false);
        setIsLoadingVisibleFilterSets(false);
        toast.success("Saved!");
      }
      else {
        toast.error("Cannot make changes to VaNex filters!");
      }
    }
  }

  const onFilterSetSelectChange = (selectedFilterSet, actionMeta) =>{
    if (!!selectedFilterSet){
      updateFilterSet(selectedFilterSet.value, selectedVariantTab);
      setSelectedFilterSetIndex(selectedFilterSet.value);
    }
  }

  const onFilterMenuOpen = (e, b) => {
    if(isFilterSetModified && selectedFilterSetIndex!==null && selectedFilterSetIndex!==undefined && visibleFilterSets[selectedFilterSetIndex].user_defined===true){
    // if(isFilterSetModified){
      setIsFilterSetModified(false);
      toast((t) => (
        <div className="d-flex flex-row ">
          <div>Save your modifications before switching to another filter-set, otherwise <b>they will be lost!</b></div>
          <button className="btn btn-secondary" onClick={() => toast.dismiss(t.id)}>
            Dismiss
          </button>
        </div>
      ), {duration:6000});
      // toast("Modifications will be lost if you switch to another filter-set without saving this one first!", {duration:8000});
      // toast("Save the filter-set before switching to another one if you want to keep your modifications!", {duration:8000});
      // toast((t) => (
      //   <div className="d-flex flex-row">
      //     <b>Do you want to save this <br/>filter-set?</b>
      //     <button className="btn btn-sm btn-primary my-0 ms-3 me-2" onClick={() => toast.dismiss(t.id)}>
      //       Yes
      //     </button>
      //     <button className="btn btn-sm btn-secondary my-0 ms-2 me-0" onClick={() => toast.dismiss(t.id)}>
      //       No
      //     </button>
      //   </div>
      // ), {duration:Infinity});
    }
  }

  const renderFilterList = () => {
    // const options = [ // ! it might be good to show them under groups
    //   {
    //     label: "RD-SNV",
    //     options: [
    //       { label: "Broad", value: "value_1" },
    //       { label: "Specific", value: "value_2" },
    //       { label: "Germline", value: "value_1" },
    //       { label: "Somatic", value: "value_2" }
    //     ]
    //   },
    //   {
    //     label: "Epilepsy",
    //     options: [
    //       { label: "Germline", value: "value_5" },
    //       { label: "Somatic", value: "value_6" }
    //     ]
    //   },
    //   { label: "A root option", value: "value_3" },
    //   { label: "Another root option", value: "value_4" }
    // ];
    return(
      <div className="d-flex flex-row justify-content-center">
        <div className="absolute-panel scroll-div">
          <div className="flex-center flex-row mt-1">
            <CreatableSelect
              isClearable
              className="flex-grow-1 mx-2"
              ref={refForCreatableSelect}
              isDisabled={isLoadingVisibleFilterSets}
              isLoading={isLoadingVisibleFilterSets}
              onChange={onFilterSetSelectChange}
              // onChange={(selectedFilterSet, actionMeta) => ( (!!selectedFilterSet) ? setSelectedFilterSetIndex(selectedFilterSet.value) : null )}
              onMenuOpen={onFilterMenuOpen}
              onCreateOption={createNewFilterSet}
              options={visibleFilterSets}
              // defaultValue={visibleFilterSets[selectedFilterSetIndex || 0]}
              placeholder='Preset Filter Sets'
            />
            {/* <i className="far fa-save filter-set-button mx-2 flex-center" onClick={saveFilterSet}></i> */}
            <div className="filter-set-button mx-2 flex-center" onClick={saveFilterSet}><i className="far fa-save"></i></div>
          </div>

          <ul className="list-group small mx-1 my-1">
            {renderFilterInfo( tabs[selectedVariantTab].filters, false )}
            {/* {renderFilterInfo( Object.values(tabs[selectedVariantTab].filters).filter( t => t.visible===true), false )} */}
            {/* {renderFilterInfo( Object.keys(tabs[selectedVariantTab].filters).filter( t => tabs[selectedVariantTab].filters[t].visible===true), false )} */}
            {/* {renderFilterInfo(vanexFilterData, false )} */}
          </ul>

          {(Object.keys(globalTabFilters).length>0)
            ?
            <ul className="list-group small m-1">
              <label className="list-group-item fw-bold d-flex flex-row">
                <span className=" flex-grow-1">Global Filters:</span>
                  <React.Fragment>
                    <span className="fw-normal flex-grow-0 text-dblue cursor-pointer "
                    onClick={() => {setGlobalTabFilters({}); setSelectedPathwayIds([])}}
                      >Clear <i className="fas fa-trash-alt text-primary"></i></span>
                  </React.Fragment>
              </label>
              {renderFilterInfo(globalTabFilters, true)}
            </ul>
          : null }

          {/* //* show unmodifiable filters! */}
          <ul className="list-group small mx-1 my-1">
            {/* <label className="list-group-item fw-bold d-flex flex-row">Tab Filters:</label> */}
              { Object.values(tabs[selectedVariantTab].filters).filter( t => t.editable===false).map( (item, i) => 
              <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled  readOnly />{item.getFilterDescr()}</div></label>
              )}
              {/* <label className="list-group-item"><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name="gnomAD" checked="checked" disabled  readOnly />{"gnomAD frq. < 0.001"}</div></label> */}
              { filterDescriptions.map( (item, i) => 
                <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled  readOnly />{item}</div></label>
              )}
          </ul>
          {/* <ul className="list-group small m-1">
              { filterDescriptions.map( (item, i) => 
              <label className="list-group-item" key={i}><div className="form-check form-switch"><input className="form-check-input me-1" type="checkbox" name={item} checked="checked" disabled  readOnly />{item}</div></label>
            )}
          </ul> */}

          {/* <div className="flex-center add-new-filter-wrapper mt-5 mb-3">
            <div className="add-new-filter flex-center" onClick={addNewFilter}><i className="fas fa-plus fa-lg"></i></div>
          </div> */}
        </div>
      </div>
    );
  }

  const org_renderNotesPanel = () => {
    let message = "No notes found.";
    if(currentVariantId===null){
      message = "No variant selected";
    }

    // let noteFilterFunction = t => (t['IS_SET_BY_OTHERS'] && !!t['VANEX_NOTES']);
    // if(selectedVariantTab==='TAB_SUMMARY' && !!tabs[selectedVariantTab] && !!tabs[selectedVariantTab].filters['MAIN_B'] && !!tabs[selectedVariantTab].filters['MAIN_B'].isApplied){
    //   noteFilterFunction = t => t['IS_SET_BY_OTHERS'];
    // }

    const variant = props.caseData[currentVariantId];
    // console.log(variant)
    // console.log(variant.VANEX_NOTES); //! fix this, it may need to get memoized? to have optimized performace
    return(
      <div className="card m-1 shadow-sm flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        <div className="variant-card-header bg-mid-blue text-light py-0 d-flex flex-row align-items-center">
          <span className="fs-5 fw-bold flex-grow-1">Notes</span>
          <div className="">
            <label>
              <input className="form-check-input" type="checkbox" 
                onChange={(e) => setShowVaNexAutoNotes(e.target.checked)} 
                checked={showVaNexAutoNotes}
              /> Auto-Notes
            </label>
          </div>
        </div>
        {/* <h5 className="variant-card-header bg-light-blue">Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#b9def0'}}>Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#dcf3ff'}}>Notes</h5> */}
        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
          <div className="m-2 d-flex flex-column w-100">
          {(!!props.isLoadingNotes)
          ?
            <div className="d-flex flex-row mt-2">
              <span className="mx-3 spinner-border spinner-border-lg" role="status" aria-hidden="true"></span><span className="fs-4">Loading...</span>
            </div>
          :
          <>
            {/* { (!!variant && !!variant.VANEX_NOTES && (!!variant.VANEX_TAGS ||  !!variant.VANEX_NOTES['CURRENT_USER_NOTE'] || ( !!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0))) */}
            { (!!variant && !!variant.VANEX_NOTES )
            ?
              <>
                <div className="note-bubble d-flex flex-column flex-wrap">
                  <div className="fw-bold me-1 mt-1">
                    {/* {(variant.VANEX_TAGS === 1 || variant.VANEX_TAGS === 2 ? 
                      <i className="fas fa-check-circle fa-lg text-primary me-2"></i>
                    : null)} */}
                    {(variant.VANEX_TAGS === 1 ? <i className="fas fa-check-circle fa-lg text-primary me-2"></i> : (variant.VANEX_TAGS === 2 ? <i className="fas fa-user-check fa-lg text-primary me-2"></i> : null) )}
                    {props.userName}
                  </div>
                  <EditableTextArea 
                    value={variant.VANEX_NOTES['CURRENT_USER_NOTE']} 
                    updateTextAreaValue={(value)=> {
                      if(!!props.updateVariantTableData){ 
                        setShouldBlockNavigation(true);
                        props.updateVariantTableData(variant.ROW_INDEX, 'VANEX_NOTES', {
                          ...variant.VANEX_NOTES,
                          CURRENT_USER_NOTE: value, 
                          UPDATED_AT: new Date() 
                          // ALL_USERS_NOTES: variant.VANEX_NOTES['ALL_USERS_NOTES']
                        });
                      } else {toast.error('Cannot update this value!')}
                      if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                        setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                      }
                    }}
                  />
                  {/* {(!!variant.VANEX_NOTES['UPDATED_AT']) ? <hr className="m-1 p-0"/> : null} */}
                  <div className="small text-end">{variant.VANEX_NOTES['UPDATED_AT'] && new Date(variant.VANEX_NOTES['UPDATED_AT']).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true})}</div>
                </div>
                {showVaNexAutoNotes ? <div className="note-bubble d-flex flex-column flex-wrap">
                  <div className="fw-bold me-1 mt-1">
                    VaNex Auto-Notes
                  </div>
                  <div className="note-div px-1 display-linebreak">{VaNexAutoNotes.join('\n')}</div>
                </div> : null }
              {(!!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0 && showOtherUsersNotes)
              ?
                variant.VANEX_NOTES['ALL_USERS_NOTES'].filter(t => t['IS_SET_BY_OTHERS']).map( (note, index) => {
                  // variant.VANEX_NOTES['ALL_USERS_NOTES'].map( (note, index) => {
                  if (!note.VANEX_NOTES && !!note.VANEX_TAGS)
                    return(
                      <div className="d-flex flex-column flex-wrap align-items-center justify-content-center w-100 mb-1" key={index}>
                        <div className="fw-bold "><i className="fas fa-check-circle fa-lg text-primary my-1 mb-2 mx-2"></i>{note.USERNAME.split('::')[0]}{note.USERNAME.split('::').length > 1 ? <div className="text-end text-small">{note.USERNAME.split('::')[1]} </div>: '' }</div>
                      </div>
                    );
                  else if (!!note.VANEX_NOTES)
                    return (
                      // <div className="plus-border-radius border-light-blue mb-2 px-2 py-1 d-flex flex-row flex-wrap" key={index}>
                      <div className="note-bubble d-flex flex-column flex-wrap" key={index}>
                        <div className="fw-bold me-1 mt-1">
                          {(note.VANEX_TAGS === 1 ? <i className="fas fa-check-circle fa-lg text-primary me-2"></i> : (note.VANEX_TAGS === 2 ? <i className="fas fa-user-check fa-lg text-primary me-2"></i> : null) )}
                          {note.USERNAME.split('::')[0]}{note.USERNAME.split('::').length > 1 ? <div className="text-end text-small">{note.USERNAME.split('::')[1]} </div>: '' }
                        </div>
                        <div className="note-div px-1">{note.VANEX_NOTES}</div>
                        {/* <hr className="m-1 p-0"/> */}
                        <div className="small text-end">{note.UPDATED_AT && new Date(note.UPDATED_AT).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true})}</div>
                      </div>
                    );
                  else return( <div key={index}>?</div> );
                })
              : null}
              </>
            :
              <span className="fs-6 text-center">{message}</span>
            }
            </>
          }
          </div>
        </div>
      </div>
    )
  }


  
  const renderNotesPanel = () => {
    let message = "No notes found.";
    if(currentVariantId===null){
      message = "No variant selected";
    }

    // let noteFilterFunction = t => (t['IS_SET_BY_OTHERS'] && !!t['VANEX_NOTES']);
    // if(selectedVariantTab==='TAB_SUMMARY' && !!tabs[selectedVariantTab] && !!tabs[selectedVariantTab].filters['MAIN_B'] && !!tabs[selectedVariantTab].filters['MAIN_B'].isApplied){
    //   noteFilterFunction = t => t['IS_SET_BY_OTHERS'];
    // }

    const variant = props.caseData[currentVariantId];
    // const entrezid = variant?.ENTREZ_ID;
    // console.log("variant", variant)
    // console.log(variant.VANEX_NOTES); //! fix this, it may need to get memoized? to have optimized performace

    if (props.project === "training"){
      return(
        <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
          {renderCurrentAnalysisNotes(variant, message)}
        </div>
      )
    }

    return(
      <SplitPane split="horizontal" minSize={10} maxSize={-50} defaultSize={500} >
        <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
          {renderCurrentAnalysisNotes(variant, message)}
        </div>
        <div className="absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
          {renderAggrigatedNotes(variant, message)}
        </div>
      </SplitPane>
    )
  }



  const renderCurrentAnalysisNotes = (variant, message) => {
    return(
      <div className="card m-1 shadow-sm flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        {/* <div className="variant-card-header bg-mid-blue text-light py-0 d-flex flex-row align-items-center">
          <span className="fs-5 fw-bold flex-grow-1">Notes</span>
          <div className="">
            <label>
              <input className="form-check-input" type="checkbox" 
                onChange={(e) => setShowVaNexAutoNotes(e.target.checked)} 
                checked={showVaNexAutoNotes}
              /> Auto-Notes
            </label>
          </div>
        </div> */}
        {/* <h5 className="variant-card-header bg-light-blue">Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#b9def0'}}>Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#dcf3ff'}}>Notes</h5> */}
        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
          <div className="m-2 d-flex flex-column w-100">
          {(!!props.isLoadingNotes)
          ?
            <div className="d-flex flex-row mt-2">
              <span className="mx-3 spinner-border spinner-border-lg" role="status" aria-hidden="true"></span><span className="fs-4">Loading...</span>
            </div>
          :
          <>
            {/* { (!!variant && !!variant.VANEX_NOTES && (!!variant.VANEX_TAGS ||  !!variant.VANEX_NOTES['CURRENT_USER_NOTE'] || ( !!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0))) */}
            { (!!variant && !!variant.VANEX_NOTES )
            ?
              <>
                <div className="note-bubble d-flex flex-column flex-wrap">
                  <div className="fw-bold me-1 mt-1">
                    {/* {(variant.VANEX_TAGS === 1 || variant.VANEX_TAGS === 2 ? 
                      <i className="fas fa-check-circle fa-lg text-primary me-2"></i>
                    : null)} */}
                    {(variant.VANEX_TAGS === 1 ? <i className="fas fa-check-circle fa-lg text-primary me-2"></i> : (variant.VANEX_TAGS === 2 ? <i className="fas fa-user-check fa-lg text-primary me-2"></i> : null) )}
                    {props.userName}
                  </div>
                  <EditableTextArea 
                    value={variant.VANEX_NOTES['CURRENT_USER_NOTE']} 
                    updateTextAreaValue={(value)=> {
                      if(!!props.updateVariantTableData){ 
                        setShouldBlockNavigation(true);
                        props.updateVariantTableData(variant.ROW_INDEX, 'VANEX_NOTES', {
                          ...variant.VANEX_NOTES,
                          CURRENT_USER_NOTE: value, 
                          UPDATED_AT: new Date() 
                          // ALL_USERS_NOTES: variant.VANEX_NOTES['ALL_USERS_NOTES']
                        });
                      } else {toast.error('Cannot update this value!')}
                      if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                        setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                      }
                    }}
                  />
                  {/* {(!!variant.VANEX_NOTES['UPDATED_AT']) ? <hr className="m-1 p-0"/> : null} */}
                  <div className="small text-end">{variant.VANEX_NOTES['UPDATED_AT'] && new Date(variant.VANEX_NOTES['UPDATED_AT']).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true})}</div>
                </div>
                {showVaNexAutoNotes ? <div className="note-bubble d-flex flex-column flex-wrap">
                  <div className="fw-bold me-1 mt-1">
                    VaNex Auto-Notes
                  </div>
                  <div className="note-div px-1 display-linebreak">{VaNexAutoNotes.join('\n')}</div>
                </div> : null }
              {(!!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0 && showOtherUsersNotes)
              ?
                variant.VANEX_NOTES['ALL_USERS_NOTES'].filter(t => t['IS_SET_BY_OTHERS']).map( (note, index) => {
                  // variant.VANEX_NOTES['ALL_USERS_NOTES'].map( (note, index) => {
                  if (!note.VANEX_NOTES && !!note.VANEX_TAGS)
                    return(
                      <div className="d-flex flex-column flex-wrap align-items-center justify-content-center w-100 mb-1" key={index}>
                        <div className="fw-bold ">{(note.VANEX_TAGS === 1 ? <i className="fas fa-check-circle fa-lg text-primary me-2"></i> : (note.VANEX_TAGS === 2 ? <i className="fas fa-user-check fa-lg text-primary me-2"></i> : null) )}{note.USERNAME.split('::')[0]}{note.USERNAME.split('::').length > 1 ? <div className="text-end text-small">{note.USERNAME.split('::')[1]} </div>: '' }</div>
                      </div>
                    );
                  else if (!!note.VANEX_NOTES)
                    return (
                      // <div className="plus-border-radius border-light-blue mb-2 px-2 py-1 d-flex flex-row flex-wrap" key={index}>
                      <div className="note-bubble d-flex flex-column flex-wrap" key={index}>
                        <div className="fw-bold me-1 mt-1">
                          {(note.VANEX_TAGS === 1 ? <i className="fas fa-check-circle fa-lg text-primary me-2"></i> : (note.VANEX_TAGS === 2 ? <i className="fas fa-user-check fa-lg text-primary me-2"></i> : null) )}
                          {note.USERNAME.split('::')[0]}{note.USERNAME.split('::').length > 1 ? <div className="text-end text-small">{note.USERNAME.split('::')[1]} </div>: '' }
                        </div>
                        <div className="note-div px-1">{note.VANEX_NOTES}</div>
                        {/* <hr className="m-1 p-0"/> */}
                        <div className="small text-end">{note.UPDATED_AT && new Date(note.UPDATED_AT).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute:'2-digit', hour12: true})}</div>
                      </div>
                    );
                  else return( <div key={index}>?</div> );
                })
              : null}
            </>
            :
              <span className="fs-6 text-center">{message}</span>
            }
            </>
          }
          </div>
        </div>
      </div>
    )
  }



  const renderAggrigatedNotes = (variant, message) => {
    const entrezid = variant?.ENTREZ_ID;
    const varChromosome = variant?.CHROMOSOME;
    const varPosition = variant?.POSITION;
    const varChrPosId = `${varChromosome}-${varPosition}`;
    // console.log('NOTES POS:',aggNotesPositionLevel?.[varChrPosId])
    // console.log('NOTES GENE:',aggNotesGeneLevel?.[entrezid])
    //! add this, on click setShownAggNotesPanelId to 1 or 2 or 3
    return(
      <div className="card m-1 shadow-sm flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        <Tabs>
          <TabList>
            <Tab><span>Notes : Pos {aggNotesPositionLevel[varChrPosId]?.length || 0} | Gene {aggNotesGeneLevel[entrezid]?.length  || 0} </span></Tab>
            {/*
            
            <Tab><span>Position [{aggNotesPositionLevel[varChrPosId]?.length}]</span></Tab>*/}
            <Tab><span><i className="fas fa-cogs"></i></span></Tab>
          </TabList>
          <TabPanel>{renderAggrigatedGeneLevelNotes(variant, message)}</TabPanel>
          {/* <TabPanel>{renderAggrigatedPositionLevelNotes(variant, message)}</TabPanel> */}
          <TabPanel>{renderAggrigatedNotesSettings(variant, message)}</TabPanel>
        </Tabs>
      </div>
    );
  }


  const renderAggrigatedGeneLevelNotes = (variant, message) => {
    const entrezid = variant?.ENTREZ_ID;
    const varChromosome = variant?.CHROMOSOME;
    const varPosition = variant?.POSITION;
    // console.log('aggNotesGeneLevel',aggNotesGeneLevel)
    return(
      <div className="show-overflow absolute-panel d-flex flex-column flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        {/* 
        <div className="card m-1 shadow-sm flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        <div className="variant-card-header bg-mid-blue text-light py-0 d-flex flex-row align-items-center">
          <span className="fs-5 fw-bold flex-grow-1">Aggregated Notes</span>
          <div className="">
            <label>
              <input className="form-check-input" type="checkbox" 
                onChange={(e) => setShowVaNexAutoNotes(e.target.checked)} 
                checked={showVaNexAutoNotes}
              /> Auto-Notes
            </label>
          </div>
        </div> */}
        {/* <h5 className="variant-card-header bg-light-blue">Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#b9def0'}}>Notes</h5> */}
        {/* <h5 className="variant-card-header" style={{backgroundColor:'#dcf3ff'}}>Notes</h5> */}
        <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
          <div className="m-2 d-flex flex-column w-100">
          {(!!props.isLoadingNotes)
          ?
            <div className="d-flex flex-row mt-2">
              <span className="mx-3 spinner-border spinner-border-lg" role="status" aria-hidden="true"></span><span className="fs-4">Loading...</span>
            </div>
          :
          <>
            {/* { (!!variant && !!variant.VANEX_NOTES && (!!variant.VANEX_TAGS ||  !!variant.VANEX_NOTES['CURRENT_USER_NOTE'] || ( !!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0))) */}
            { (!!variant && !!variant.VANEX_NOTES )
            ?
              <>
              {( isLoadingAggNotes
              ?
                <div>LOADING...</div> 
              : 
                renderAggNotes(aggNotesGeneLevel, entrezid, varChromosome, varPosition)
            )}
            </>
            :
              <span className="fs-6 text-center">{message}</span>
            }
            </>
          }
          </div>
        </div>
      </div>
    )
  }

  // const renderAggrigatedPositionLevelNotes = (variant, message) => {
  //   const varChromosome = variant?.CHROMOSOME;
  //   const varPosition = variant?.POSITION;
  //   const varChrPosId = `${varChromosome}-${varPosition}`;
    
  //   return(
  //     <div className="show-overflow absolute-panel d-flex flex-column flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
  //     {/* 
  //     <div className="card m-1 shadow-sm flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
  //       <div className="variant-card-header bg-mid-blue text-light py-0 d-flex flex-row align-items-center">
  //         <span className="fs-5 fw-bold flex-grow-1">Aggregated Notes</span>
  //         <div className="">
  //           <label>
  //             <input className="form-check-input" type="checkbox" 
  //               onChange={(e) => setShowVaNexAutoNotes(e.target.checked)} 
  //               checked={showVaNexAutoNotes}
  //             /> Auto-Notes
  //           </label>
  //         </div>
  //       </div> */}
  //       {/* <h5 className="variant-card-header bg-light-blue">Notes</h5> */}
  //       {/* <h5 className="variant-card-header" style={{backgroundColor:'#b9def0'}}>Notes</h5> */}
  //       {/* <h5 className="variant-card-header" style={{backgroundColor:'#dcf3ff'}}>Notes</h5> */}
  //       <div className="d-flex flex-row flex-nowrap align-items-center justify-content-center">
  //         <div className="m-2 d-flex flex-column w-100">
  //         {(!!props.isLoadingNotes)
  //         ?
  //           <div className="d-flex flex-row mt-2">
  //             <span className="mx-3 spinner-border spinner-border-lg" role="status" aria-hidden="true"></span><span className="fs-4">Loading...</span>
  //           </div>
  //         :
  //         <>
  //           {/* { (!!variant && !!variant.VANEX_NOTES && (!!variant.VANEX_TAGS ||  !!variant.VANEX_NOTES['CURRENT_USER_NOTE'] || ( !!variant.VANEX_NOTES['ALL_USERS_NOTES'] && !!variant.VANEX_NOTES['ALL_USERS_NOTES'].length>0))) */}
  //           { (!!variant && !!variant.VANEX_NOTES )
  //           ?
  //             <>
  //             {( isLoadingAggNotes
  //             ?
  //               <div className="d-flex flex-row mt-2">
  //                 <span className="mx-3 spinner-border spinner-border-lg" role="status" aria-hidden="true"></span><span className="fs-4">Loading...</span>
  //               </div>
  //             : 
  //               renderAggNotes(aggNotesPositionLevel, varChrPosId, true)
  //           )}
  //           </>
  //           :
  //             <span className="fs-6 text-center">{message}</span>
  //           }
  //           </>
  //         }
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }

  const renderAggNotes = (aggNotes, noteKey, varChromosome, varPosition) => {
    // console.log('NOTEKEY',noteKey)
    // console.log('aggNotes',aggNotes.items)
    // console.log('aggNotes',aggNotes[noteKey])
    if ( (noteKey in aggNotes) && (aggNotes[noteKey]) && (aggNotes[noteKey].length > 0)){
      const filteredAggNotes = aggNotes[noteKey].filter( note => (note.VANEX_NOTES && 
          note.VANEX_NOTES.length > minAggNotesChar &&
          selectedAggNotesUsers.includes(note.USERNAME)
        ));   //! set the length here
      if(orderOfAggNotes === "Length")
        filteredAggNotes.sort( (elA,elB) => (elA.VANEX_NOTES.length > elB.VANEX_NOTES.length) ? -1 : ((elB.VANEX_NOTES.length > elA.VANEX_NOTES.length) ? 1 : 0)); // * reverse sorting (descending)
      else if(orderOfAggNotes === "POS")
        filteredAggNotes.sort( (elA,elB) => (((elA.CHR === varChromosome) && (elA.POS === varPosition)) ? -1 : (elB.CHR === varChromosome) && (elB.POS === varPosition) ? 1 : 0));
      // else if(orderOfAggNotes === "User") 
      //   filteredAggNotes = filteredAggNotes.sort()
      // // else // Date is the default 
      //   // filteredAggNotes = filteredAggNotes.sort()
      // console.log('note',filteredAggNotes)
      if (filteredAggNotes.length > 0) {
        return (
          <>
            {filteredAggNotes.map((note, index) => {
              // Check if this is the first item that doesn't match the condition
              // Check if the current note matches the condition
              const isMatching = note?.CHR === varChromosome && note?.POS === varPosition;

              // Find if the current note is the first non-matching item
              const isFirstNonMatching = !isMatching && 
                filteredAggNotes.slice(0, index).every(n => n?.CHR === varChromosome && n?.POS === varPosition);

              // Check if there are no matching items at all
              const hasNoMatches = filteredAggNotes.every(n => !(n?.CHR === varChromosome && n?.POS === varPosition));

              // Check if all items match the condition
              const allMatch = filteredAggNotes.every(n => n?.CHR === varChromosome && n?.POS === varPosition);

              return (
                <div key={index}>
                  {(index===0 && orderOfAggNotes === "POS") && (
                    <div className="AggNote-divider">
                      <span>{"Position Match"}</span>
                    </div>
                  )}
                  {/* Render the custom divider before the first non-matching item */}
                  {((isFirstNonMatching || (hasNoMatches && index === 0)) && orderOfAggNotes === "POS") && (
                    <div className="AggNote-divider">
                      <span>{"Gene Match"}</span>
                    </div>
                  )}
                  <div
                    className={`note-bubble aggnote-bubble d-flex flex-column flex-wrap ${
                      note?.CHR === varChromosome && note?.POS === varPosition
                        ? "border-shadow1"
                        : ""
                    }`}
                  >
                    {/* Render the note details */}
                    <Accordion>
                      <Accordion.Item eventKey={index}>
                        <Accordion.Header>
                          <div className="fw-bold flex-row">
                            <div className="fw-bold flex-grow-1">
                              {note?.EFFECT}
                            </div>
                            <div className="fw-bold small">{note?.CASE_ID}</div>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="small">
                            <b>Variant: </b>
                            {note?.CHR}-{note?.POS}-{note?.REF}-{note?.ALT}
                          </div>
                          <div className="small">
                            <b>Loc In Gene: </b>
                            {note?.LOC_IN_GENE}
                          </div>
                          <div className="small">
                            <b>Analysis ID: </b>
                            {note?.ANALYSIS_ID}
                          </div>
                          <div className="small">
                            <b>GENE: </b>
                            {note?.GENE}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <div className="note-div px-1">{note.VANEX_NOTES}</div>
                    <div className="small d-flex flex-row">
                      <div className="flex-grow-1">
                        {/* {console.log(note)} */}
                        {note?.VANEX_TAGS === "true" ?
                          <i className="fas fa-check-circle fa-lg me-2 darker-orange"></i> : null
                        }
                        {note?.USERNAME.split("_")?.[0]?.slice(0, 9)}.{" "}
                        {note?.USERNAME.split("_")?.[1]?.[0]}.
                      </div>
                      <div className="small text-end">
                        {note.UPDATED_AT &&
                          new Date(note.UPDATED_AT).toLocaleString([], {
                            year: "numeric",
                            month: "numeric",
                            day: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                      </div>
                    </div>
                  </div>
                  {/* Render the divider at the bottom if all items match */}
                  {(allMatch && index === filteredAggNotes.length - 1 && orderOfAggNotes === "POS") && (
                    <div className="AggNote-divider">
                      <span>{"Gene Match"}</span>
                    </div>
                  )}
                </div>
              );
            })}
          </>
        );
      }
    }
    return(<div>Found None!</div>);
  }


  const renderAggrigatedNotesSettings = (variant, message) => {
    return(
      <div className="show-overflow absolute-panel d-flex flex-column m-1 flex-grow-1 bg-white" style={{minWidth: '10rem'}}>
        <div className="mt-2"  style={{fontSize:'larger'}}>
          <b>Sort Agg. Notes by</b>
          <select
            className="mx-2"
            value={orderOfAggNotes}
            onChange={e => {
              setOrderOfAggNotes(e.target.value || undefined)
            }}
            >
              {["Date", "Length", "POS"].map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
          </select>
        </div>
        <div className="mt-3" style={{fontSize:'larger'}}>
          <hr className="m-0 my-1 p-0"/>
          <b>Filters:</b>
        </div>
        <div>{renderUserFilterForm()}</div>
      </div>
    );
  }

  const renderUserFilterForm = () => {
    let selColUniqueValues = AggNotesUsers.sort()//.sort( (elA,elB) => ( Number(!elA) - Number(!elB)) );
    let allUsersAreSelected = selectedAggNotesUsers.every(element => AggNotesUsers.includes(element));

    let initValues = {
      // columnName: "USERNAME",
      noteLength: minAggNotesChar,
      value: '',
      valueIsList: true,
      // separateValueListBy: ' ',
      // minValue: '',maxValue: '',
      includeNullValues: true, 
      selTerms: selectedAggNotesUsers, 
      // filterBy: "SelectedTerms", 
      // filterBy: "UserProvidedValue", 
      selectAllStatus: (allUsersAreSelected ? CHECKED : UNCHECKED)
    }

    return(
      <Formik className="" enableReinitialize={true} initialValues={initValues} onSubmit={ (fields) => {
        setMinAggNotesChar(fields.noteLength)
        setSelectedAggNotesUsers(fields.selTerms)
      }}
      >
        {({ errors, values, touched, setValues }) => (
          <Form>
          <div className="1modal-body">
            {/* <div className="d-flex flex-row w-100">
              <div className="d-flex flex-column flex-grow-1">
                <label className="">
                  <Field name="filterBy" className="form-check-input me-2" type="radio" value="UserProvidedValue" placeholder="" aria-label=""/>
                  {selectedColumn.Header}<span className="fw-bold mx-1">Contains</span>
                </label>
                <label className="form-check-label flex-grow-1">
                  <Field className="form-control w-100" type="text" name="value" placeholder="" aria-label=""
                  onFocus={e => { values.filterBy ='UserProvidedValue'; }}
                  />
                </label>
              </div>
              <label className="d-flex flex-row ms-1 align-items-center flex-grow-0">
                <Field className="mx-1" name="valueIsList" type="checkbox" />
                <div className={(values.valueIsList ? '' : 'text-muted')}>
                  multiple values <br/>separated by 
                  <Field className="mx-1" disabled={!values.valueIsList} name="separateValueListBy" type="text" style={{maxWidth:'1.5rem'}}/>
                </div>
              </label>
            </div>
            <hr/> */}
            {/* <hr className="m-0 my-1 p-0"/> */}
            <label className="d-flex flex-row align-items-center">
              <div className="fw-bold ms-1 me-2">* Longer than </div>
              <Field name="noteLength" className="form-check-input m-0 p-1" style={{minWidth:'3rem', minHeight:'2rem'}} type="number" min="25" max="2000" placeholder="Min Character Length" aria-label=""/>
            </label>
            <div className="ms-3">Notes shorter than 25 character are already excluded.</div>

            {/* <hr className="m-0 my-1 p-0"/> */}
            <label className="">
              {/* <Field name="filterBy" className="form-check-input me-2" type="radio" value="SelectedTerms" placeholder="" aria-label=""/> */}
              <span className="fw-bold mx-1">* Writen by</span>
            </label>
            <div className="ist-group list-group-flush border border-secondary border-1 m-1 show-overflow l" style={{maxHeight:'15vh'}}>
              <label className="list-group-item">
                <IndeterminateCheckbox
                  className="form-check-input me-1"
                  name="select-all"
                  value={values.selectAllStatus}
                  onFocus={e => { values.filterBy ='SelectedTerms'; }}
                  onChange={e => {
                    let chboxStatus = INDETERMINATE;
                    let selectedTerms = [];
                    if (e.target.checked) {
                      selColUniqueValues.forEach( (term) => selectedTerms.push(term));
                      chboxStatus = CHECKED;
                    } else {
                      chboxStatus = UNCHECKED;
                    }
                    setValues({ ...values, 
                      selectAllStatus: chboxStatus,
                      selTerms: selectedTerms 
                    });
                  }}
                />
                Select All
              </label>
              <FieldArray
                name="selTerms"
                render={arrayHelpers => (
                  <React.Fragment>
                    {selColUniqueValues.map( (tag, ind) => (
                      <label className="list-group-item" key={ind}>
                        <input
                          className="form-check-input me-1"
                          type="checkbox"
                          name={(tag!==null && tag!==undefined && tag!=='') ? tag.toString() : "__Blank__"}
                          checked={values.selTerms.includes(tag)}
                          onFocus={e => { values.filterBy ='SelectedTerms'; }}
                          onChange={e => {
                            let chboxStatus = INDETERMINATE;
                            let selectedTerms = [...values.selTerms];
                            if(values.selTerms.length === 0){
                              chboxStatus = UNCHECKED;
                            }
                            if (e.target.checked) {
                              if(values.selTerms.length === 0){
                                chboxStatus = INDETERMINATE;
                              }
                              if(selColUniqueValues.length === (values.selTerms.length+1) ){
                                chboxStatus = CHECKED;
                              }
                              // arrayHelpers.push(tag);
                              selectedTerms.push(tag);
                            } else {
                              if(values.selTerms.length-1 === 0){
                                chboxStatus = UNCHECKED;
                              }
                              const removeIndex = values.selTerms.indexOf(tag);
                              // arrayHelpers.remove(removeIndex);
                              // let removeIndex = selectedTerms.map(item => item.id).indexOf(tag);
                              ~removeIndex && selectedTerms.splice(removeIndex, 1);  //! <= this is a nice way of doing this: (removeIndex >= 0) && array.splice(removeIndex, 1);
                            }
                            setValues({ ...values, 
                              selectAllStatus: chboxStatus,
                              selTerms: selectedTerms 
                            });
                          }}
                        />
                        {/* <span>{ (typeof(tag)==='boolean' ? ( (tag) ? 'Checked' : 'Unchecked') : (!!tag ? tag : "__Blank__") )}</span> */}
                        {/* <span>{(!!tag) ? (typeof(tag)==='boolean' ? 'Checked' : tag ) : "__Blank__"}</span> */}
                        <span>{(tag!==null && tag!==undefined && tag!=='') ? tag.replaceAll("_"," ") : "__Blank__"}</span>
                      </label>
                    ))}
                  </React.Fragment>
                )}
              />
            </div>
          </div>
          <div className="modal-footer ">
            <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i>Apply Filter</button>
            <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
          </div>
        </Form>)}
      </Formik>
    );
  }



  const handleToggleColumnVisibility = (e, columnId) => {
    let hiddenCols = [...hiddenColumnsIds];
    // TODO: check if this is in the array? this is fine now, but it might be good to check if the checkbox status matches th array!
    if(e.target.checked) {
      // console.log(columnId + ' removed from hiddenColumnsIds');
      hiddenCols = hiddenCols.filter(t => t!==columnId);
      if(columnOrder.indexOf(columnId) < 0) setColumnOrder([...columnOrder, columnId]);
    }
    else {
      // console.log(columnId + ' pushed to hiddenColumnsIds');
      hiddenCols.push(columnId);
    }
    setHiddenColumnsIds(hiddenCols);
  }

  const getColumnNameFromAccessor = (columnAccessor) => {
    let colDefinition = getParameterCaseInsensitive(variantFormatDefinition['column-definitions'],columnAccessor);
    if(!!colDefinition){
      let colHeader= colDefinition['header'];
      if(colHeader){
        return colHeader;
      } else{
        return columnAccessor;
      }
    } else {
      // console.log(colDefinition, columnAccessor);
      return columnAccessor;
    }
  }

  const changeVisibleColumnsToDefaultForExport = () => {
    let defaultExportVisibleColumns = ['VANEX_TAGS','VANEX_NOTES','CHROMOSOME','POSITION','REF','ALT','P_GT','GENE','MANE_HGVS','LOC_IN_GENE','EFFECT','OMIM_PHENOTYPES','FUNC_ELEMENT']; // ,'MR_PHENE_PHENOTYPE_TERMS','CLINVAR_CLNSIG','GNOMAD_WG_FAF95_POPMAX','GNOMAD_EX_FAF95_POPMAX', 'QUAL',
    let hiddenCols = [];
    Object.values(columnData).forEach(column => {
      if(!defaultExportVisibleColumns.includes(column.id) &&
          !( column.id.endsWith('_GT')) // && !(include_Alt_Percent && column.id.endsWith('_Alt_Percent'))
        ){
        hiddenCols.push(column.id);
      }
    });
    setHiddenColumnsIds(hiddenCols);
  }

  const [columnNameSearchValue, setColumnNameSearchValue] = useState('');

  const renderColumnList = () => {
    const sampleColNames = Object.keys(sampleColumns).map(i => i);
    let columnList = Object.values(columnData).filter( t => t.id!=='SAMPLES' && t.id!=='ROW_INDEX' )
      .sort( (elA,elB) => {
        if(!sampleColNames.includes(elA.id) && sampleColNames.includes(elB.id)) return 1;
        if(sampleColNames.includes(elA.id) && !sampleColNames.includes(elB.id)) return -1;
        if(sampleColNames.includes(elA.id) && sampleColNames.includes(elB.id))
          return(sampleColNames.indexOf(elA.id) - sampleColNames.indexOf(elB.id));

        if(!columnOrder.includes(elA.id) && !columnOrder.includes(elB.id)) return 1;
        if(!columnOrder.includes(elA.id) && columnOrder.includes(elB.id)) return 1;
        if(columnOrder.includes(elA.id) && !columnOrder.includes(elB.id)) return -1;
        return(columnOrder.indexOf(elA.id) - columnOrder.indexOf(elB.id));
    });
    if(columnNameSearchValue!==undefined && columnNameSearchValue!==null && columnNameSearchValue.trim() !== ''){ //(columnNameSearchValue.length>0){
      columnList = columnList.filter(t => String(t['Header']).toLowerCase().includes(columnNameSearchValue.toLowerCase()));
    }
    return (
      <div className="absolute-panel scroll-div fw-normal p-2">
        <button type="button" className="btn btn-sm btn-outline-primary mx-4 my-1 px-3 py-0" onClick={changeVisibleColumnsToDefaultForExport}>Default export columns</button>
        <input className="w-100 mb-1" type="text" placeholder="Search Column Names" value={columnNameSearchValue} onChange={(e)=>setColumnNameSearchValue(e.target.value)}/>
        { columnList.map( (column, index) => (
            <div className="no-white-space-wrap" key={index}>
              { ( !!showPMFPrefix && !!sampleColumns[column.id] ?  
                ((!!getTypeOfColumnsInPMFList(column)) ? 
                  // <button type="button" className="btn btn-sm btn-outline-secondary m-0 ms-1 p-0 px-1" data-bs-toggle="modal" data-bs-target="#refForChangeSampleTypeModal">{column.sampleType}</button>
                  <button type="button" style={{minWidth:'2rem'}}
                    className="btn btn-sm btn-outline-secondary m-0 me-1 mb-1 p-0 px-1"
                    onClick={() => sampleTypeModal.show()}
                  >
                    {getTypeOfColumnsInPMFList(column)}
                  </button>
                 : <span style={{marginRight:'2.25rem'}}></span>)
              : <></>)}
              <label>
                <input type="checkbox" className="me-1"
                  checked={!hiddenColumnsIds.includes(column.id)}
                  onChange={(e) => handleToggleColumnVisibility(e, column.id)}
                  disabled={(!hiddenColumnsIds.includes(column.id) && hiddenColumnsIds.length >= (Object.keys(columnData).length)-3)}
                />
                {column.Header}
              </label>
            </div>
        ))}
        {(columnNameSearchValue.length>0) ? 
          <div className="text-dblue cursor-pointer text-center" onClick={()=>setColumnNameSearchValue('')}>+ {Object.values(columnData).length - Object.values(columnList).length } unmatched (show all)</div>
        : null }
      </div>
    );
  }

  const handleTogglePathwaySelection = (e, pathwayId) => {
    let selPathways = [...selectedPathwayIds];
    // TODO: check if this is in the array? this is fine now, but it might be good to check if the checkbox status matches th array!
    if(e.target.checked) {
      // console.log(pathwayId + ' pushed to selectedPathwayIds');
      selPathways.push(pathwayId);
    }
    else {
      // console.log(pathwayId + ' removed from selectedPathwayIds');
      selPathways = selPathways.filter(t => t!==pathwayId);
    }
    setSelectedPathwayIds(selPathways);
  }

  const renderPathwayList = () => {
    let pathwayList = Object.keys(pathways)
    if(pathwayCategory!=='ALL')
      pathwayList=pathwayList.filter(t => t.includes(`(${pathwayCategory})`))

    pathwayList.sort( (elA,elB) => {
      if(!selectedPathwayIds.includes(elA) && !selectedPathwayIds.includes(elB)) return 1;
      if(!selectedPathwayIds.includes(elA) && selectedPathwayIds.includes(elB)) return 1;
      if(selectedPathwayIds.includes(elA) && !selectedPathwayIds.includes(elB)) return -1;
      return(selectedPathwayIds.indexOf(elA) - selectedPathwayIds.indexOf(elB));
    });

    if(pathwaySearchValue.length>0){
      pathwayList = pathwayList.filter(t => t.toLowerCase().includes(pathwaySearchValue.toLowerCase()));
    }
    return (
      <div className="absolute-panel scroll-div fw-normal p-2">
        <div>
          Database: <select value={pathwayCategory} onChange={e => {setPathwayCategory(e.target.value)}}>
            {["ALL","BIOCARTA", "KEGG", "PID", "REACTOME", "WP"].map(k => (
              <option key={k} value={k}>{(k==='WP') ? 'WikiPathways' : k}</option>
            ))}
          </select>{` (n=${pathwayList.length})`}</div>
        <input className="w-100 mb-1" type="text" placeholder="Search Pathways" value={pathwaySearchValue} onChange={(e)=>setPathwaySearchValue(e.target.value)}/>
        {(pathwaySearchValue.length>0) ? 
          <div className="text-dblue cursor-pointer text-center" onClick={() => setSelectedPathwayIds([...selectedPathwayIds, ...pathwayList])}>Select All Matched</div>
        :
        (selectedPathwayIds.length>0) ? 
          <div className="text-dblue cursor-pointer text-center" onClick={() => setSelectedPathwayIds([])}>Unselect All</div>
        : null }
        { pathwayList.map( (column, index) => (
            <div className="no-white-space-wrap" key={index}>
              <label>
                <input type="checkbox" className="me-1"
                  checked={selectedPathwayIds.includes(column)}
                  onChange={(e) => handleTogglePathwaySelection(e, column)}
                />
                {column}
              </label>
            </div>
        ))}
        {(pathwaySearchValue.length>0) ? 
          <div className="text-dblue cursor-pointer text-center" onClick={()=>setPathwaySearchValue('')}>+ {Object.keys(pathways).length - Object.keys(pathwayList).length } unmatched (show all)</div>
        : null }
      </div>
    );
  }

  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  useEffect(() => {
    if (shouldBlockNavigation) {
      window.onbeforeunload = () => true
    } else {
      window.onbeforeunload = undefined
    }
  });
  useEffect(() => {
    props.setShouldBlockNavigation(shouldBlockNavigation);
  }, [shouldBlockNavigation])
  

  function sendDenovoAssessment(values, variantIndex, columnId, valueToSet){ 
    
    let newVals = {
      'PROJECT':props.project,
      'USERNAME':props.userName,
      'CASE_ID':props.currentCaseInformation.CASE_ID,
      'DENOVO_ASSESSMENT': values['VANEX_REVIEWS']['DENOVO_ASSESSMENT'], //DENOVOMANUAL_ASSESSMENT
      "CHROMOSOME": values['CHROMOSOME'],
      'POSITION': values['POSITION'],
      'REF': values['REF'],
      'ALT': values['ALT'],
      'PMF': PMFSampleColNames,
      'DATA':values
      // 'CYTOBAND_ID': values['CYTOBAND_ID'],
      // 'LOC_IN_GENE': values['LOC_IN_GENE'],
      // 'NUM_TO_SPLICE_SITE': values['NUM_TO_SPLICE_SITE'],
      // 'DIST_TO_SPLICE_SITE': values['DIST_TO_SPLICE_SITE'],
      // 'DIST_TO_CODING_REGION': values['DIST_TO_CODING_REGION'],
      // 'EFFECT': values['EFFECT'],
    };
    console.log(values);
    console.log(newVals);

    Auth.currentSession().then(data => {
      let id_token = data.getIdToken().getJwtToken();
      const url_to_send_to = `${ENDPOINT_BASE}collect_denovo_assessment?key=denovo_assessments/${props.currentCaseInformation.CASE_ID}.json.gz`;

      toast.promise(
        postDenovoAssessmentInformation(url_to_send_to, newVals, id_token, variantIndex, columnId, valueToSet),
        {
          loading: 'Saving...',
          success: (data) => <>Successfully saved<br/>{data}</>,
          error: (err) => {
            console.error(err);
            return(`Error: ${err}`);
          }
        },{
          success: {
            duration: 3000,
          },
        }
      );

    })
    .catch( e => toast.error(`Error in saving: ${e}`, {duration:8000}));
    // toast.success("Feedback sent!", {duration:2000});
    // console.log({'PROJECT':props.project, 'USERNAME':props.userName, 'CASE_ID':props.currentCaseInformation.CASE_ID,...values});
  }

  function postDenovoAssessmentInformation(url_to_send_to, fields, id_token, variantIndex, columnId, valueToSet) {
    return new Promise(function (resolve, reject) {
      // if (request) {  // ! ---------------------------- uncomment this back?! ?!!!!!! -------------------------
      //   request.abort();
      // }
      console.log(fields);
      let xhReq = new XMLHttpRequest();
      // request = xhReq;

      // get a callback when the server responds
      xhReq.addEventListener("load", (oEvent) => {
        if (xhReq.status===200) {
          // toast.success("Feedback sent!", {duration:2000});

          //! fix this. this is copied from below
          if(!!props.updateVariantTableData){ 
            setShouldBlockNavigation(true);
            props.updateVariantTableData(variantIndex, columnId, valueToSet);
          } else {toast.error('Cannot update this value!')}
          if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
            setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
          }
          
          resolve(xhReq.responseText);
        }
        else {
          reject(xhReq.responseText);
        }
      });

      // TODO: handle error in these:
      xhReq.addEventListener("error", () => {  reject("An error occurred while transferring the file"); });
      xhReq.addEventListener("abort", () => { reject("Transfer has been canceled by the user"); });
      // xhReq.addEventListener("error", () => {  setErrorLog(['An error occurred while transferring the data','']);  });
      // xhReq.addEventListener("abort", () => { setErrorLog(['Transfer has been canceled by the user', '']);   });
      // xhReq.addEventListener("loadend",() => { setErrorLog(['Transfer finished (succeeded or not?!)'], '');   });
      // xhReq.addEventListener("loadstart",() => { console.error('Transfer started!');   });
      
      xhReq.open("POST", url_to_send_to, true);
      // xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded; charset=UTF-8");
      xhReq.setRequestHeader('Content-Type', 'application/json');
      xhReq.setRequestHeader('Accept', 'application/json');
      xhReq.setRequestHeader('Authorization','Bearer ' + id_token);

      // console.log('----');
      // console.log(fields);
      xhReq.send(JSON.stringify(fields));
    });
  }

  const renderVariantTab = () => {
    if(!mainUseEffectsIsDone){
      return(<div className="variant-viewer-split-pane d-flex my-5 justify-content-center">
          <span className="mx-3 spinner-border" role="status" aria-hidden="true"></span>
          <span className="fs-3">Loading...</span>
        </div>)
    }
    return(
      <div className="variant-viewer-split-pane">
        <Prompt
          when={shouldBlockNavigation}
          message='You have unsaved changes, are you sure you want to leave?'
        />
        <SplitPane split="horizontal" minSize={50} maxSize={-50} defaultSize={300}>
          <div className="show-overflow absolute-panel d-flex flex-column mb-1 bg-white">
            <VariantTable
              setSortBy={props.setSortBy}
              initialSortBy={props.initialSortBy}
              triggerExportPreparation={triggerExportPreparation}
              preparedDataFromVariantTable={(values) => {setPreparedDataFromVariantTable(values); setTriggerExportPreparation(false)}}
              updateVariantTableData={(variantIndex, columnId, value) => { 
                if(!!props.updateVariantTableData){ 
                  setShouldBlockNavigation(true);
                  props.updateVariantTableData(variantIndex, columnId, value);
                } else {toast.error('Cannot update this value!')}
                if(isAutoSavingNotes && (autoSaveNotesCounter < autoSaveNotesInterval) ){
                  setAutoSaveNotesCounter(autoSaveNotesCounter + 1);
                }
              }}
              getTabName={() => selectedVariantTab}
              // getCaseID={() => props.currentCaseInformation.CASE_ID}
              sendFeedback={sendDenovoAssessment}
              setColumnsSpec={(colId, colWidth) => Object.values(columnData).find(t => t.id===colId).setColWidth(colWidth) }
              hiddenColumnsIds={hiddenColumnsIds}
              reportCurrentPageIndex={(pind) => setLatestTablePageIndex(pind)}
              initialPageIndex={latestTablePageIndex}
              setDataForFilterSelectionModal={setDataForFilterSelectionModal}
              // PMFSampleColNames={Object.keys(sampleColumns).map(i => i)}
              PMFSampleColNames={getSampleColumnsProbandAtFirst()}
              currentVariantId={currentVariantId} 
              displayVariantInfo={ (variantId) => setCurrentVariantId(variantId)}
              variantFilterObj={ {...tabs[selectedVariantTab].filters, ...globalTabFilters} }
              columns={columnData} data={variantData}
            />
          </div>
          {renderVariantInfo()}
        </SplitPane>
      </div>
    );
  }

  const renderFiltersAndColumnsTabs = () => {
    return(
      <Tabs>
        <TabList>
          <Tab><span>Notes</span></Tab>
          <Tab><span>Filters</span></Tab>
          <Tab><span>Columns</span></Tab>
          <Tab><span>Pathways</span></Tab>
        </TabList>
        <TabPanel>{renderNotesPanel()}</TabPanel>
        <TabPanel>{renderFilterList()}</TabPanel>
        <TabPanel>{renderColumnList()}</TabPanel>
        <TabPanel>{renderPathwayList()}</TabPanel>
      </Tabs>
    );
  }

  const onSelectedTabChange = (index, lastIndex, event) => {
    // console.log(event.target.innerText);
    if(!!refForCreatableSelect.current){
      refForCreatableSelect.current.state.value = null;
    }
    setLatestTablePageIndex(0);
    setCurrentVariantId(null);
    // setNotesForCurrentVariant([]);
    setSelectedVariantTab(Object.keys(tabs)[index]);
    setSelectedFilterSetIndex(null);
  }

  const isNumericColumn = (columnId) => {
    let tmpList = props.caseData.filter(t => t[columnId]!==null);
    if(!!tmpList[0]){
      if(tmpList[0][columnId]!==undefined && tmpList[0][columnId]!==null){
        return(typeof(tmpList[0][columnId])=='number');
      }
    }
    return false;
  }

  const review_code_to_text = (revCode, returnNullForNotFound=false, returnLowerCase=false) => {
    let tmpOption = Object.values(ReviewOptions).find(l => l.value===revCode);
    // let tmpOption = Object.values(ReviewOptions).find(l => String(l.value).toLowerCase() === String(revCode).toLowerCase());
    if(!!tmpOption && !!tmpOption.label){
      if(returnLowerCase)
        return(String(tmpOption.label).toLowerCase());
      else
        return(tmpOption.label);
    } else {
      return( (returnNullForNotFound ? null : '' ) );
    }
  }

  const setDataForFilterSelectionModal = (e, selectedColumn) => {
    // console.log("---------", selectedColumn);
    // console.log(isNumericColumn(selectedColumn.id));
    if(selectedColumn.id==='VANEX_TAGS'){
      toast.error('Cannot filter '+selectedColumn.Header);
      return;
    }
    setFilterSelectionTitle(`Filtering ${selectedColumn.Header}`);
    let initValues = { columnName:selectedColumn.id, value:'', valueIsList:false, separateValueListBy:' ' , minValue:'', maxValue:'', includeNullValues:true, selTerms:[], filterBy:"UserProvidedValue", selectAllStatus:UNCHECKED }

    // let initValues = { value: 'z', minValue: 0, maxValue:0 }
    // setFilterSelectionFormik(<Formik className="" enableReinitialize={true} initialValues={initValues}
    // onSubmit={(fields) => {
    //     console.log(fields);
    //     filterSelectionModal.hide();
    //   }}>
    //     {({ values, setFieldValue }) => (
    //     <Form id={}>
    //       {/* { console.log(values)} */}
    //       {/* <CustomerPopup
    //           showModal={showModal}
    //           close={() => toggleCustomerModal()}
    //           onSelect={data => onSelectCustomerHandler(data, setFieldValue)}
    //         /> */}
    //       <div className="modal-body">
    //         <Field className="form-control" type="text" name="minValue" placeholder="" aria-label=""/>
    //         <Field className="form-control" type="text" name="maxValue" placeholder="" aria-label=""/>
    //       </div>
    //     <div className="modal-footer ">
    //       <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i> Apply</button>
    //       <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
    //     </div>
    //     </Form>)}
    //   </Formik>);
    //   filterSelectionModal.show();
    // return;

    if(isNumericColumn(selectedColumn.id)){
      //? ------------------- filtering column with NUMERIC values  ---------------------------

      if (!!globalTabFilters[selectedColumn.id]){
        if (!!globalTabFilters[selectedColumn.id]["minValue"] && !!globalTabFilters[selectedColumn.id]["maxValue"]){
          initValues["minValue"] = globalTabFilters[selectedColumn.id]["minValue"];
          initValues["maxValue"] = globalTabFilters[selectedColumn.id]["maxValue"];
        }
      }

      const getFilterDescriptionValue = (minValue, maxValue, includeNullValues) =>{
        let hasMinValue = (!!minValue || minValue===0);
        let hasMaxValue = (!!maxValue || maxValue===0);
        let nullValueDesc = ( includeNullValues ? " (Include Blanks)" : "" )
        if( hasMinValue && !hasMaxValue)
          return(` >= "${minValue}"${nullValueDesc}`);
        if( !hasMinValue && hasMaxValue)
          return(` <= "${maxValue}"${nullValueDesc}`);
        if( !hasMinValue && !hasMaxValue)
          return(`${nullValueDesc}`);
        return(` = "${minValue} to ${maxValue}"`);
      }

      setFilterSelectionFormik(
        <Formik className="" enableReinitialize={true} initialValues={initValues} onSubmit={(fields) => {
          // console.log(fields);
          setGlobalTabFilters(
            prevGlobalTabFilters => ({
              ...prevGlobalTabFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: getFilterDescriptionValue(fields.minValue, fields.maxValue, fields.includeNullValues),
                minValue: fields.minValue,
                maxValue: fields.maxValue,
                operation: '=',
                visible: true,
                editable: true,  // tabFilter: false,
                getFilterDescr: function(){ return(this.label + this.value)},
                // getFilterDescr: function(){ return(this.label + ' ' + this.operation + ' ' + this.value); },
                filterFunction: function(){
                  let hasMinValue = (!!fields.minValue || fields.minValue===0);
                  let hasMaxValue = (!!fields.maxValue || fields.maxValue===0);
                  if( hasMinValue && !hasMaxValue)
                    return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? (t[selectedColumn.id] >= fields.minValue ) : ( fields.includeNullValues ? true : false) ) );
                  if( !hasMinValue && hasMaxValue)
                    return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? (t[selectedColumn.id] <= fields.maxValue ) : ( fields.includeNullValues ? true : false) ) );
                  if( !hasMinValue && !hasMaxValue)
                    return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? false : ( fields.includeNullValues ? true : false) ) );
                  return( (t) => ( (t[selectedColumn.id]!==null && t[selectedColumn.id]!==undefined) ? ( (t[selectedColumn.id] >= fields.minValue ) && (t[selectedColumn.id] <= fields.maxValue )) : ( fields.includeNullValues ? true : false) ) );
                },
                isApplied: true
              }
            })
          );
          filterSelectionModal.hide();
        }}>
          <Form>
          <div className="modal-body">
            <table className="">
              <tbody>
                <tr>
                  <td>
                    <label className=""><br/>{selectedColumn.Header}</label> 
                  </td>
                  <td>
                    <label className=""><br/><i className="fas fa-equals"></i></label> 
                  </td>
                  <td>
                    <label className="form-check-label">
                      Min
                      <Field className="form-control" type="text" name="minValue" placeholder="" aria-label=""/>
                    </label>
                  </td>
                  <td>
                    <label className="form-check-label">
                      Max
                      <Field className="form-control" type="text" name="maxValue" placeholder="" aria-label=""/>
                    </label>
                  </td>
                </tr>
              </tbody>
            </table>
            <label className="mt-2 w-100 d-flex flex-column ms-1 align-items-center">
              <div>
                <Field className="mx-1" type="checkbox" name="includeNullValues" placeholder="" aria-label=""/>
                Include Blanks
              </div>
            </label>
          </div>
          <div className="modal-footer ">
            <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i>Apply Filter</button>
            <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
          </div>
          </Form>
        </Formik>
      );
    }
    else {
      //? ------------------- filtering column with TEXT values ---------------------------
      
      if (!!globalTabFilters[selectedColumn.id]){
        // debugger;
        if (!!globalTabFilters[selectedColumn.id]["value"]){
          initValues["value"] = globalTabFilters[selectedColumn.id]["value"];
        }
        if (!!globalTabFilters[selectedColumn.id]["filterBy"]){
          initValues["filterBy"] = globalTabFilters[selectedColumn.id]["filterBy"];
        }
        if (!!globalTabFilters[selectedColumn.id]["selTerms"]){
          initValues["selTerms"] = globalTabFilters[selectedColumn.id]["selTerms"];
        }
      }

      //? ------------------- collect unique values in an array ---------------------------
      let tmpObj = {}
      let tmpval;
      // Object.values(props.caseData).forEach((variantRecord) => {
      Object.values(variantData).forEach((variantRecord) => {
        // tmpObj[variantRecord[selectedColumn.id]] = ((!!variantRecord[selectedColumn.id]) ? variantRecord[selectedColumn.id] : "__Blank__");
        if(selectedColumn.id==='VANEX_NOTES'){
          tmpObj[variantRecord[selectedColumn.id]['CURRENT_USER_NOTE']] = variantRecord[selectedColumn.id]['CURRENT_USER_NOTE'];
        } else if(selectedColumn.id==='VANEX_REVIEWS'){
          tmpObj[variantRecord[selectedColumn.id]['REVIEW_VALUE']] = null;
          tmpval = Object.values(ReviewOptions).find(l => l.value === variantRecord[selectedColumn.id]['REVIEW_VALUE']);
          // console.log(tmpval);
          if(!!tmpval && !!tmpval.label){
            tmpObj[variantRecord[selectedColumn.id]['REVIEW_VALUE']] = tmpval.label;
          }
        } else {
          tmpObj[variantRecord[selectedColumn.id]] = variantRecord[selectedColumn.id];
        }
      });
      let selColUniqueValues = Object.values(tmpObj).map( k => k).sort().sort( (elA,elB) => ( Number(!elA) - Number(!elB)) );
      // console.log(selColUniqueValues);

      setFilterSelectionFormik(
        <Formik className="" enableReinitialize={true} initialValues={initValues} onSubmit={(fields) => {
          //! Equals, Does not equal, Contains, Does not contain, Begins with, Ends with, 
          // console.log(fields);
          let operation = "contains";
          // let useSelTerms = fields.filterBy === 'SelectedTerms';
          let myFilterFunction;
          if(fields.filterBy === 'SelectedTerms' || fields.valueIsList===true){
            operation = "=";
            let selectedTerms;
            let multiValuesProvided = (fields.valueIsList===true && fields.filterBy!=='SelectedTerms');
            if(multiValuesProvided){
              // selectedTerms = fields.value.split(fields.separateValueListBy);
              selectedTerms = String(fields.value).toLowerCase().split(fields.separateValueListBy);
            } else {
              selectedTerms = fields.selTerms;
            }
            // let selectedTerms = fields.selTerms.map( (term) => {
            //   if (term==='__Blank__'){
            //     return(null);
            //   }
            //   else{
            //     return(term);
            //   }
            // });
            // let includeNullValuesInFilter = fields.selTerms.includes("__Blank__");
            let includeNullValuesInFilter = (fields.selTerms.includes(null) || fields.selTerms.includes(''));
            myFilterFunction = function(){
              //* if multi values are provided as a list use toLowerCase first before comparing! otherwise they need to match exactly (it is case sensitive)
              if(selectedColumn.id==='VANEX_NOTES'){
                if(multiValuesProvided)
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(String(t[selectedColumn.id]['CURRENT_USER_NOTE']).toLowerCase()) !== -1) : includeNullValuesInFilter ) );
                else
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(t[selectedColumn.id]['CURRENT_USER_NOTE']) !== -1) : includeNullValuesInFilter ) );
              } else if(selectedColumn.id==='VANEX_REVIEWS'){
                // if(fields.valueIsList===true)
                //   selectedTerms = String(fields.value).toLowerCase().split(fields.separateValueListBy);
                return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(review_code_to_text(t[selectedColumn.id]['REVIEW_VALUE'], true, multiValuesProvided )) !== -1) : includeNullValuesInFilter ) );
              } else { 
                if(multiValuesProvided)
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(String(t[selectedColumn.id]).toLowerCase()) !== -1) : includeNullValuesInFilter ) );
                else
                  return( (t) => ( (!!t[selectedColumn.id]) ? ( selectedTerms.indexOf(t[selectedColumn.id]) !== -1) : includeNullValuesInFilter ) );
              }
            }
          } else {
            myFilterFunction = function(){
              if(selectedColumn.id==='VANEX_NOTES'){
                return( (t) => ( (!!t[selectedColumn.id]) ? String(t[selectedColumn.id]['CURRENT_USER_NOTE']).toLowerCase().includes(fields.value.toLowerCase()) : false ));
              } else if(selectedColumn.id==='VANEX_REVIEWS'){
                return( (t) => ( (!!t[selectedColumn.id]) ? String(review_code_to_text(t[selectedColumn.id]['REVIEW_VALUE'], false)).toLowerCase().includes(fields.value.toLowerCase()) : false ));
                // return( (t) => ( (!!t[selectedColumn.id]) ? false : false ));
              } else {
                return( (t) => ( (!!t[selectedColumn.id]) ? String(t[selectedColumn.id]).toLowerCase().includes(fields.value.toLowerCase()) : false ));
                // return( (t) => {
                //   if(!!t[selectedColumn.id])
                //     console.log(String(t[selectedColumn.id]));
                //   return(true)
                //   // return( (!!t[selectedColumn.id]) ? t[selectedColumn.id].toLowerCase().includes(fields.value.toLowerCase()) : false )
                // });
              }
            }
          }

          setGlobalTabFilters(
            prevGlobalTabFilters => ({
              ...prevGlobalTabFilters,
              [selectedColumn.id]: {
                columnId: selectedColumn.id,
                label: `${selectedColumn.Header}`,
                value: fields.value,
                operation: `${operation}`,
                visible: true,
                editable: true,  // tabFilter: false,
                selTerms: fields.selTerms,
                filterBy: fields.filterBy,
                getFilterDescr: function(){
                  let fieldValue = `"${this.value}"`;
                  if(this.filterBy === 'SelectedTerms'){
                    fieldValue = `[${this.selTerms.join('], [')}]`;
                    // if(this.selTerms.length > 3){fieldValue = "[...]";}
                    // return(`"${fieldValue}"`)
                  }
                  return(this.label + ' ' + this.operation + ' ' + fieldValue);
                },
                filterFunction: myFilterFunction,
                isApplied: true
              }
            })
          ); 

          filterSelectionModal.hide();
        }}>
          {({ errors, values, touched, setValues }) => (
            <Form>
            <div className="modal-body">
              <div className="d-flex flex-row w-100">
                <div className="d-flex flex-column flex-grow-1">
                  <label className="">
                    <Field name="filterBy" className="form-check-input me-2" type="radio" value="UserProvidedValue" placeholder="" aria-label=""/>
                    {selectedColumn.Header}<span className="fw-bold mx-1">Contains</span>
                  </label>
                  <label className="form-check-label flex-grow-1">
                    <Field className="form-control w-100" type="text" name="value" placeholder="" aria-label=""
                    onFocus={e => { values.filterBy ='UserProvidedValue'; }}
                    />
                  </label>
                </div>
                <label className="d-flex flex-row ms-1 align-items-center flex-grow-0">
                  <Field className="mx-1" name="valueIsList" type="checkbox" />
                  <div className={(values.valueIsList ? '' : 'text-muted')}>
                    multiple values <br/>separated by 
                    <Field className="mx-1" disabled={!values.valueIsList} name="separateValueListBy" type="text" style={{maxWidth:'1.5rem'}}/>
                  </div>
                </label>
              </div>
              <hr/>
              <label className="">
                <Field name="filterBy" className="form-check-input me-2" type="radio" value="SelectedTerms" placeholder="" aria-label=""/>
                  {selectedColumn.Header}<span className="fw-bold mx-1">matches</span>
              </label>
              <div className="ist-group list-group-flush border border-secondary border-1 m-1 show-overflow l" style={{maxHeight:'40vh'}}>
                <label className="list-group-item">
                  <IndeterminateCheckbox
                    className="form-check-input me-1"
                    name="select-all"
                    value={values.selectAllStatus}
                    onFocus={e => { values.filterBy ='SelectedTerms'; }}
                    onChange={e => {
                      let chboxStatus = INDETERMINATE;
                      let selectedTerms = [];
                      if (e.target.checked) {
                        selColUniqueValues.forEach( (term) => selectedTerms.push(term));
                        chboxStatus = CHECKED;
                      } else {
                        chboxStatus = UNCHECKED;
                      }
                      setValues({ ...values, 
                        selectAllStatus: chboxStatus,
                        selTerms: selectedTerms 
                      });
                    }}
                  />
                  Select All
                </label>
                <FieldArray
                  name="selTerms"
                  render={arrayHelpers => (
                    <React.Fragment>
                      {selColUniqueValues.map( (tag, ind) => (
                        <label className="list-group-item" key={ind}>
                          <input
                            className="form-check-input me-1"
                            type="checkbox"
                            name={(tag!==null && tag!==undefined && tag!=='') ? tag.toString() : "__Blank__"}
                            checked={values.selTerms.includes(tag)}
                            onFocus={e => { values.filterBy ='SelectedTerms'; }}
                            onChange={e => {
                              let chboxStatus = INDETERMINATE;
                              let selectedTerms = [...values.selTerms];
                              if(values.selTerms.length === 0){
                                chboxStatus = UNCHECKED;
                              }
                              if (e.target.checked) {
                                if(values.selTerms.length === 0){
                                  chboxStatus = INDETERMINATE;
                                }
                                if(selColUniqueValues.length === (values.selTerms.length+1) ){
                                  chboxStatus = CHECKED;
                                }
                                // arrayHelpers.push(tag);
                                selectedTerms.push(tag);
                              } else {
                                if(values.selTerms.length-1 === 0){
                                  chboxStatus = UNCHECKED;
                                }
                                const removeIndex = values.selTerms.indexOf(tag);
                                // arrayHelpers.remove(removeIndex);
                                // let removeIndex = selectedTerms.map(item => item.id).indexOf(tag);
                                ~removeIndex && selectedTerms.splice(removeIndex, 1);  //! <= this is a nice way of doing this: (removeIndex >= 0) && array.splice(removeIndex, 1);
                              }
                              setValues({ ...values, 
                                selectAllStatus: chboxStatus,
                                selTerms: selectedTerms 
                              });
                            }}
                          />
                          {/* <span>{ (typeof(tag)==='boolean' ? ( (tag) ? 'Checked' : 'Unchecked') : (!!tag ? tag : "__Blank__") )}</span> */}
                          {/* <span>{(!!tag) ? (typeof(tag)==='boolean' ? 'Checked' : tag ) : "__Blank__"}</span> */}
                          <span>{(tag!==null && tag!==undefined && tag!=='') ? tag : "__Blank__"}</span>
                        </label>
                      ))}
                    </React.Fragment>
                  )}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <button className="btn btn-primary" type="submit" ><i className="fas fa-filter me-2"></i>Apply Filter</button>
              <button type="button" className="btn btn-secondary" onClick={() => filterSelectionModal.hide()}>Cancel</button>
            </div>
            </Form>)}
          </Formik>
      );
    }
    filterSelectionModal.show();
    return;
  }

  function handleSampleTypeChange(fields) {
    if(
      ((fields.Proband !== 'None')  && (fields.Mother !== 'None')  && (fields.Father !== 'None') &&
      (fields.Proband !== fields.Mother) && (fields.Mother !== fields.Father)  && (fields.Proband !== fields.Father) 
      // && (fields.Proband !== '')  && (fields.Mother !== '')  && (fields.Father !== '')
      )
    ||
      ((fields.Proband !== 'None')  && (fields.Mother !== 'None')  && (fields.Father === 'None') &&
      (fields.Proband !== fields.Mother) 
      )
    ||
      ((fields.Proband !== 'None')  && (fields.Mother === 'None')  && (fields.Father !== 'None') &&
      (fields.Proband !== fields.Father) 
      )
    ||
      ((fields.Proband !== 'None')  && (fields.Mother === 'None')  && (fields.Father === 'None')
      )
    ){
      setPMFSampleColNames([fields.Proband, fields.Mother, fields.Father]);
      const sampleColumnNames = Object.keys(sampleColumns).map(i => i);
      if(!!sampleColumnNames && sampleColumnNames.length>0){
        setInitialColumnData( prevColumnData => prevColumnData.map( colInfo => {
          // let colHeader=colInfo.Header;
          let colAccessor = colInfo.id;
          if(sampleColumnNames.includes(colInfo.id))
            return({...colInfo, Header: getTypeOfColumnsInPMFList(colInfo,false, true, [fields.Proband, fields.Mother, fields.Father]) + colInfo.id })
            // colHeader=getTypeOfColumnsInPMFList(colInfo,false, true, [fields.Proband, fields.Mother, fields.Father]) + colInfo.id;
          if( colAccessor.endsWith("_GT") || colAccessor.endsWith('_Alt_Percent')  || colAccessor.endsWith('_GQ') || colAccessor.endsWith('_AD') || colAccessor.endsWith('_RD') ||
               colAccessor.endsWith('_TD') || colAccessor.endsWith('_PL') || colAccessor.endsWith('_DP') || colAccessor.endsWith('_SB') || colAccessor.endsWith('_GT_RAW') )
            // colHeader=colAccessor;//`${getSampleLabel(colAccessor.slice(0,-3))}: ${colAccessor}`;
            return({...colInfo, Header: colAccessor});
          return(colInfo)
        }));
      }
      toast.success('Success!'); // toast.success('SUCCESS!! :-)\n\n' + JSON.stringify(fields, null, 4));
      setRefresh(prevState => !prevState);
      sampleTypeModal.hide();
    }
    else {
      toast.error("Not a valid combination!")
    }
  }


  //? --------------------------------------------- Main Render ----------------------------------
  // console.log("render...");
  // console.log(tabs);
  // console.log(selectedFilterSetIndex);
  return (
    <React.Fragment>
      <SplitPane className={" bottom-tab-panel " + ( (props.onlyShowOneTab) ? " plus-border-radius " : "" )}
        split="vertical" minSize={100} maxSize={-100} defaultSize={280} >
        {(!!props.currentCaseNotes) ?
          <div className="variant-viewer-split-pane">
            <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
              {renderFiltersAndColumnsTabs()}
            </div>
            {/* <SplitPane split="horizontal" minSize={50} maxSize={-50} defaultSize={262} >
              <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
                {renderFiltersAndColumnsTabs()}
              </div>
              <div className="show-overflow absolute-panel d-flex flex-column mb-1 sidebar-splitpane-panel">
                {renderNotesPanel()}
              </div>
            </SplitPane> */}
          </div>
        :
          <div className="sidebar-splitpane-panel">
            {renderFiltersAndColumnsTabs()}
          </div>
        }
        <div className="sidebar-splitpane-panel absolute-panel">
          <Tabs defaultIndex={Object.keys(tabs).indexOf(selectedVariantTab)} onSelect={onSelectedTabChange} >
            <TabList style={{paddingRight:'7rem'}}>
              {Object.values(tabs).map( (tabName, index) => <Tab key={index}><span>{tabName.label}</span></Tab>)}
              <div className="tab-menu-button-group d-flex flex-row ps-1 pt-1 align-items-center " >
                {/* <Dropdown as={ButtonGroup} size="sm" className="m-0 p-0">
                  <Button variant=""  className="m-0 p-0 text-light">
                  
                  <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={saveNotes}>
                      <i className="fas fa-file-alt fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Save Notes</span>
                      {(isSavingNotes) ? 
                      <div className="progress export-progressbar">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${savingNotesProgressbarValue}%`}} aria-valuenow={savingNotesProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      : null}
                    </div>

                  </Button>

                  <Dropdown.Toggle split variant="" className="text-light" id="dropdown-split-basic" />

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                {/* <span className="ms-1 " style={{cursor:'default'}}>Started at: <span className="fw-bold">{analysisStartedAt}</span></span> */}
                {(canSaveNotes)
                ?
                  <>
                    <div className="m-0 ms-1 p-0 px-1 tab-menu-button cursor-pointer" onClick={saveNotes}>
                      {(isSavingNotes) ? 
                        <>
                          <span className="mx-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {/* <i className="fas fa-circle fs-6"></i> */}
                          <span className="fs-6 fw-bold ms-1 p-0">Saving...</span>
                          <div className="progress export-progressbar">
                            <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${savingNotesProgressbarValue}%`}} aria-valuenow={savingNotesProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </>
                      :
                        (numberOfNotesSaved!==null && numberOfNotesSaved!==undefined) ? 
                          <>
                            <i className="fas fa-check-double green-lighter fs-6"></i>
                            <span className="fs-6 fw-bold ms-1 p-0">{numberOfNotesSaved} Notes &amp; {numberOfReviewSaved} Review Saved!</span>
                          </>
                        :
                          <>
                            <i className="fas fa-file-alt fs-6"></i><span className="fs-6 fw-bold ms-1 p-0">Save Notes</span>
                          </>
                      }
                    </div>
                    <Dropdown align="end" onSelect={notesOptions}>
                      <Dropdown.Toggle variant="" id="notesOptions-dropdown" className="tab-menu-button dropdown-toggle m-0 p-0 py-2 px-1 d-flex flex-row align-items-center">
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="export-menu m-0" >
                        {/* <Dropdown.Item as={() => (<div className=""><input type="checkbox" className="mx-2" checked={isAutoSavingNotes}  readOnly />Auto Save Notes</div>)
                        } eventKey="summary"></Dropdown.Item> */}
                        <Dropdown.Item as="button" eventKey="hideOtherUsersNotes"><div className=""><input type="checkbox" className="mx-2" checked={!showOtherUsersNotes}  readOnly />Hide Other Users' Notes</div></Dropdown.Item>
                        <Dropdown.Item as="button" eventKey="autoSavingNotes" ><div className=""><input type="checkbox" className="mx-2" checked={isAutoSavingNotes}  readOnly />Auto Save Notes</div></Dropdown.Item>
                        <Dropdown.Item as={() => (<div className={`ms-5 d-flex ${(!isAutoSavingNotes) ? 'text-muted' :'' }`}>
                          - every<input type="number" 
                            min="1" step="1" 
                            className="mx-2" style={{maxWidth:'2.5rem', maxHeight:'1.6rem'}}
                            disabled={!isAutoSavingNotes}
                            defaultValue={autoSaveNotesInterval}
                            onChange={e => setAutoSaveNotesInterval(e.target.value)}
                          />
                          </div>)
                        } eventKey="summary"></Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </>
                : null }
                <Dropdown align="end " onSelect={exportSnvAsXlsx}>
                  <Dropdown.Toggle variant="" id="export-dropdown" className="tab-menu-button m-0 ms-1 p-0 px-2 d-flex flex-row align-items-center">
                    <div>
                      <i className="fas fa-download fs-5"></i><span className="fs-6 fw-bold mx-1 p-0">Export</span>
                      {(isExporting) ? 
                      <div className="progress export-progressbar">
                        <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${exportProgressbarValue}%`}} aria-valuenow={exportProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      : null}
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="export-menu m-0" >
                    <Dropdown.Item as="button" eventKey="clipboard">to Clipboard</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey="summary">Summary Tab Only</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey="noOriginalData">Exclude 'All SNVs' Tab</Dropdown.Item>
                    <Dropdown.Item as="button" eventKey="all">Complete Dataset</Dropdown.Item>
                    {/* <Dropdown.Item as="button" eventKey="all" onClick={() => exportSnvAsXlsx('all')} >Complete Dataset</Dropdown.Item> */}
                  </Dropdown.Menu>
                </Dropdown>

                {/* <span onClick={exportSnvAsXlsx}>
                  <i className="fas fa-download fs-5"></i><span className="fs-5 fw-bold mx-1 p-0">Export</span>
                  {(isExporting) ? 
                  <div className="progress export-progressbar">
                    <div className="progress-bar progress-bar-striped progress-bar-animated bg-info" role="progressbar" style={{width:`${exportProgressbarValue}%`}} aria-valuenow={exportProgressbarValue} aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  : null}
                </span> */}
              </div>
            </TabList>
            {Object.keys(tabs).map( (tabId, indx) => <TabPanel key={indx}>{renderVariantTab()}</TabPanel>)}
          </Tabs>
        </div>
      </SplitPane>
      {/* //? --------------------------------------------- Modal ---------------------------------- */}
      <div className="modal fade" ref={refForFilterSelectionModal} tabIndex="-1" aria-labelledby="refForFilterSelectionModal" aria-hidden="true">
        {/* <div className="modal-dialog modal-sm"> */}
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{filterSelectionTitle}</h5>
              <button type="button" className="btn-close" onClick={() => filterSelectionModal.hide()} aria-label="Close"></button>
            </div>
            {filterSelectionFormik}
          </div>
        </div>
      </div>
      {/* END Modal */}

      {/* //? --------------------------------------------- SampleType Modal ---------------------------------- */}
      <div className="modal fade" ref={refForChangeSampleTypeModal} tabIndex="-1" aria-labelledby="changeSampleTypeLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="changeSampleTypeLabel">Change Sample Types</h5>
              <button type="button" className="btn-close" onClick={() => sampleTypeModal.hide()}  aria-label="Close"></button>
            </div>
            <Formik className=""
              enableReinitialize={true}
              initialValues={{ Proband: PMFSampleColNames[0] || 'None', Mother: PMFSampleColNames[1] || 'None', Father: PMFSampleColNames[2] || 'None' }}
              onSubmit={handleSampleTypeChange}
            >
            {({ errors, values, touched, setValues, setFieldValue }) => {
              let selectOptions = Object.keys(sampleColumns).map(i => ({value: i, label:i}) );
              selectOptions=[...selectOptions,{value: 'None', label:'None'}];
              return(
                <Form>
                  <div className="modal-body d-flex justify-content-center ">

                    <table className="change-sample-type-table w-100">
                      <thead>
                        <tr>
                          <th className="">Sample Type</th>
                          <th className="w-100">Sample ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className="mx-3">Proband</label> 
                          </td>
                          <td className="d-flex">
                            <Select className="w-100" 
                              name="Proband"
                              options={selectOptions}
                              value={{value: values.Proband, label:values.Proband}}
                              onChange={selectedOption => setFieldValue('Proband', selectedOption.value)}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="mx-3">Mother</label> 
                          </td>
                          <td className="d-flex">
                            <Select className="w-100"
                              name="Mother"
                              options={selectOptions}
                              value={{value: values.Mother, label:values.Mother}}
                              onChange={selectedOption => setFieldValue('Mother', selectedOption.value)}
                            />
                          </td>
                        </tr>
                          <tr>
                            <td>
                              <label className="mx-3">Father</label> 
                            </td>
                            <td className="d-flex">
                              <Select className="w-100"
                                name="Father"
                                options={selectOptions}
                                value={{value: values.Father, label:values.Father}}
                                onChange={selectedOption => setFieldValue('Father', selectedOption.value)}
                              />
                            </td>
                          </tr>
                      </tbody>
                    </table>

                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" onClick={() => sampleTypeModal.hide()}>Close</button>
                    <button className="btn btn-primary" type="submit" ><i className="fas fa-save me-2"></i> Save changes</button>
                  </div>
                </Form>
              )
            }}
            </Formik>
          </div>
        </div>
      </div>
      {/* END Modal */}
    </React.Fragment>
  );
}

export default VariantViewer;
